import { css } from 'styled-components';

import { colorContrast, hoverBackground, createColor } from './colors';

export const formErrorHandler = (errors, formActions, schema) => {
  const { setStatus, setFieldError } = formActions;

  if (typeof errors === 'string') {
    setStatus(errors);
  } else if (Array.isArray(errors)) {
    setStatus(errors.join('. '));
  } else if (typeof errors === 'object') {
    Object.keys(schema.fields).forEach((key) => {
      if (errors[key]) {
        setFieldError(key, errors[key].join('. '));
      }
    });
    setStatus(errors.base?.join('. '));
  }
};

export const buttonVariant = (background) => {
  const bg = createColor(background);
  const color = colorContrast(bg);
  const border = 'transparent';

  const hoverBg = hoverBackground(bg, 0.15, 0.15);
  const hoverColor = colorContrast(hoverBg);
  const hoverBorder = hoverBackground(bg, 0.2, 0.1);
  const activeBg = hoverBackground(bg, 0.2, 0.2);
  const activeBorder = background;
  const activeColor = colorContrast(activeBg);

  return css`
    &,
    &.disabled,
    &:disabled {
      background: ${background};
      color: ${color};
      border-color: ${border};
    }

    &&:hover:not(:disabled):not(.disabled) {
      background: ${hoverBg};
      color: ${hoverColor};
      border-color: ${hoverBorder};
    }

    &:not(.disabld):not(:disabled).active,
    &:not(.disabld):not(:disabled):active {
      background: ${activeBg};
      color: ${activeColor};
      border-color: ${activeBorder};
    }
  `;
};

export const badgeVariant = (background) => {
  const bg = createColor(background);
  const color = colorContrast(bg);

  return css`
    background: ${background};
    color: ${color};
  `;
};
