import styled from 'styled-components';

import Title from '../../content-editor/Title';
import { editTitle } from '../../../sitemap/tree-renderer/actions';
import PageUpdateIndicator from './PageUpdateIndicator';

const Button = styled.button`
  font-size: 2rem;
  color: #fff;
`;

function TitleWithUpdateIndicator({ pageId, children }) {
  return (
    <Title>
      {children}
      <PageUpdateIndicator pageId={pageId} />
    </Title>
  );
}

export default function NodeTitle({ node, disabled, updateIndicator = true }) {
  if (!node) {
    return null;
  }

  const TitleComponent = updateIndicator ? TitleWithUpdateIndicator : Title;

  const title = node.data.name;
  const handleClick = (e) => {
    editTitle(e, node);
  };

  if (disabled) {
    return (
      <TitleComponent pageId={node.data.pageId}>
        <h2 className="font-weight-bold text-truncate m-0" title={title}>
          {title}
        </h2>
      </TitleComponent>
    );
  }

  return (
    <TitleComponent pageId={node.data.pageId}>
      <Button
        type="button"
        className="btn btn-link text-decoration-none font-weight-bold text-truncate px-0 text-left"
        title={title}
        onClick={handleClick}
      >
        {title}
      </Button>
    </TitleComponent>
  );
}
