/* global jQuery */
import { useEffect, useMemo, useRef } from 'react';

import useTrackControlledValue from './useTrackControlledValue';

export default function TagInput({ placeholder, onChange, value, disabled, ...props }) {
  const { name } = props;
  const inputRef = useRef();

  // Keep jquery tagsInput plugin in sync with value prop.
  // Call a function each time value prop is different from the latestKnownValue.
  // Track latestKnownValue to reduce calls to the sync function.
  const setLastesKnownValue = useTrackControlledValue(value, () => {
    jQuery(inputRef.current).importTags(value);
  });

  const handleChange = useMemo(() => onChange(name), [onChange, name]);

  // Initialize the jquery control
  useEffect(() => {
    jQuery(inputRef.current).tagsInput({
      placeholder,
      interactive: !disabled,
      onChange: (element) => {
        // Keep track of jquery last known value
        setLastesKnownValue(inputRef.current.value);

        // Notify formik of every change
        handleChange({ target: element instanceof jQuery ? element.get(0) : element });
      },
    });
  }, [placeholder, handleChange, setLastesKnownValue, disabled]);

  return <input ref={inputRef} type="text" defaultValue={value} disabled={disabled} {...props} />;
}
