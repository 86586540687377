import ContentForm from '../content-editor/ContentForm';
import { usePageContent, useUpdatePageContent } from './hooks';
import { stringToKeywords } from '../content-editor/utils';
import { addEditorFiles } from './api';

export default function PageContentForm({ node, sitemapId, small }) {
  const { data: page } = node;
  const save = useUpdatePageContent(page.pageId);

  const { data, isLoading } = usePageContent(page.pageId);

  const handleSave = async (formData) => {
    const info = { ...formData, keywords: stringToKeywords(formData.keywords) };

    // Delete unpermitted params
    delete info.id;
    delete info.files;
    delete info.gdocs;
    delete info.updated_at;

    await save.mutateAsync(info);
  };

  const handleFileUpload = (newFileSignedId) => {
    // Tinymce upload images one at a time...not multiple
    return addEditorFiles(sitemapId, page.pageId, [newFileSignedId]).then((res) => res[0]);
  };

  return (
    <ContentForm
      node={node}
      data={data}
      small={small}
      isInitialLoading={isLoading}
      onSave={handleSave}
      onFileUpload={handleFileUpload}
    />
  );
}
