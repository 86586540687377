/* global easydropdown */

import { useState, useEffect, useRef } from 'react';

import SitemapComparisonSearchBar from './SitemapComparisonSearchBar';

function SitemapComparisonPageSelectors({ onPageSelectionChange = () => {} }) {
  const [statusFilter, setStatusFilter] = useState('allStatuses');
  const [similarityFilter, setSimilarityFilter] = useState('allSimilarities');
  const [searchValue, setSearchValue] = useState('');
  const statusFilterDropdown = useRef(null);
  const similarityFilterDropdown = useRef(null);

  useEffect(() => {
    statusFilterDropdown.current = easydropdown('#status-filter', {
      callbacks: {
        onSelect: setStatusFilter,
      },
    });
    similarityFilterDropdown.current = easydropdown('#similarity-filter', {
      callbacks: {
        onSelect: setSimilarityFilter,
      },
    });
    return () => {
      statusFilterDropdown.current.destroy();
      similarityFilterDropdown.current.destroy();
    };
  }, []);

  const onReviewFlaggedPages = () => {
    statusFilterDropdown.current.value = 'onlyFlagged';
    similarityFilterDropdown.current.value = 'allSimilarities';
  };

  useEffect(() => {
    document.addEventListener('comparisonReviewFlaggedPages', onReviewFlaggedPages);

    return () => {
      document.removeEventListener('comparisonReviewFlaggedPages', onReviewFlaggedPages);
    };
  });

  useEffect(() => {
    onPageSelectionChange({
      filters: [statusFilter, similarityFilter],
      searchValue,
    });
  }, [searchValue, statusFilter, similarityFilter, onPageSelectionChange]);

  return (
    <div className="comparison-page-selectors">
      <SitemapComparisonSearchBar onSearchValueChange={setSearchValue} />
      <div className="filters">
        <div className="similarity-filter-container">
          <select id="similarity-filter" defaultValue="allSimilarities">
            <option value="allSimilarities">All Similarities</option>
            <option value="similarityUnder99">{'Similarity < 99%'}</option>
            <option value="similarityUnder85">{'Similarity < 85%'}</option>
            <option value="similarityUnder75">{'Similarity < 75%'}</option>
            <option value="similarityUnder65">{'Similarity < 65%'}</option>
            <option value="similarityUnder50">{'Similarity < 50%'}</option>
          </select>
        </div>
        <div className="status-filter-container">
          <select id="status-filter" defaultValue="allStatuses">
            <option value="allStatuses">All Pages</option>
            <option value="onlyFlagged">Only Flagged</option>
            <option value="onlyResolved">Only Resolved</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default SitemapComparisonPageSelectors;
