import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

import { keywordsToString } from './utils';

/**
 * Component that resets formik form when data prop changes.
 * Formik `enableReinitialize` doesn't work for us. We want to keep existing value for some fields
 * (new_files and remove_files).
 *
 * This component give us control over which fields we reset
 */
export default function ResetFormOnChange({ data }) {
  const formik = useFormikContext();
  const prevRef = useRef(null);
  // Horrible hack. Formik reference is not stable. After calling setValues it changes.
  const formikRef = useRef();
  formikRef.current = formik;

  useEffect(() => {
    // Skip the first time, when form is initializing
    if (!prevRef.current) {
      prevRef.current = data;
      return;
    }

    const values = { ...data };
    values.keywords = keywordsToString(values.keywords);

    const { current: form } = formikRef;
    // Override current form values. Keep fields like new_files and remove_files
    if (!form.dirty) {
      form.resetForm({ values: { ...form.values, ...values } });
    }
  }, [data]);

  return null;
}
