/* global $ */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import FilterStatus from './List/FilterStatus';
import SinglePage from './List/SinglePage';
import UnreadCount from './List/UnreadCount';
import config from '../../config';
import { defaultFilter, FILTERS as filters } from './Comment/config';
import { getComments, getTeamMembers } from './api/request';
import { useAuth } from '../../contexts/AuthContext';
import { useListState } from './hooks/useListState';
import { useCommentsCount } from './hooks/useCommentsCount';

const { SCREENSHOT_PATH } = config;

const CommentsList = ({ sitemapId }) => {
  const [threadsById, setThreadsById] = useState({});
  const [threads, setThreads] = useState([]);
  const [sitemap, setSitemap] = useState();
  const { isOpen, setIsOpen, toggleIsOpen } = useListState();
  const { unreadCount, setCounts } = useCommentsCount();
  const [errors, setErrors] = useState([]);
  const { user } = useAuth();
  const [currentFilter, setCurrentFilter] = useState({ ...defaultFilter(user) });
  const [teamMembers, setTeamMembers] = useState([]);

  const onCommentClick = (_sitemap, commentNumber) => {
    const imgPopup = $.magnificPopup.instance;
    const item = $.magnificPopup.instance.currItem;

    const openScreenshots = () => {
      window.openScreenshots(_sitemap.node, SCREENSHOT_PATH, commentNumber);
    };

    if (imgPopup && item) {
      const { pageId: nodePageId } = _sitemap.node.data;
      if (item.data.pageId !== nodePageId) {
        imgPopup.close();
        setTimeout(() => {
          openScreenshots();
        }, 500);
      } else {
        document.dispatchEvent(
          new CustomEvent('openComment', {
            detail: { commentNumber },
          })
        );
      }
    } else {
      openScreenshots();
    }
  };

  const handleOnCommentsUpdate = ({ detail }) => {
    const { pageId, comments } = detail;

    setThreadsById((currThreads) => ({ ...currThreads, [pageId]: comments }));
  };

  const pageCommentsChanges = useCallback(
    async ({ detail }) => {
      const { page_id: pageId } = detail;
      const comments = await getComments(sitemapId, pageId);

      setThreadsById((currThreads) => ({ ...currThreads, [pageId]: comments[pageId] }));
    },
    [sitemapId]
  );

  useEffect(() => {
    const getCommentsAsync = async () => {
      try {
        const json = await getComments(sitemapId);

        setThreadsById(json);
      } catch (error) {
        setErrors(['Error loading comments']);
      }
    };

    getCommentsAsync();
  }, [sitemapId]);

  useEffect(() => {
    const showUnresolvedComments = () => {
      setIsOpen(true);
      setCurrentFilter(filters.find((f) => f.key === 'unresolved'));
    };
    const closeCommentList = () => setIsOpen(false);

    document.addEventListener('onCommentsUpdate', handleOnCommentsUpdate);
    document.addEventListener('closeCommentsList', closeCommentList);
    document.addEventListener('toggleCommentsList', toggleIsOpen);
    document.addEventListener('pageCommentsChanges', pageCommentsChanges);
    document.addEventListener('showUnresolvedComments', showUnresolvedComments);

    return () => {
      document.removeEventListener('onCommentsUpdate', handleOnCommentsUpdate);
      document.removeEventListener('toggleCommentsList', toggleIsOpen);
      document.removeEventListener('closeCommentsList', closeCommentList);
      document.removeEventListener('pageCommentsChanges', pageCommentsChanges);
      document.removeEventListener('showUnresolvedComments', showUnresolvedComments);
    };
  }, [pageCommentsChanges, toggleIsOpen, setIsOpen]);

  // Set sitemap if it's available.
  useEffect(() => {
    if (window.sitemapTree) {
      setSitemap(window.sitemapTree);
    }
  }, []);

  // Listen to treeRendered event and update sitemap tree
  useEffect(() => {
    const handleOnTreeReady = () => {
      const newTree = window.sitemapTree;
      if (newTree && (!sitemap || sitemap !== newTree)) {
        setSitemap(newTree);
      }
    };

    document.addEventListener('treeRendered', handleOnTreeReady);

    return () => {
      document.removeEventListener('treeRendered', handleOnTreeReady);
    };
  }, [setSitemap, sitemap]);

  useEffect(() => {
    const handleUnreadChanged = () => {
      if (!isOpen && unreadCount > 0) {
        setCurrentFilter({ ...defaultFilter(user) });
      }
    };

    document.addEventListener('toggleCommentsList', handleUnreadChanged);

    return () => {
      document.removeEventListener('toggleCommentsList', handleUnreadChanged);
    };
  }, [unreadCount, isOpen, user]);

  useEffect(() => {
    getTeamMembers(sitemapId)
      .then((members) => setTeamMembers(members))
      .catch(() => setErrors(['Error loading team members']));
  }, [sitemapId]);

  // Transform threads by key to an array
  useEffect(() => {
    if (threadsById && sitemap) {
      const data = window.Sitemap.tree
        .map(sitemap, (node) => {
          const { pageId, name, screenshot } = node.data;

          if (threadsById[pageId]) {
            return {
              title: name,
              screenshot,
              comments: threadsById[pageId],
              node,
            };
          }

          return null;
        })
        .filter(Boolean);

      setThreads(data);
    }
  }, [threadsById, sitemap]);

  useEffect(() => {
    // Total comments count
    const commentsTotalCount = threads.reduce((sum, l) => sum + l.comments.length, 0);

    // Unread count
    const unRead = threads.reduce((sum, page) => {
      const unreadThreads = page.comments.reduce((unread, thread) => {
        const isUnread = thread.comments.some((c) => !c.read);
        return unread + (isUnread ? 1 : 0);
      }, 0);

      return sum + unreadThreads;
    }, 0);

    setCounts(unRead, commentsTotalCount);
  }, [threads, setCounts]);

  const filteredThreads = useMemo(() => {
    let predicate = (i) => i;

    switch (currentFilter.key) {
      case 'in_progress':
      case 'private': {
        predicate = (c) => c.comment_type === currentFilter.key;
        break;
      }
      case 'unseen': {
        predicate = (c) => !c.read;
        break;
      }
      case 'unresolved': {
        predicate = (c) => !c.resolved;
        break;
      }
      case 'resolved': {
        predicate = (c) => c.resolved;
        break;
      }
      default:
        break;
    }

    return threads
      .map((item) => ({
        ...item,
        comments: item.comments
          .map((comment) => ({
            ...comment,
            comments: comment.comments.filter(predicate),
          }))
          .filter((i) => i.comments.length > 0),
      }))
      .filter((item) => item.comments.length > 0);
  }, [threads, currentFilter]);

  // Comments count
  const commentsCount = useMemo(() => {
    const count = filteredThreads.reduce((sum, l) => sum + l.comments.length, 0);
    return count;
  }, [filteredThreads]);

  return (
    <div className={`comments__wrapper ${isOpen ? 'isOpen' : ''}`}>
      <div className="comments__header">
        <h2 className="comments__title">
          {commentsCount}
          <span>&nbsp;conversations</span>
        </h2>

        <FilterStatus currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
      </div>

      <div className="comments__list">
        <TransitionGroup>
          {filteredThreads.map((sitemapComment) => (
            <CSSTransition
              key={sitemapComment.node.data.pageId}
              classNames="commentList"
              timeout={{ enter: 500, exit: 300 }}
            >
              <div className="comment">
                <div className="comment__title-wrapper">
                  <h3 className="comment__title">{sitemapComment.title}</h3>

                  <UnreadCount threads={sitemapComment.comments} />
                </div>

                {sitemapComment.comments.length > 0 &&
                  sitemapComment.comments.map((page) => (
                    <SinglePage
                      teamMembers={teamMembers}
                      page={page}
                      key={`${sitemapComment.node.data.pageId}-${page.commentNumber}`}
                      sitemap={sitemapComment}
                      onCommentClick={onCommentClick}
                    />
                  ))}
              </div>
            </CSSTransition>
          ))}
          {errors.map((error) => error)}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default CommentsList;
