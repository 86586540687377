export const MoveIcon = ({ className }) => {
  return (
    <svg className={className} height="19" viewBox="0 0 24 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>ico-move</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-975.000000, -976.000000)">
          <g transform="translate(975.000000, 976.000000)">
            <path
              d="M21.9541542,1.69506446 L9.81216179,1.69506446 C9.53781312,1.69506446 9.41396111,1.55099791 9.12864797,1.14024404 C8.79472512,0.65898902 8.3369342,0 7.25521568,0 L2.04588598,0 C0.918696196,0 0,1.10042064 0,2.45217991 L0,16.9447594 C0,18.0788828 0.917130989,19 2.04588598,19 L21.954114,19 C23.0813038,19 24,18.0773526 24,16.9447594 L23.9984308,5.84089637 L23.9984308,4.14889223 C23.9984308,2.7956028 23.0812998,1.69518216 21.95411,1.69518216 L21.9541542,1.69506446 Z M22.9951372,16.9460541 C22.9951372,17.5391678 22.527943,18.0204228 21.9525889,18.0204228 L2.04436091,18.0204228 C1.4690069,18.0204228 1.0018127,17.5376376 1.0018127,16.9460541 L1.00338192,5.81472671 L1.00338192,5.52352936 C1.00338192,4.9304157 1.47057612,4.44916068 2.04593013,4.44916068 L21.9541582,4.44916068 C22.5295122,4.44916068 22.9967064,4.93194586 22.9967064,5.52352936 L22.9967064,5.83924851 L22.9951372,16.9460541 Z M2.04424051,0.980989669 L7.25357021,0.980989669 C7.77875733,0.980989669 7.97316406,1.22467358 8.29768363,1.69212184 C8.58614324,2.10746227 8.98119745,2.67758429 9.81051632,2.67758429 L21.9525087,2.67758429 C22.4165323,2.67758429 22.8163022,3.13431751 22.9464552,3.73967245 C22.6517227,3.57261818 22.3146735,3.46993266 21.9525087,3.46993266 L2.04428064,3.46839858 C1.66332533,3.46839858 1.30903472,3.58028076 1.00173243,3.76419425 L1.00173243,2.45072822 C1.00330165,1.65531953 1.47988308,0.980950434 2.04428064,0.980950434 L2.04424051,0.980989669 Z"
              fill="currentColor"
            />
            <path
              d="M14.8387449,16 C14.5002798,16.0022368 14.1938523,15.8060612 14.0641014,15.5042512 C13.933562,15.2016878 14.0062577,14.8538481 14.2470134,14.6237192 L16.9985177,11.9760967 L14.2470134,9.32847414 C13.9632582,9.00856516 13.9828089,8.53320227 14.2907873,8.23593146 C14.5987757,7.93864133 15.0912249,7.91978907 15.4226461,8.19367805 L18.7580697,11.4132427 L18.7572873,11.4124874 C19.0809042,11.7263699 19.0809042,12.2334107 18.7572873,12.5472932 L15.4218637,15.7668578 L15.4226461,15.7668578 C15.2670931,15.915499 15.0576066,15.9992467 14.8387269,16 L14.8387449,16 Z"
              fill="currentColor"
            />
            <path
              d="M6.12838401,11 L17.2504331,11 C17.6645931,11 18,11.3360947 18,11.750237 C18,12.1643793 17.6645931,12.500474 17.2504331,12.500474 C10.1139364,12.500474 6.40658665,12.500474 6.12838401,12.500474 C6.12838401,12.500474 5.40743125,11.819093 6.12838401,11 Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MoveIcon;
