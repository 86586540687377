/* global toastr, easydropdown */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState, useEffect, useRef } from 'react';

import SitemapComparisonSearchBar from './SitemapComparisonSearchBar';
import flagSettingsImage from './flag-settings.svg';
import { flagAllPages, resolveAllFlaggedPages } from './request';

function SitemapComparisonToolbar({
  flaggingEnabled = false,
  pages,
  flagNotificationUserIds,
  openFlagSettings,
  sitemapComparisonId,
  onPageSelectionChange = () => {},
}) {
  const [canFlagAll, setCanFlagAll] = useState(pages.some((p) => p.similarityIndex < 1.0 && p.status !== 'FLAGGED'));
  const [canResolveAll, setCanResolveAll] = useState(pages.some((p) => p.status === 'FLAGGED'));
  const [areAllFlaggedPagesResolved, setAreAllFlaggedPagesResolved] = useState(
    pages.some((p) => p.status === 'RESOLVED') && pages.every((p) => p.status !== 'FLAGGED')
  );
  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  const [flagFilter, setFlagFilter] = useState('allStatuses');
  const [similarityFilter, setSimilarityFilter] = useState('allSimilarities');
  const [searchValue, setSearchValue] = useState('');
  const statusFilterDropdown = useRef(null);
  const similarityFilterDropdown = useRef(null);

  useEffect(() => {
    statusFilterDropdown.current = easydropdown('#status-filter', {
      callbacks: {
        onSelect: setFlagFilter,
      },
    });
    similarityFilterDropdown.current = easydropdown('#similarity-filter', {
      callbacks: {
        onSelect: setSimilarityFilter,
      },
    });
    return () => {
      statusFilterDropdown.current.destroy();
      similarityFilterDropdown.current.destroy();
    };
  }, []);

  const onReviewFlaggedPages = () => {
    statusFilterDropdown.current.value = 'onlyFlagged';
    similarityFilterDropdown.current.value = 'allSimilarities';
  };

  useEffect(() => {
    document.addEventListener('comparisonReviewFlaggedPages', onReviewFlaggedPages);

    return () => {
      document.removeEventListener('comparisonReviewFlaggedPages', onReviewFlaggedPages);
    };
  });

  useEffect(() => {
    onPageSelectionChange({
      filters: [flagFilter, similarityFilter],
      searchValue,
    });
  }, [searchValue, flagFilter, similarityFilter, onPageSelectionChange]);

  useEffect(() => {
    setCanFlagAll(pages.some((p) => p.similarityIndex < 1.0 && p.status !== 'FLAGGED'));
    setCanResolveAll(pages.some((p) => p.status === 'FLAGGED'));
    setAreAllFlaggedPagesResolved(
      pages.some((p) => p.status === 'RESOLVED') && pages.every((p) => p.status !== 'FLAGGED')
    );
  }, [pages]);

  const batchPageUpdates = (apiCall, eventName) => {
    if (!flagNotificationUserIds) {
      openFlagSettings();
      return;
    }
    setButtonsEnabled(false);
    apiCall(sitemapComparisonId)
      .then(() => {
        document.dispatchEvent(new CustomEvent(eventName));
      })
      .catch(() => {
        toastr.error('Sorry, something went wrong. Please try again later');
      })
      .finally(() => {
        setButtonsEnabled(true);
      });
  };

  const flagAll = () => {
    batchPageUpdates(flagAllPages, 'comparisonAllPagesFlagged');
  };

  const resolveAll = () => {
    batchPageUpdates(resolveAllFlaggedPages, 'comparisonAllFlaggedPagesResolved');
  };

  const flagAllButton = flaggingEnabled && canFlagAll && (
    <button className="btn btn-muted coral-red" type="button" onClick={flagAll} disabled={!buttonsEnabled}>
      Flag All
    </button>
  );

  const allFlaggedButton = flaggingEnabled && !canFlagAll && (
    <button className="btn btn-primary coral-red" type="button" disabled={!buttonsEnabled}>
      All Flagged
    </button>
  );

  const resolveAllButton = flaggingEnabled && canResolveAll && (
    <button className="btn btn-muted gray" type="button" onClick={resolveAll} disabled={!buttonsEnabled}>
      Resolve All
    </button>
  );

  const allResolvedButton = flaggingEnabled && areAllFlaggedPagesResolved && (
    <button className="btn btn-primary" type="button" disabled={!buttonsEnabled}>
      All Resolved
    </button>
  );

  const toolbarButtons = flaggingEnabled && (
    <div className="toolbar-buttons">
      {allResolvedButton}
      {resolveAllButton}
      {flagAllButton}
      {allFlaggedButton}
      <img
        className="flag-settings ml-3"
        src={flagSettingsImage}
        alt="Flag settings"
        aria-label="Flag settings"
        role="button"
        onClick={openFlagSettings}
      />
    </div>
  );

  return (
    <div className="comparison-toolbar">
      <SitemapComparisonSearchBar onSearchValueChange={setSearchValue} />
      {toolbarButtons}
      <div className="toolbar-filters">
        <div className="similarity-filter-container">
          <select id="similarity-filter" defaultValue="allSimilarities">
            <option value="allSimilarities">All Similarities</option>
            <option value="similarityUnder99">{'Similarity < 99%'}</option>
            <option value="similarityUnder85">{'Similarity < 85%'}</option>
            <option value="similarityUnder75">{'Similarity < 75%'}</option>
            <option value="similarityUnder65">{'Similarity < 65%'}</option>
            <option value="similarityUnder50">{'Similarity < 50%'}</option>
          </select>
        </div>
        <div className="status-filter-container">
          <select id="status-filter" defaultValue="allStatuses">
            <option value="allStatuses">All Pages</option>
            <option value="onlyFlagged">Only Flagged</option>
            <option value="onlyResolved">Only Resolved</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default SitemapComparisonToolbar;
