/* global treeState */
import { useState, useEffect } from 'react';

export const useIsOrganizedByPath = () => {
  const [isOrganizedByPath, setIsOrganizedByPath] = useState(treeState.isOrganizedByPath);

  useEffect(() => {
    const onTreeStateChanged = () => {
      setIsOrganizedByPath(treeState.isOrganizedByPath);
    };

    document.addEventListener('treeStateChanged', onTreeStateChanged);

    return () => {
      document.removeEventListener('treeStateChanged', onTreeStateChanged);
    };
  }, []);

  return isOrganizedByPath;
};

export default useIsOrganizedByPath;
