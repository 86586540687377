/* global moment */
import styled from 'styled-components';

import CloseButton from '../../shared/CloseButton';

const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Placeholder = styled.div`
  color: #fce4a0;
`;

function ComparisonSubject({ sitemap, onClose, className = '' }) {
  if (!sitemap) {
    return (
      <PlaceholderContainer className={`subject-container ${className}`}>
        <Placeholder>Please select one more sitemap!</Placeholder>
      </PlaceholderContainer>
    );
  }

  return (
    <div className={`subject-container ${className}`}>
      <CloseButton className="subject-close" onClick={onClose} />
      {sitemap.sitemapNickname && <div className="subject-field nickname">{sitemap.sitemapNickname}</div>}
      <div className="subject-field url">{sitemap.sitemapUrl}</div>
      <div className="subject-field date">{moment(sitemap.sitemapFinishedAt).format('MMM D, YYYY [at] h:mma')}</div>
      <div className="subject-field id">{`ID: ${sitemap.sitemapCrawlVersion}`}</div>
    </div>
  );
}

export default ComparisonSubject;
