import { useState } from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  margin: auto;
  border: 1px solid #00ffaf;
  border-radius: 4px;
  cursor: pointer;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: #20bbff;
    margin: 3px;
    display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
  }
`;

function Checkbox({ isInitiallyChecked = false, onChange = () => {} }) {
  const [isChecked, setIsChecked] = useState(isInitiallyChecked);

  const onClick = (event) => {
    event.stopPropagation();
    onChange(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <div className="text-center">
      <StyledCheckbox isChecked={isChecked} onClick={onClick} />
    </div>
  );
}

export default Checkbox;
