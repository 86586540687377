/* global $, dataConfirmModal */
import { createRef, PureComponent } from 'react';
import styled from 'styled-components';

import MouseTooltip from './Draggable/Tooltip';
import Pointer from './Draggable/Pointer';
import { relativePositionFromEvent } from './Comment/helpers';
import { AuthContext } from '../../contexts/AuthContext';
import { getComments, getTeamMembers } from './api/request';
import { navigateTo } from '../../common/location';

const PointContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const PointWrapper = styled.div`
  position: absolute;
`;

const LeaveCommentSpan = styled.span`
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  padding: 7px 10px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  width: 163px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

class Comments extends PureComponent {
  constructor(props) {
    super(props);

    this.container = createRef();

    this.state = {
      comments: [],
      teamMembers: [],
      isMounted: false,
      isOverImage: false,
      areCommentsOpen: false,
      showLeaveCommentTooltip: true,
    };
  }

  async componentDidMount() {
    const { pageId, sitemapId } = this.props;

    if (this.isDisabled()) {
      this.setState({
        isMounted: true,
      });
      return;
    }

    const comments = await getComments(sitemapId, pageId);
    // Always fetch team members.
    // They are used on the mention select (trigger with @) and to format comment (see app/javascript/components/comments/Comment/helpers.js#humanCommentText)
    const teamMembers = await getTeamMembers(sitemapId);

    this.setState({
      teamMembers,
      isMounted: true,
      comments: comments[pageId] || [],
    });

    document.addEventListener('pageCommentsChanges', this.handleCommentChanges);
  }

  async componentDidUpdate(prevProps) {
    const { pageId } = this.props;

    if (pageId !== prevProps.pageId) {
      // Clear comments while fetching new ones
      this.setState({
        comments: [],
        areCommentsOpen: false,
        showLeaveCommentTooltip: true,
        isOverImage: false,
      });

      this.onPagechange((comments) => {
        this.setState({
          comments,
        });
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('pageCommentsChanges', this.handleCommentChanges);
  }

  async onPagechange(callback) {
    const { pageId, sitemapId } = this.props;

    if (this.isDisabled()) {
      callback([]);

      return;
    }

    const comments = await getComments(sitemapId, pageId);

    // Page changed while we were fetching comments? stop here
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.pageId !== pageId) {
      return;
    }

    callback(comments[pageId] || []);
  }

  handleCommentChanges = async ({ detail }) => {
    const { pageId, sitemapId } = this.props;
    const { page_id: pageChangedId } = detail;

    if (pageChangedId === pageId) {
      const comments = await getComments(sitemapId, pageId);

      this.setState({
        comments: comments[pageId] || [],
      });
    }
  };

  onImageClick = (event) => {
    event.preventDefault();
    const { user } = this.context;

    document.dispatchEvent(new Event('closeCommentsList'));

    if (this.state.areCommentsOpen || !this.state.isMounted) {
      return;
    }

    if (this.props.disabledWarning) {
      this.props.disabledWarning();
      return;
    }

    if (!user) {
      // this avoid infinite event loop between modal and magnific popup
      $.magnificPopup.instance.close();

      dataConfirmModal.confirm({
        title: 'Please sign up',
        text: 'You need to sign up to leave a comment',
        commit: 'Sign up',
        cancel: 'Cancel',
        focus: 'commit',
        onConfirm: () => {
          navigateTo('/users/sign_up');
        },
        onCancel: () => {},
      });
      return;
    }

    let lastNumber = null;

    // eslint-disable-next-line no-restricted-syntax
    for (const comment of this.state.comments) {
      if (comment.commentNumber > lastNumber) {
        lastNumber = comment.commentNumber;
      }
    }

    const newComment = {
      isNew: true,
      comments: [],
      commentNumber: lastNumber + 1,
      geometry: relativePositionFromEvent(event, this.container.current),
    };

    this.setState((state) => ({
      areCommentsOpen: true,
      comments: [...state.comments, newComment],
    }));
  };

  removePointer = (commentNumber) => {
    this.setState(
      (prevState) => {
        const index = prevState.comments.findIndex((c) => c.commentNumber === commentNumber);
        const comments = [...prevState.comments];

        comments.splice(index, 1);

        return {
          areCommentsOpen: false,
          comments,
        };
      },
      () => {
        this.onDispatchComments(this.state.comments, commentNumber);
      }
    );
  };

  removeComment = (threadNumber, commentNumber) => {
    this.setState(
      (prevState) => {
        let index = prevState.comments.findIndex((c) => c.commentNumber === threadNumber);
        const threads = [...prevState.comments];
        const thread = threads[index];
        index = thread.comments.findIndex((c) => c.id === commentNumber);
        thread.comments.splice(index, 1);

        return { comments: threads };
      },
      () => {
        this.onDispatchComments(this.state.comments, commentNumber);
      }
    );
  };

  toggleOpenComments = (areCommentsOpen) => {
    this.setState((prevState) => ({
      areCommentsOpen: areCommentsOpen === undefined ? !prevState.areCommentsOpen : areCommentsOpen,
    }));
  };

  getPointerProps = (comment) => {
    return {
      x: comment.geometry.x,
      y: comment.geometry.y,
      pageId: this.props.pageId,
      comments: comment.comments,
      sitemapId: this.props.sitemapId,
      isNew: comment.isNew,
      targetRef: this.container.current,
      removePointer: this.removePointer,
      removeComment: (commentNumber) => this.removeComment(comment.commentNumber, commentNumber),
      teamMembers: this.state.teamMembers,
      toggleOpenComments: this.toggleOpenComments,
      onDispatchComments: this.onDispatchComments,
      onMouseOver: () => this.setState({ showLeaveCommentTooltip: false }),
      onMouseLeave: () => this.setState({ showLeaveCommentTooltip: true }),
      commentNumber: this.props.commentNumber,
      currentUser: this.props.currentUser,
      areCommentsOpen: this.state.areCommentsOpen,
    };
  };

  getMouseTooltipProps = () => {
    const { showLeaveCommentTooltip, isOverImage, areCommentsOpen } = this.state;

    return {
      offsetX: 10,
      offsetY: 10,
      targetRef: this.container.current,
      visible: showLeaveCommentTooltip && isOverImage && !areCommentsOpen,
    };
  };

  onDispatchComments = (commentsToUpdate, commentNumber) => {
    this.setState(
      (prevState) => {
        const toUpdate = prevState.comments.findIndex((c) => c.commentNumber === commentNumber);
        const comments = [...prevState.comments];

        comments[toUpdate] = {
          ...comments[toUpdate],
          comments: commentsToUpdate,
        };

        return { comments };
      },
      () => {
        document.dispatchEvent(
          new CustomEvent('onCommentsUpdate', { detail: { pageId: this.props.pageId, comments: this.state.comments } })
        );
      }
    );
  };

  isDisabled() {
    const { disabledWarning } = this.props;

    return !!disabledWarning;
  }

  render() {
    const { comments, isMounted } = this.state;
    const { pageId, image } = this.props;

    return (
      <Wrapper
        ref={this.container}
        onFocus={() => undefined}
        onMouseOver={() => this.setState({ isOverImage: true })}
        onMouseLeave={() => this.setState({ isOverImage: false })}
        onClick={this.onImageClick}
      >
        <MouseTooltip {...this.getMouseTooltipProps()}>
          {isMounted && <LeaveCommentSpan>Click to leave a comment</LeaveCommentSpan>}
        </MouseTooltip>

        {isMounted && (
          <PointContainer>
            {comments.length > 0 &&
              comments.map((comment) => (
                <PointWrapper key={`${pageId}-${comment.commentNumber}-${image}`}>
                  <Pointer {...this.getPointerProps(comment)}>{comment.commentNumber}</Pointer>
                </PointWrapper>
              ))}
          </PointContainer>
        )}
      </Wrapper>
    );
  }
}

Comments.contextType = AuthContext;

export default Comments;
