import { useEffect, useRef } from 'react';

import { isIncommingEvent, parseIncommingAction } from '../utils';

export default function useFlowLoadListener(callback) {
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  useEffect(() => {
    const handleOnMessage = (event) => {
      if (!isIncommingEvent(event) || parseIncommingAction(event.data.message) !== 'ready') {
        return;
      }

      if (callbackRef.current) {
        callbackRef.current();
      }

      // Listen one semantic
      window.removeEventListener('message', handleOnMessage);
    };

    window.addEventListener('message', handleOnMessage);

    return () => window.removeEventListener('message', handleOnMessage);
  }, []);
}
