/* global $, treeState */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import config from '../../../config';
import eye from './eye.svg';
import { TagSquare } from '../../sitemap-tagging/TagSquare';
import { absoluteThumbUrl, treeNodeThumbUrl, nameOrDefault } from '../../../sitemap/utils';

const { SCREENSHOT_PATH } = config;

const maxChars = 54;

function SitemapPage({ node }) {
  const { data: page } = node;
  const name = nameOrDefault(page);
  const title = name.length < maxChars ? name : `${name.slice(0, maxChars - 3)}...`;

  const thumbnailUrl = absoluteThumbUrl(page.thumbnail || treeNodeThumbUrl(page.screenshot));

  const openSitemapSlider = (event) => {
    event.preventDefault();

    const imgPopup = $.magnificPopup.instance;
    const item = $.magnificPopup.instance.currItem;

    if (imgPopup && item && item.data.pageId !== page.pageId) {
      imgPopup.close();
      setTimeout(() => {
        window.openScreenshots(node, SCREENSHOT_PATH);
      }, 500);
    } else {
      window.openScreenshots(node, SCREENSHOT_PATH);
    }
  };

  const toggleSelection = (e) => {
    e.stopPropagation();
    document.dispatchEvent(new CustomEvent('treeNodeToggleSelection', { detail: { target: node } }));
  };

  const hasContent = Boolean(page.withContent);

  return (
    <div className="sitemap-page-container">
      <div className={`sitemap-page ${hasContent ? 'with-content' : ''} ${page.external ? 'external' : ''}`}>
        <LazyLoad overflow resize>
          <Tags tags={page.tags} />
          <div className={`thumbnail-container ${page.selected ? 'selected' : ''}`}>
            {thumbnailUrl ? (
              <img className="thumbnail" src={thumbnailUrl} alt={title} />
            ) : (
              <div className="thumbnail" />
            )}
            <div className="thumbnail-selected-overlay" />
            <div className="thumbnail-overlay" onClick={openSitemapSlider}>
              <img src={eye} alt="View Page" />
            </div>
            <Checkbox checked={page.selected} title="Click to select the page" onClick={toggleSelection} />
          </div>
          <div className="page-title" title={name}>
            {title}
          </div>
        </LazyLoad>
      </div>
    </div>
  );
}

function Tags({ page, tags }) {
  const list = tags || treeState.tagsOf(page.id, { hydratate: true });

  return (
    <div className="tags">
      {list.map((tag) => (
        <TagSquare key={tag.id} tag={tag} className="tag" />
      ))}
    </div>
  );
}

function Checkbox({ checked, title, onClick }) {
  const classes = classNames('shadow-sm checkbox', { 'checkbox-checked': checked });

  return (
    <button type="button" className={classes} title={title} aria-label={title} onClick={onClick}>
      <i className="fa fa-check" />
    </button>
  );
}

export default SitemapPage;
