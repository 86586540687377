import { memo, useRef, useState, useEffect } from 'react';

import { Select, Caret, Option, OptionsList, Button, Bullet } from './FilterStatus.styles';
import { useAuth } from '../../../contexts/AuthContext';
import { filterForUser } from '../Comment/config';

const FilterStatus = ({ currentFilter, setCurrentFilter }) => {
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(currentFilter);
  const [filters, setFilters] = useState([]);
  const { user } = useAuth();

  const onFilter = (option) => () => {
    setSelected(option);
    setIsOpen(false);
    setCurrentFilter(option);
  };

  const handleClickOutside = (event) => {
    if (menuRef && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => setFilters(filterForUser(user)), [user]);

  useEffect(() => {
    if (currentFilter) {
      setSelected(currentFilter);
    }
  }, [currentFilter]);

  return (
    <Select ref={menuRef}>
      <Option onClick={() => setIsOpen(true)}>
        <Bullet color={selected.color} />

        {selected.name}

        <Caret />
      </Option>

      {isOpen && (
        <OptionsList>
          {filters.map((option) => {
            return (
              <li key={option.key} className={`${option.key} ${selected === option.key ? 'selected' : ''}`}>
                <Button onClick={onFilter(option)}>
                  <Bullet color={option.color} />

                  {option.name}
                </Button>
              </li>
            );
          })}
        </OptionsList>
      )}
    </Select>
  );
};

export default memo(FilterStatus);
