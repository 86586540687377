import { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useTags } from '../../sitemap-tagging/hooks/useTags';
import { TagPicker, TagPickerLabel } from '../../sitemap-tagging/TagPicker';
import { SitemapProvider } from '../../../contexts/SitemapContext';
import { DEFAULT_FILTER } from '../../../sitemap/tree-filter';
import { TagList } from '../../sitemap-tagging/TagList';

/**
 * Filter tagIds collection. Keep ids that exists on tags collection.
 *
 * Return original list if there are no changes. Prevent unnecesary reRenders
 */
const constraintTo = (tagIds, tags) => {
  const filtered = tagIds.filter((id) => tags.some((tag) => tag.id === id));

  if (tagIds.length !== filtered.length) {
    return filtered;
  }

  return tagIds;
};

const PlaceholderBorder = styled.div`
  position: relative;
  background: linear-gradient(180deg, #00ffaa 0%, #d700f7 100%);
  padding: 2px;
  border-radius: 4px;
`;

const PlaceholderContent = styled.div`
  position: relative;
  padding: 3px 7px;
  background: #312f2f;
  color: #d8d8d8;
  border-radius: inherit;
  font-size: 0.81rem;
  line-height: 1;
`;

const Placeholder = ({ children }) => {
  return (
    <PlaceholderBorder>
      <PlaceholderContent>{children}</PlaceholderContent>
    </PlaceholderBorder>
  );
};

const HAS_CONTENT_TAG = {
  id: 'withContent',
  name: 'Has Content',
  short: 'Content',
  color: '#008CCA',
};

const HAS_COMMENTS_TAG = {
  id: 'withComments',
  name: 'Has Comments',
  short: 'Comments',
  color: '#553daf',
};

const URL_CHANGED_TAG = {
  id: 'urlChanged',
  name: 'URL Changed',
  short: 'URL',
  color: '#2d9660',
};

const EXTERNAL_URL_TAG = {
  id: 'externalUrl',
  name: 'External URL',
  short: 'External',
  color: '#714242',
};

function useFilter() {
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent('treeNodeFilterChange', { detail: filter }));
  }, [filter]);

  // Change filter prop value and trigger reRender. Keep filter stable if there are no changes
  const updateFilter = useCallback(
    (prop, updater) => {
      setFilter((f) => {
        const currentValue = f[prop];
        const newValue = updater(currentValue);

        if (currentValue !== newValue) {
          return { ...f, [prop]: newValue };
        }

        return f;
      });
    },
    [setFilter]
  );

  return [filter, { setFilter, updateFilter }];
}

const NodeFilter = ({
  sitemapId,
  tagEditionEnabled,
  pageContentEnabled,
  commentSitemapEnabled,
  sitemapEditionEnabled,
}) => {
  const [tags, { addTag, updateTag, removeTag }] = useTags();

  const [filter, { updateFilter }] = useFilter();

  // Very similar to constraint on treeState
  useEffect(() => {
    updateFilter('tags', (s) => constraintTo(s, tags));
  }, [tags, updateFilter]);

  const extraTags = useMemo(() => {
    const extras = [];
    if (commentSitemapEnabled) {
      extras.push(HAS_COMMENTS_TAG);
    }
    if (pageContentEnabled) {
      extras.push(HAS_CONTENT_TAG);
    }
    if (sitemapEditionEnabled) {
      extras.push(EXTERNAL_URL_TAG);
      extras.push(URL_CHANGED_TAG);
    }
    return extras;
  }, [commentSitemapEnabled, pageContentEnabled, sitemapEditionEnabled]);

  const handleSelect = (ids) => {
    const [id] = ids;
    if (extraTags.some((t) => t.id === id)) {
      return updateFilter(id, () => true);
    }

    return updateFilter('tags', (s) => [...s, ...ids]);
  };

  const handleUnselect = (ids) => {
    const [id] = ids;
    if (extraTags.some((t) => t.id === id)) {
      return updateFilter(id, () => false);
    }

    return updateFilter('tags', (s) => s.filter((tId) => ids.indexOf(tId) < 0));
  };

  const allTags = useMemo(() => [...extraTags, ...tags], [tags, extraTags]);
  const extraValue = useMemo(() => extraTags.filter((t) => filter[t.id]).map((t) => t.id), [filter, extraTags]);
  const allValue = useMemo(() => extraValue.concat(filter.tags), [extraValue, filter.tags]);

  if (allTags.length === 0 && !tagEditionEnabled) {
    return null;
  }

  const tagsFilter = tags.length > 0 || tagEditionEnabled;
  const mixedFilters = extraTags.length > 0 && tagsFilter;

  return (
    <SitemapProvider sitemapId={sitemapId}>
      <TagPicker
        className="border-0"
        title={mixedFilters && 'TAGS'}
        tags={tags}
        value={filter.tags}
        editable={tagEditionEnabled}
        maxLength={5}
        onSelect={handleSelect}
        onUnselect={handleUnselect}
        onCreate={addTag}
        onUpdate={updateTag}
        onDelete={removeTag}
        label={
          <TagPickerLabel
            tags={allTags}
            value={allValue}
            charsBreakpoint={30}
            placeholder={<Placeholder>{tagsFilter && !mixedFilters ? 'All Tags' : 'Filters'}</Placeholder>}
          />
        }
        extraTags={
          <TagList tags={extraTags} selected={extraValue} onSelect={handleSelect} onUnselect={handleUnselect} />
        }
      />
    </SitemapProvider>
  );
};

export default NodeFilter;
