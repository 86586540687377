/* global toastr */
import { useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { useSitemapTreeMode } from '../../../stores/sitemap-tree-mode';
import { useInputSearch } from '../../../stores/input-search';
import { useSitemapPageSearch } from '../../../stores/sitemap-page-search';

const titles = {
  vertical: 'Switch to vertical orientation',
  horizontal: 'Switch to horizontal orientation',
  grid: 'Open grid view',
};

const Dropdown = styled.div`
  background-color: #3b3939 !important;
  border: 1px solid #97979729;
`;

const PADDING = 10;

export default function SwitchTreeViewOption() {
  const { orientation, isGridView, setMode } = useSitemapTreeMode();
  const isVerticalView = orientation === 'vertical';
  const { setIsOpen: setInputSearchOpen } = useInputSearch();
  const { setIsOpen: setPageSearchOpen } = useSitemapPageSearch();

  const onToggleOrientation = (newMode) => (event) => {
    event.preventDefault();
    if (typeof window.sitemapTree === 'undefined') {
      toastr.error('Fetching data, please wait');
      return;
    }

    setMode(newMode);

    if (newMode !== 'grid') {
      setInputSearchOpen(false);
      setPageSearchOpen(false);
      const isVertical = newMode === 'vertical';

      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('sitemapTreeVerticalityUpdated', { detail: { isVertical } }));
      }, 0);
    } else {
      setInputSearchOpen(true);
      setPageSearchOpen(true);
    }
  };

  const menuStyles = useMemo(() => {
    return {
      minWidth: 'auto',
      paddingLeft: PADDING,
      paddingRight: PADDING,
      translate: isVerticalView ? '11px' : '16px',
    };
  }, [isVerticalView]);

  return (
    <div className="dropdown d-inline-flex">
      <button
        type="button"
        id="orientation-toggle"
        className={classNames('btn btn-link button-icon dropdown-toggle', {
          grid: isGridView,
          vertical: orientation === 'vertical' && !isGridView,
          horizontal: orientation === 'horizontal' && !isGridView,
        })}
        data-toggle="dropdown"
        data-offset="0,5"
        title="Click to change orientation or access grid view"
        aria-label="Click to change orientation or access grid view"
      />

      <Dropdown className="dropdown-menu dropdown-menu-right" style={menuStyles}>
        <div className="d-flex flex-column flex-gap-3 align-items-center">
          <OrientationButton
            type="vertical"
            disabled={orientation === 'vertical' && !isGridView}
            onClick={onToggleOrientation('vertical')}
          />
          <OrientationButton
            type="horizontal"
            disabled={orientation === 'horizontal' && !isGridView}
            onClick={onToggleOrientation('horizontal')}
          />
          <OrientationButton type="grid" disabled={isGridView} onClick={onToggleOrientation('grid')} />
        </div>
      </Dropdown>
    </div>
  );
}

function OrientationButton({ type, disabled, onClick }) {
  return (
    <button
      type="button"
      id="orientation-toggle"
      className={classNames('btn btn-link button-icon', type, { disabled })}
      title={titles[type]}
      aria-label={titles[type]}
      onClick={onClick}
    />
  );
}
