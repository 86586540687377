/* global treeState */
import { useState, useEffect } from 'react';

export const useSitemapTree = (listenTo = 'treeStateChanged') => {
  const [tree, setTree] = useState(treeState.sitemapTree);

  useEffect(() => {
    const refreshTree = () => {
      setTree(treeState.sitemapTree);
    };

    document.addEventListener(listenTo, refreshTree);

    return () => {
      document.removeEventListener(listenTo, refreshTree);
    };
  }, [listenTo]);

  return tree;
};

export default useSitemapTree;
