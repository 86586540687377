/* global toastr */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { getSitemapContent, updateSitemapContent } from './api';
import { useSitemap } from '../../contexts/SitemapContext';
import { useAuth } from '../../contexts/AuthContext';

export const sitemapContentKey = (sitemapId) => ['sitemap', sitemapId, 'content'];

export function useSitemapContent() {
  const { sitemapId } = useSitemap();

  return useQuery({
    queryKey: sitemapContentKey(sitemapId),
    queryFn: () => getSitemapContent(sitemapId),
    enabled: Boolean(sitemapId),
  });
}

export function useUpdateSitemapContent() {
  const { sitemapId } = useSitemap();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => updateSitemapContent(sitemapId, user.random_uuid, data),
    mutationKey: sitemapContentKey(sitemapId),
    onSuccess(sitemapContent) {
      // document.dispatchEvent(new CustomEvent('treeNodeChangePageWithContent', { detail: { target: 'sitemapId' } }));
      queryClient.setQueryData(sitemapContentKey(sitemapId), sitemapContent);
    },
    onError() {
      toastr.error("The data couldn't be saved...", '', { preventDuplicates: true });
    },
  });
}
