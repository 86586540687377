import classNames from 'classnames';

import CommentsIcon from './CommentsIcon';
import { useToggleComments } from './hooks/useToggleComments';

export default function ToggleListButton({ className }) {
  const toggleComments = useToggleComments();

  return (
    <button
      type="button"
      className={classNames('btn btn-muted d-inline-flex align-items-center flex-gap-3', className)}
      onClick={toggleComments}
    >
      <CommentsIcon className="text-white" />
      Comments
    </button>
  );
}
