/* global toastr */
import { useState, useEffect } from 'react';

import { canExpandAll } from '../../sitemap/expand-collapse-all';

/**
 * Check sitemap tree to see what action button should be shown: collapse all, expand all, no action.
 */
const getActiveButton = () => {
  // Sitemap is not render? Then show no button
  if (!window.sitemapTree && !window.treeState) {
    return undefined;
  }

  return canExpandAll(window.sitemapTree, window.treeState.size);
};

export default function ExpandCollapseOption() {
  // true: show expand
  // false: show collapse
  // null: no button
  const [expand, setExpand] = useState(getActiveButton);

  useEffect(() => {
    const refreshState = () => {
      setExpand(getActiveButton());
    };

    document.addEventListener('treeRendered', refreshState);

    return () => {
      document.removeEventListener('treeRendered', refreshState);
    };
  }, []);

  const clickHandler = () => {
    if (typeof window.sitemapTree === 'undefined') {
      toastr.error('Fetching data, please wait');
      return;
    }

    setExpand(!expand);
    document.dispatchEvent(new Event(expand ? 'treeExpandAll' : 'treeCollapseAll'));
  };

  return (
    <>
      {expand && (
        <button
          type="button"
          className="btn btn-link button-icon"
          id="expand-all"
          title="Expand all"
          aria-label="Expand all"
          onClick={clickHandler}
        />
      )}
      {expand === false && (
        <button
          type="button"
          className="btn btn-link button-icon"
          id="collapse-all"
          title="Collapse all"
          aria-label="Collapse all"
          onClick={clickHandler}
        />
      )}
    </>
  );
}
