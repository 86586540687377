import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { createTag, updateTag } from './api/request';
import { ColorPickerField } from './TagForm/ColorPicker';
import { Input } from './TagForm/Input';
import { formErrorHandler } from './utils/forms';
import { Box } from './Box';
import { useSitemap } from '../../contexts/SitemapContext';

const COLORS = ['#01A16F', '#A726C7', '#D13535', '#3E65DB', '#FFD014', '#242424'];

const TagSchema = Yup.object().shape({
  name: Yup.string().max(150).required(),
  color: Yup.string().required(),
});

export const defaultColor = COLORS[0];

export const TagForm = ({ tag, onClose, onUpdate, onCreate }) => {
  const { sitemapId } = useSitemap();

  const initialValues = tag || {};

  const handleSubmit = async (data, actions) => {
    try {
      if (tag.id) {
        await updateTag(tag.id, data);

        onUpdate({ ...tag, ...data });
      } else {
        const newTag = await createTag(sitemapId, data);

        onCreate(newTag);
      }
    } catch (errors) {
      formErrorHandler(errors, actions, TagSchema);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={TagSchema} onSubmit={handleSubmit}>
      {({ isValid, isSubmitting, status }) => (
        <Box as={Form} className="px-3 pb-3 pt-2">
          <button type="button" className="btn btn-sm btn-link btn-small pl-0 mb-3" onClick={onClose}>
            <i className="fa fa-arrow-left" /> Back
          </button>
          <Input name="name" type="text" placeholder="Tag title" autoFocus="autofocus" autoComplete="off" />
          <Input name="color" as={ColorPickerField} colors={COLORS} />
          <div className="help-block form-text with-errors form-control-feedback">{status}</div>
          <div className="text-right">
            <button type="submit" disabled={!isValid || isSubmitting} className="btn btn-primary btn-sm">
              Save
            </button>
          </div>
        </Box>
      )}
    </Formik>
  );
};

export default TagForm;
