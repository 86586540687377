import { get, patch, parsedErrors } from '../../common/request';

export function getSitemapContent(sitemapId) {
  const url = `/api/v1/sitemaps/${sitemapId}/content`;

  return get(url)
    .catch((err) => {
      if (err.status === 404 || err.status === 403) {
        return null;
      }

      throw err;
    })
    .catch(parsedErrors);
}

export function updateSitemapContent(sitemapId, userId, sitemapContent) {
  const url = `/api/v1/sitemaps/${sitemapId}/content`;

  return patch(url, { data: { sitemap_content: sitemapContent, done_by: userId } }).catch(parsedErrors);
}

export function addEditorFiles(sitemapId, newEditorFiles) {
  const url = `/api/v1/sitemaps/${sitemapId}/content_editor_files`;

  return patch(url, { data: { new_editor_files: newEditorFiles } }).catch(parsedErrors);
}
