import { useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';

import config from '../../config';
import BaseSpinner from '../shared/Spinner';
import { fetchUserFlow } from './api';
import useFlowLoadListener from './hooks/useFlowLoadListener';
import useFlowRefresher from './hooks/useFlowRefresher';
import useIncomingMessageHandler from './hooks/useIncomingMessageHandler';
import { isAnonymousContext } from './utils';

const { USER_FLOWS_URL } = config;

const Spinnner = styled(BaseSpinner)`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: inherit;
`;

function UserFlow({ id, flow, hasThumb, context, initialLightMode }) {
  const iframeRef = useRef();
  const stateRef = useRef({
    hasThumb,
    lastThumbResponse: null,
    flow,
  });

  // Listen to messages from FlowApp
  const handleOnMessage = useIncomingMessageHandler({ stateRef, iframeRef, id, context });
  useEffect(() => {
    window.addEventListener('message', handleOnMessage);

    return () => {
      window.removeEventListener('message', handleOnMessage);
    };
  }, [handleOnMessage]);

  // Listen to flow changes and send a message to FlowApp
  useFlowRefresher({ iframeRef, stateRef, id, context });

  useFlowLoadListener(() => {
    iframeRef.current.focus();
  });

  const appParams = useMemo(() => {
    const params = new URLSearchParams();

    params.append('lightMode', initialLightMode);

    if (isAnonymousContext(context)) {
      params.append('standalone', 'true');
    }

    return params;
  }, [context, initialLightMode]);

  // declare useFlowAppLoadListener before setting src. Make sure we don't miss the first message
  useEffect(() => {
    const params = appParams.toString();
    const querystring = params.length ? `?${params}` : '';

    iframeRef.current.src = `${USER_FLOWS_URL}${querystring}`;
  }, [appParams]);

  return <iframe title="app" id="user-flow" ref={iframeRef} allow="clipboard-write" data-hj-allow-iframe />;
}

function UserFlowWrapper(props) {
  const { id, context } = props;
  const [flow, setFlow] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const isAnonymous = isAnonymousContext(context);

  useLayoutEffect(() => {
    let unmounted = false;

    fetchUserFlow(id).then((response) => {
      if (!unmounted) {
        setFlow(response);
      }
    });

    return () => {
      unmounted = true;
    };
  }, [id]);

  useFlowLoadListener(() => {
    setIsLoading(false);
  });

  return (
    <>
      {flow && <UserFlow flow={flow} initialLightMode={flow?.options?.lightMode ?? false} {...props} />}
      {isLoading && <Spinnner />}
      {flow && !isLoading && isAnonymous && <ShowSaveTrigger />}
    </>
  );
}

function ShowSaveTrigger() {
  const containerRef = useRef();

  useEffect(() => {
    containerRef.current = document.getElementById('flows-signup-container');
  }, []);

  useEffect(() => {
    // Show signup button after 15 seconds
    const timer = setTimeout(() => {
      const { current: container } = containerRef;

      if (!container) {
        return;
      }

      container.style.display = 'flex';

      // otherwise opacity transition doesn't work
      setTimeout(() => {
        container.style.opacity = 1;
      }, 10);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  return null;
}

export default UserFlowWrapper;
