import { AuthProvider } from '../../contexts/AuthContext';
import CommentsList from './CommentsList';

export const CommentListWithAuthContext = (props) => {
  return (
    <AuthProvider>
      <CommentsList {...props} />
    </AuthProvider>
  );
};

export default CommentListWithAuthContext;
