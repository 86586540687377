import { useState, useEffect } from 'react';

import SitemapComparisonPage from './SitemapComparisonResult/SitemapComparisonPage';
import ExpandedSitemapComparisonPageModal from './SitemapComparisonResult/ExpandedSitemapComparisonPageModal';
import FlagSettingsModal from './SitemapComparisonResult/FlagSettingsModal';
import SitemapComparisonHeader from './SitemapComparisonResult/SitemapComparisonHeader';

function SitemapComparisonResult({
  sitemapARootUrl,
  sitemapBRootUrl,
  differentPagesCount,
  pages: initialPages,
  sitemapComparisonId,
  sitemapAFinishedAt,
  sitemapACrawlVersion,
  sitemapAPages,
  sitemapBFinishedAt,
  sitemapBCrawlVersion,
  sitemapBPages,
  users,
  inviteUserUrl,
  flagNotificationUserIds: initialFlagNotificationUserIds,
  flaggingEnabled = false,
}) {
  const [shouldShowExpandedView, setShouldShowExpandedView] = useState(false);
  const [shouldShowFlagSettingsModal, setShouldShowFlagSettingsModal] = useState(false);
  const [expandedViewPageInfo, setExpandedViewPageInfo] = useState(null);
  const [pageSelection, setPageSelection] = useState({});
  const [pages, setPages] = useState(initialPages);
  const [flagNotificationUserIds, setFlagNotificationUserIds] = useState(initialFlagNotificationUserIds);

  const onPageStatusUpdated = ({ detail }) => {
    const { path, status: newStatus } = detail;
    setPages(
      pages.map((p) => {
        const page = p;
        if (page.path === path) {
          page.status = newStatus;
        }
        return page;
      })
    );
  };

  const onFlagNotificationUserIdsUpdated = ({ detail: userIds }) => {
    setFlagNotificationUserIds(userIds);
  };

  const onAllPagesFlagged = () => {
    setPages(
      pages.map((p) => {
        const page = p;
        if (page.similarityIndex < 1.0) {
          page.status = 'FLAGGED';
        }
        return page;
      })
    );
  };

  const onAllFlaggedPagesResolved = () => {
    setPages(
      pages.map((p) => {
        const page = p;
        if (page.status === 'FLAGGED') {
          page.status = 'RESOLVED';
        }
        return page;
      })
    );
  };

  useEffect(() => {
    document.addEventListener('comparisonPageStatusUpdated', onPageStatusUpdated);
    document.addEventListener('comparisonFlagNotificationUserIdsUpdated', onFlagNotificationUserIdsUpdated);
    document.addEventListener('comparisonAllPagesFlagged', onAllPagesFlagged);
    document.addEventListener('comparisonAllFlaggedPagesResolved', onAllFlaggedPagesResolved);

    return () => {
      document.removeEventListener('comparisonPageStatusUpdated', onPageStatusUpdated);
      document.removeEventListener('comparisonFlagNotificationUserIdsUpdated', onFlagNotificationUserIdsUpdated);
      document.removeEventListener('comparisonAllPagesFlagged', onAllPagesFlagged);
      document.removeEventListener('comparisonAllFlaggedPagesResolved', onAllFlaggedPagesResolved);
    };
  });

  const getPageInfoFor = (page) => {
    const sitemapAPage = sitemapAPages.find((p) => p.url === page.url || p.path === page.path);
    const sitemapBPage = sitemapBPages.find((p) => p.url === page.url || p.path === page.path);
    return {
      page,
      sitemapAFinishedAt,
      sitemapACrawlVersion,
      sitemapAPage,
      sitemapBFinishedAt,
      sitemapBCrawlVersion,
      sitemapBPage,
    };
  };

  const showExpandedView = (page) => {
    setExpandedViewPageInfo(getPageInfoFor(page));
    setShouldShowExpandedView(true);
  };

  const showDualView = () => {
    setShouldShowExpandedView(false);
  };

  const openFlagSettings = () => {
    setShouldShowFlagSettingsModal(true);
  };

  const closeFlagSettings = () => {
    setShouldShowFlagSettingsModal(false);
  };

  const onUserIdsSelection = (selectedUserIds) => {
    setFlagNotificationUserIds(selectedUserIds);
    closeFlagSettings();
  };

  const sitemapComparisonPages = pages.map((page) => {
    // In the old days we compare pages with the same URL. Now we compare just with same path
    const pageInfo = getPageInfoFor(page);
    return (
      <SitemapComparisonPage
        key={page.url}
        pageInfo={pageInfo}
        pageSelection={pageSelection}
        sitemapComparisonId={sitemapComparisonId}
        flagNotificationUserIds={flagNotificationUserIds}
        openFlagSettings={openFlagSettings}
        onExpand={() => {
          // In the old days we compare pages with the same URL. Now we compare just with same path
          showExpandedView(page);
        }}
        flaggingEnabled={flaggingEnabled}
      />
    );
  });

  return (
    <div className="comparison-result">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <SitemapComparisonHeader
              sitemapARootUrl={sitemapARootUrl}
              sitemapBRootUrl={sitemapBRootUrl}
              flaggingEnabled={flaggingEnabled}
              pages={pages}
              onPageSelectionChange={setPageSelection}
              differentPagesCount={differentPagesCount}
              flagNotificationUserIds={flagNotificationUserIds}
              openFlagSettings={openFlagSettings}
              sitemapComparisonId={sitemapComparisonId}
            />
          </div>
        </div>
        {sitemapComparisonPages}
      </div>
      {shouldShowExpandedView && (
        <ExpandedSitemapComparisonPageModal
          pageInfo={expandedViewPageInfo}
          onClose={showDualView}
          sitemapComparisonId={sitemapComparisonId}
          flaggingEnabled={flaggingEnabled}
          flagNotificationUserIds={flagNotificationUserIds}
          openFlagSettings={openFlagSettings}
        />
      )}
      {shouldShowFlagSettingsModal && (
        <FlagSettingsModal
          sitemapComparisonId={sitemapComparisonId}
          onClose={closeFlagSettings}
          users={users}
          inviteUserUrl={inviteUserUrl}
          onUserIdsSelection={onUserIdsSelection}
          showIntroText={!flagNotificationUserIds}
          flagNotificationUserIds={flagNotificationUserIds}
        />
      )}
    </div>
  );
}

export default SitemapComparisonResult;
