import { useMemo } from 'react';

import Backdrop from '../Backdrop';
import Thumbnail from '../file-upload/Thumbnail';

function FileList({ name, value, onChange, files, disabled }) {
  const filtered = useMemo(() => {
    return files.filter((f) => !value.includes(f.id));
  }, [files, value]);

  const handleRemove = (fileId) => {
    onChange({ target: { name, value: [...value, fileId] } });
  };

  if (disabled && filtered.length === 0) {
    return 'No files';
  }

  return filtered.map(({ id, filename, url, preview }) => (
    <div key={id} className="backdrop-parent">
      <Thumbnail className="rounded" src={preview}>
        <Backdrop className="rounded flex-column py-2">
          <a
            href={`${url}?disposition=attachment`}
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-link text-decoration-none ml-auto"
            title="Click to download the file"
            aria-label="Click to download the file"
          >
            <i className="fa fa-download" />
          </a>
          <a
            href={url}
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-link text-decoration-none m-auto"
            title="Click to open the file"
            aria-label="Click to open the file"
          >
            <i className="fa fa-eye" />
          </a>
          {disabled ? (
            <div className="flex-grow-1" />
          ) : (
            <button
              type="button"
              className="btn btn-link text-danger ml-auto"
              title="Click to delete the file"
              aria-label="Click to delete the file"
              onClick={() => handleRemove(id)}
            >
              <i className="fa fa-trash" />
            </button>
          )}
        </Backdrop>
      </Thumbnail>
      <div className="my-2 text-break" title={filename}>
        {filename}
      </div>
    </div>
  ));
}

export default FileList;
