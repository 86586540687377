/* global moment */
/* eslint-disable react/no-danger */
import { memo, useEffect, useState } from 'react';
import Gravatar from 'react-gravatar';

import {
  ActionButton,
  ActionsContainer,
  CommentButton as Button,
  CommentContent,
  CommentHeader,
  CommentWrapper,
  TimeAgo,
  Username,
} from './styles';
import Textarea from './Textarea';
import WithPermissions from './WithPermissions';
import { markCommentAsRead, updateComment } from '../api/request';
import DeleteComment from './DeleteComment';
import { useAuth } from '../../../contexts/AuthContext';
import { richCommentText } from './helpers';

const replaceUrlsWithLinks = (text) => {
  if (!text) {
    return '';
  }

  // URLs starting with http://, https://
  const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;

  return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
};

const RenderComment = ({
  comment,
  comments,
  isResolved,
  teamMembers,
  handleSetComment,
  handleRemovePointer,
  handleRemoveComment,
}) => {
  const [text, setText] = useState(comment.text);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (!comment.read) {
      markCommentAsRead(comment.id, user).then(() => {
        handleSetComment({ ...comment, read: true });
      });
    }
  }, [comment, handleSetComment, user]);

  useEffect(() => {
    const newText = replaceUrlsWithLinks(comment?.text);

    setText(newText);
  }, [comment]);

  const toggleEdit = () => setIsEditing(!isEditing);

  const toggleDelete = () => setIsDeleting(!isDeleting);

  const onEditClick = () => {
    updateComment(comment.id, { body: text }, user)
      .then((updatedComment) => handleSetComment(updatedComment))
      .finally(toggleEdit);
  };

  return isDeleting ? (
    <CommentWrapper $isResolved={isResolved}>
      <DeleteComment
        comment={comment}
        isRoot={comments[0].id === comment.id}
        onCancel={toggleDelete}
        onCommentDeleted={(commentId) => handleRemoveComment(commentId)}
        onThreadDeleted={() => handleRemovePointer()}
      />
    </CommentWrapper>
  ) : (
    <CommentWrapper $isResolved={isResolved}>
      <CommentHeader>
        <Gravatar email={comment.author_email || comment.author_gravatar} className="img-responsive img" />

        <Username>{comment.author_name}</Username>

        <TimeAgo>{moment(comment.created_at).fromNow()}</TimeAgo>

        {!isResolved && (
          <WithPermissions comment={comment}>
            {({ canEditComment, canDeleteComment }) => (
              <ActionsContainer>
                {canEditComment && (
                  <ActionButton type="button" onClick={toggleEdit}>
                    <i className="icon-feather-edit-2" />
                  </ActionButton>
                )}
                {canDeleteComment && (
                  <ActionButton type="button" onClick={toggleDelete}>
                    <i className="icon-feather-trash-2" />
                  </ActionButton>
                )}
              </ActionsContainer>
            )}
          </WithPermissions>
        )}
      </CommentHeader>
      <CommentContent>
        {isEditing ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Textarea initialValue={text} teamMembers={teamMembers} handleChange={(value) => setText(value)} />
            <div>
              <Button $small $light style={{ marginRight: '1rem' }} onClick={toggleEdit}>
                Cancel
              </Button>
              <Button $small onClick={onEditClick}>
                Save
              </Button>
            </div>
          </div>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: richCommentText(text, teamMembers) }} />
        )}
      </CommentContent>
    </CommentWrapper>
  );
};

export default memo(RenderComment);
