import { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import Gravatar from 'react-gravatar';

import Textarea from './Textarea';
import SelectStatus from './SelectStatus';
import RenderComments from './RenderComments';
import { CommentButton as Button } from './styles';
import { POINTER_RADIUS } from './config';
import { AuthContext } from '../../../contexts/AuthContext';
import { resolveComment, unresolveComment } from '../api/request';
import WithPermissions from './WithPermissions';

const WriteCommentButton = styled.button`
  cursor: pointer;
  width: 100%;
  display: block;
  height: 50px;
  border: 1px solid #bdbdbd;
  background-color: #f6f6f6;
  border-radius: 4px;
  text-align: left;
  padding: 1rem;
  color: #9b9b9b;
`;

const Content = styled.div`
  padding: 1.5rem;
  background-color: #fff;
  height: 100%;
  border-radius: 6px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

const Header = styled.header`
  display: flex;
  min-height: 75px;
  align-items: center;
  padding: 1.5rem;
  color: #747474;
  border-bottom: 1px solid #e0e0e0;

  img {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
  }
`;

const Wrapper = styled.div.attrs(({ x, y }) => ({
  style: {
    transform: `translate(${x - POINTER_RADIUS}px, ${y - POINTER_RADIUS}px)`,
  },
}))`
  width: 500px;
  background-color: #f2f2f2;
  border-radius: 6px;
  box-shadow: 0 9px 20px rgba(0, 0, 0, 0.5);
  position: relative;
  margin-top: 10px;
  z-index: 3;
  transition: all 0.5s ease;
  transition-property: margin;

  ${({ $isRightSided }) =>
    $isRightSided
      ? css`
          margin-left: -445px;
        `
      : css`
          margin-left: -45px;
        `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #f2f2f2;
    border-top: 0;
    margin-top: -7px;
    transition: all 0.5s ease;
    transition-property: left;

    ${({ $isRightSided }) =>
      $isRightSided
        ? css`
            left: 455px;
          `
        : css`
            left: 55px;
          `}
  }
`;

class CommentsComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isWritting: false,
      commentText: '',
      commenType: '',
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      this.props.pointerRef &&
      !this.wrapperRef.contains(event.target) &&
      !this.props.pointerRef.contains(event.target) &&
      !(this.state.isWritting && this.state.commentText)
    ) {
      this.props.handleHidePointerComment();
    }
  };

  onWriteCommentClick = () => {
    this.setState({ isWritting: true });
  };

  onAddComment = async () => {
    const { commentText, isWritting } = this.state;

    if (!isWritting) {
      return;
    }

    if (commentText === '') {
      this.textareaRef.focus();
      return;
    }

    const { x, y, pageId, sitemapId, commentNumber, comments } = this.props;
    const data = {
      x,
      y,
      commentNumber,
      page_id: pageId,
      body: commentText,
      sitemap_id: sitemapId,
      comment_type: this.state.commenType,
      parent_id: (comments[0] && comments[0].id) || null,
    };

    const comment = await this.props.handleAddComment(data);
    this.props.handleSetComment({ ...comment, read: true });
    this.setState({
      commentText: '',
      isWritting: false,
    });
  };

  handleCommentState = () => {
    const { user } = this.context;
    const { comments, handleSetComment, isResolved } = this.props;

    if (comments.length >= 1) {
      comments.forEach((comment) => {
        if (isResolved) {
          unresolveComment(comment.id, user).then((c) => handleSetComment(c));
        } else {
          resolveComment(comment.id, user).then((c) => handleSetComment(c));
        }
      });
    }
  };

  setTextareaRef = (textareaRef) => {
    this.textareaRef = textareaRef;
  };

  addWrapperRef = (node) => {
    this.wrapperRef = node;
    this.props.setCommentsRef(node);
  };

  onStatusChange = (newStatus) => {
    this.setState({ commenType: newStatus });
  };

  // eslint-disable-next-line class-methods-use-this
  onWrapperClick = (event) => {
    event.stopPropagation();
    document.dispatchEvent(new Event('closeCommentsList'));
  };

  render() {
    const { user: currentUser } = this.context;

    const {
      x,
      y,
      comments,
      isResolved,
      isRightSided,
      sitemapId,
      teamMembers,
      handleSetComment,
      handleRemovePointer,
      handleRemoveComment,
    } = this.props;
    const { isWritting } = this.state;
    const hasComments = comments.length > 0;
    const isNewComment = comments.length <= 0;

    return (
      <Wrapper ref={this.addWrapperRef} x={x + 2} y={y + 42} $isRightSided={isRightSided} onClick={this.onWrapperClick}>
        <Header>
          <WithPermissions comment={comments[0]}>
            {({ canChangeTypeComment, canResolveComment }) => (
              <>
                {isNewComment ? (
                  <div>
                    <Gravatar email={currentUser.email} />
                    <span>New comment</span>
                  </div>
                ) : canResolveComment ? (
                  <Button $light type="button" onClick={this.handleCommentState}>
                    {isResolved ? 'Re-open' : 'Resolve'}
                  </Button>
                ) : null}
                <SelectStatus
                  comment={comments[0]}
                  handleSetComment={handleSetComment}
                  readOnly={!canChangeTypeComment}
                  onChange={this.onStatusChange}
                />
              </>
            )}
          </WithPermissions>
        </Header>
        <Content>
          {hasComments ? (
            <RenderComments
              comments={comments}
              sitemapId={sitemapId}
              isResolved={isResolved}
              teamMembers={teamMembers}
              handleSetComment={handleSetComment}
              handleRemovePointer={handleRemovePointer}
              handleRemoveComment={handleRemoveComment}
            />
          ) : null}

          {currentUser && !isWritting && !isResolved && (
            <WriteCommentButton onClick={this.onWriteCommentClick}>
              {hasComments ? 'Add comment' : 'Write a comment or @Mention'}
            </WriteCommentButton>
          )}

          {currentUser && isWritting && !isResolved && (
            <Textarea
              teamMembers={teamMembers}
              setTextareaRef={this.setTextareaRef}
              placeholder="Write a comment or @Mention"
              handleChange={(value) => this.setState({ commentText: value })}
              onSubmit={this.onAddComment}
            />
          )}

          {currentUser && !isResolved && (
            <Button $right type="button" style={{ marginTop: '1rem' }} onClick={this.onAddComment}>
              Comment
            </Button>
          )}
        </Content>
      </Wrapper>
    );
  }
}

CommentsComponent.contextType = AuthContext;

export default CommentsComponent;
