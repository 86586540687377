import { useMemo } from 'react';
import styled from 'styled-components';

import { TagListItem } from './TagList/TagListItem';

const ListContainer = styled.div.attrs({
  className: 'thin-scrollbars',
})`
  max-height: 40vh;
  overflow-y: auto;
`;

const VerticalSeparator = styled.hr`
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-color: #5a5959;
`;

const derivateActiveStatus = (selected) => {
  const active = {};

  selected.forEach((id) => {
    active[id] = true;
  });

  return active;
};

export const EmptyTag = {
  name: 'No tag',
  color: '#a3a3a3',
};

/**
 * Display a list of tags with selection capabilities.
 */
export const TagList = ({
  tags,
  selected,
  editable,
  clearable,
  disabled,
  extraTags,
  title,
  onSelect,
  onUnselect,
  onEdit,
  onDelete,
}) => {
  const activeStatus = useMemo(() => derivateActiveStatus(selected), [selected]);

  const handleClick = (tag) => {
    const { id } = tag;

    if (!activeStatus[id]) {
      onSelect([id]);
    } else {
      onUnselect([id]);
    }
  };

  const handleClear = () => {
    onUnselect(selected);
  };

  return (
    <ListContainer>
      {extraTags}
      {title && <div className="py-2 px-3 font-weight-bold text-muted">{title}</div>}
      {clearable && (
        <>
          <TagListItem tag={EmptyTag} className="py-1 px-3" selected={selected.length === 0} onClick={handleClear} />
          <VerticalSeparator />
        </>
      )}
      {tags.map((tag) => (
        <TagListItem
          key={tag.id}
          tag={tag}
          className="py-1 px-3"
          editable={editable}
          selected={activeStatus[tag.id]}
          disabled={disabled && !activeStatus[tag.id]}
          onEdit={onEdit}
          onDelete={onDelete}
          onClick={() => handleClick(tag)}
        />
      ))}
    </ListContainer>
  );
};

export default TagList;
