/* global sitemapTree, Sitemap, $ */
import styled from 'styled-components';
import { useEffect } from 'react';

import { findNodes } from '../../sitemap/tree-renderer/hierarchy';
import { useCurrentSelection } from './hooks/useCurrentSelection';
import { dispatchEvent } from './utils';
import { InputTag } from './SitemapSelectionBar/InputTag';
import { MoveNodes } from './SitemapSelectionBar/MoveNodes';
import { SitemapProvider } from '../../contexts/SitemapContext';

const Container = styled.div.attrs({
  className: 'position-fixed d-flex flex-row align-items-center justify-content-center',
})`
  z-index: 1055;
  left: 0;
  bottom: 0;
  background: #312f2f;
  width: 100%;
  border: 1px solid #5a5959;
  font-size: 0.875rem;

  .container {
    @media (min-width: 1250px) {
      max-width: 1190px;
    }
  }

  .selection-count {
    font-size: 105%;
    font-weight: bold;
  }

  .selection-info {
    display: flex;
    align-items: center;
  }

  .fa {
    font-weight: 900;
  }

  .fa.danger,
  .svg-icon {
    font-size: 1.125rem;
  }
`;

const Separator = styled.div`
  background: #5a5959;
  width: 1px;
  height: 100%;
  margin: 0 1rem;
`;

const SitemapSelectionBar = ({ sitemapId, tagEditionEnabled }) => {
  const { currentSelection, clearSelection } = useCurrentSelection();

  const selectionSize = currentSelection.length;
  const isOpen = selectionSize > 0;

  useEffect(() => {
    $(document.body).toggleClass('selection-bar-opened', isOpen);
  }, [isOpen]);

  const deleteSelection = () => {
    const target = findNodes(sitemapTree, currentSelection);

    if (Sitemap.editionGuard.check()) {
      dispatchEvent('treeNodeDelete', { target });
    }
  };

  const moveSelection = (newParent) => {
    if (Sitemap.editionGuard.check()) {
      dispatchEvent('treeNodeParentChanged', { target: currentSelection, newParent, ancestorsExpanded: true });
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <SitemapProvider sitemapId={sitemapId}>
      <Container className="selection-bar">
        <div className="container">
          <div className="row">
            <div className="col-auto col-xl-4 selection-info">
              <span>
                <span className="badge badge-pill badge-primary selection-count">{selectionSize}</span>
                <span className="ml-2">Item{selectionSize !== 1 ? 's' : ''} selected</span>
              </span>
              <Separator />
              <button type="button" className="btn btn-link px-0" onClick={clearSelection}>
                Deselect all
              </button>
            </div>
            <div className="col-auto col-xl-5">
              <InputTag className="mr-3" currentSelection={currentSelection} editionEnabled={tagEditionEnabled} />
            </div>
            <div className="col-auto col-xl-3">
              <MoveNodes nodes={currentSelection} onMove={moveSelection} />
              <button type="button" className="btn btn-link" onClick={deleteSelection}>
                <i className="fa fa-trash danger mr-1 align-bottom" /> Delete
              </button>
            </div>
          </div>
        </div>
      </Container>
    </SitemapProvider>
  );
};

export default SitemapSelectionBar;
