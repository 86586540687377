import { useMemo } from 'react';
import styled from 'styled-components';

import { Badge } from './Badge';

const MAX_CHARS_PER_TAG = 25;

const StyledLabel = styled.div.attrs({
  className: 'd-flex',
})`
  gap: 0.5rem;
`;

/**
 * Display a list of tags.
 * By default it shows a badge with the full name.
 * If charsBreakpoint is specified then we count characters. If the result is higher than charsBreakpoint
 * then we show only the first letter of the name.
 */
export const Label = ({ tags, charsBreakpoint, className }) => {
  const short = useMemo(() => {
    // No breakpoing? Show long format
    if (!Number.isInteger(charsBreakpoint)) {
      return false;
    }
    // Breakpoint 0? Show short
    if (charsBreakpoint === 0) {
      return true;
    }

    // Each tag count as 25 characters top. We re using text-truncate.
    // Let a tag longer than charsBreakpoint be shown with a small one
    const length = tags.reduce((accum, t) => accum + Math.min(t.name.length, MAX_CHARS_PER_TAG), 0);

    return length > charsBreakpoint;
  }, [tags, charsBreakpoint]);

  return (
    <StyledLabel className={className}>
      {tags.map((tag) => (
        <Badge color={tag.color} key={tag.id} $variant={short && 'short'} title={tag.name}>
          {short ? tag.short || tag.name[0] : tag.name}
        </Badge>
      ))}
    </StyledLabel>
  );
};

export default Label;
