/* global $ */
/* global moment */
import { useState, useEffect } from 'react';
import classNames from 'classnames';

import PageButtons from './PageButtons';
import LazyImage from './LazyImage';

const filters = {
  allStatuses: () => true,
  onlyFlagged: (page) => page.status === 'FLAGGED',
  onlyResolved: (page) => page.status === 'RESOLVED',
  allSimilarities: () => true,
  similarityUnder99: (page) => page.similarityIndex < 0.99,
  similarityUnder85: (page) => page.similarityIndex < 0.85,
  similarityUnder75: (page) => page.similarityIndex < 0.75,
  similarityUnder65: (page) => page.similarityIndex < 0.65,
  similarityUnder50: (page) => page.similarityIndex < 0.5,
};

function SitemapComparisonPage({
  pageInfo,
  onExpand,
  pageSelection,
  sitemapComparisonId,
  flaggingEnabled = true,
  flagNotificationUserIds,
  openFlagSettings,
}) {
  const {
    page,
    sitemapAFinishedAt,
    sitemapACrawlVersion,
    sitemapAPage,
    sitemapBFinishedAt,
    sitemapBCrawlVersion,
    sitemapBPage,
  } = pageInfo;

  const [showPage, setShowPage] = useState(true);
  const [showDiff, setShowDiff] = useState(true);
  const [diffImageLoaded, setDiffImageLoaded] = useState(false);
  const [showToggleButton, setShowToggleButton] = useState(true);
  const toggleShowDiff = () => {
    setShowDiff(!showDiff);
  };

  useEffect(() => {
    const searchableFields = [sitemapAPage.pageTitle, sitemapBPage.pageTitle, page.url];
    const shouldShowBasedOnFilters = !pageSelection.filters || pageSelection.filters.every((f) => filters[f](page));
    const shouldShowBasedOnSearch =
      !pageSelection.searchValue ||
      searchableFields.some((field) => field.toLowerCase().includes(pageSelection.searchValue.toLowerCase()));
    setShowPage(shouldShowBasedOnFilters && shouldShowBasedOnSearch);
  }, [
    pageSelection.searchValue,
    pageSelection.filters,
    page.status,
    page,
    sitemapAPage.pageTitle,
    sitemapBPage.pageTitle,
  ]);

  if (page.similarityIndex >= 1.0) {
    return null;
  }

  return (
    <div className={classNames('comparison-result-page', { 'd-none': !showPage })}>
      <div className="row sticky-comparison-page-title">
        <div className="col-sm-6">
          <div className="page-title">{sitemapAPage.pageTitle}</div>
          <div className="url-field">{sitemapAPage.url}</div>
        </div>
        <div className="col-sm-6">
          <div className="page-title page-title--cut">{sitemapBPage.pageTitle}</div>
          <div className="url-field">{sitemapBPage.url}</div>
          <PageButtons
            className="mb-2 mb-md-0"
            onExpand={onExpand}
            page={page}
            flaggingEnabled={flaggingEnabled}
            flagNotificationUserIds={flagNotificationUserIds}
            openFlagSettings={openFlagSettings}
            sitemapComparisonId={sitemapComparisonId}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 pr-0">
          <div className="thumbnail-container">
            <LazyImage src={sitemapAPage.screenshotPath} alt="Screenshot" loaderWidth="100%" loaderHeight="600px" />
          </div>
          <div className="page-footer">
            <div>{moment(sitemapAFinishedAt).format('MM.DD.YYYY - h:mma')}</div>
            <div>{`ID: ${sitemapACrawlVersion}`}</div>
          </div>
        </div>
        <div className="col-6 pl-0">
          <div
            className={classNames('thumbnail-container', { bordered: showToggleButton && diffImageLoaded })}
            onMouseEnter={() => {
              setShowToggleButton(true);
            }}
            onMouseLeave={() => {
              // Only hide toggle buttons on desktop
              if ($(window).width() > 767) {
                setShowToggleButton(false);
              }
            }}
          >
            <button
              className={classNames('btn btn-primary', { 'd-none': !(showToggleButton && diffImageLoaded) })}
              type="button"
              onClick={toggleShowDiff}
            >
              <i className="far fa-eye" />
              {' Toggle'}
            </button>
            <LazyImage
              className={classNames({ 'd-none': !showDiff })}
              hideLoader={!showDiff}
              loaderWidth="100%"
              loaderHeight="600px"
              role="button"
              src={page.imagePath}
              onClick={toggleShowDiff}
              alt="Diff result"
              onLoad={() => setDiffImageLoaded(true)}
            />
            <LazyImage
              className={classNames({ 'd-none': showDiff })}
              hideLoader={showDiff}
              loaderWidth="100%"
              loaderHeight="600px"
              role="button"
              src={sitemapBPage.screenshotPath}
              onClick={toggleShowDiff}
              alt="Screenshot"
            />
          </div>
          <div className="page-footer text-right">
            <div>{moment(sitemapBFinishedAt).format('MM.DD.YYYY - h:mma')}</div>
            <div>{`ID: ${sitemapBCrawlVersion}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SitemapComparisonPage;
