/* global $ */
import { useEffect } from 'react';

const findElements = (domElement) => {
  const $element = $(domElement);
  const selector = '[title]';

  return $element.find(selector).add($element.filter(selector));
};

/**
 * Hook to initialize bootstrap tooltip on a DOMReference.
 *
 * The hook provides a dependsOn argument to provide an array of dependencies. When one of them changes,
 * it means that title has changed and we need to update tooltip text.
 *
 * This hook is not designed to detect new DOM elements that require tooltip.
 * It will not work with this component:
 *
 * const CompA = ({ prop1 }) => {
 *   const ref = useRef();
 *
 *   useTooltip(ref)
 *
 *   return (
 *      {prop1 && <div title="hello" ref={ref}>this should have title</div>}
 *   )
 * }
 */
const useTooltip = (containerRef, dependsOn = []) => {
  useEffect(() => {
    const $elements = findElements(containerRef.current);

    $elements.tooltip();

    return () => {
      $elements.tooltip('dispose');
    };
  }, [containerRef]);

  useEffect(() => {
    if (dependsOn.length === 0) {
      return;
    }

    const $elements = findElements(containerRef.current);

    $elements.each(function updateTooltipTitle() {
      const $this = $(this);
      if ($this.attr('title').length) {
        $this.attr('data-original-title', $this.attr('title'));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependsOn);
};

export default useTooltip;
