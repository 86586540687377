import styled from 'styled-components';

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  margin: auto;
  border: 1px solid ${({ $isChecked }) => ($isChecked ? '#00ffaf' : '#797c83')};
  border-radius: 4px;
  cursor: pointer;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: #20bbff;
    margin: 3px;
    display: ${({ $isChecked }) => ($isChecked ? 'block' : 'none')};
  }
`;

export default StyledCheckbox;
