import { post } from '../../../common/request';

export const flagPage = async (sitemapComparisonId, url) => {
  return post(`/api/v1/sitemap_comparisons/${sitemapComparisonId}/flag_page`, {
    data: { sitemap_comparison: { page_url: url } },
  });
};

export const unflagPage = async (sitemapComparisonId, url) => {
  return post(`/api/v1/sitemap_comparisons/${sitemapComparisonId}/unflag_page`, {
    data: { sitemap_comparison: { page_url: url } },
  });
};

export const resolvePage = async (sitemapComparisonId, url) => {
  return post(`/api/v1/sitemap_comparisons/${sitemapComparisonId}/resolve_page`, {
    data: { sitemap_comparison: { page_url: url } },
  });
};

export const flagAllPages = async (sitemapComparisonId) => {
  return post(`/api/v1/sitemap_comparisons/${sitemapComparisonId}/flag_all_pages`, {
    data: { sitemap_comparison: {} },
  });
};

export const resolveAllFlaggedPages = async (sitemapComparisonId) => {
  return post(`/api/v1/sitemap_comparisons/${sitemapComparisonId}/resolve_all_flagged_pages`, {
    data: { sitemap_comparison: {} },
  });
};

export const setFlagNotificationUserIds = async (sitemapComparisonId, userIds) => {
  return post(`/api/v1/sitemap_comparisons/${sitemapComparisonId}/set_flag_notification_user_ids`, {
    data: { sitemap_comparison: { user_ids: userIds } },
  });
};
