import CommentsIcon from '../../comments/CommentsIcon';
import { useToggleComments } from '../../comments/hooks/useToggleComments';

export default function ToggleComments() {
  const toggleComments = useToggleComments();

  return (
    <button
      type="button"
      className="btn btn-link p-0 text-white border-0 comments-tab-trigger m-0"
      onClick={toggleComments}
      aria-label="Toggle comment list"
      title="Toggle comment list"
    >
      <CommentsIcon />
    </button>
  );
}
