import { useRef, useEffect } from 'react';

/**
 * Returns a reference to the the last seen value of fn.
 *
 * Use this when you want to call the last seen value of a fn inside a useEffect
 */
export const useEventCallbackRef = (fn) => {
  const ref = useRef(fn);

  useEffect(() => {
    ref.current = fn;
  }, [fn]);

  return ref;
};

export default useEventCallbackRef;
