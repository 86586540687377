/* eslint-disable import/prefer-default-export */
import { useShallow } from 'zustand/react/shallow';

import { useCommentStore } from '../stores/comments';

const listStateSelector = (state) => ({
  isOpen: state.isOpen,
  setIsOpen: state.setIsOpen,
  toggleIsOpen: state.toggleIsOpen,
});

export function useListState() {
  return useCommentStore(useShallow(listStateSelector));
}
