import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook that tracks value updates and calls onChange when value is different from the latest update.
 * It's used to detect external changes (changes in another tab for example) and update the editor content
 */
export default function useTrackControlledValue(value, onChange) {
  const latestValueRef = useRef(value);
  const callbackRef = useRef(onChange);

  useEffect(() => {
    callbackRef.current = onChange;
  }, [onChange]);

  const setLastesValue = useCallback((update) => {
    latestValueRef.current = update;
  }, []);

  const currentLatestValue = latestValueRef.current;
  useEffect(() => {
    if (currentLatestValue !== value) {
      latestValueRef.current = value;
      callbackRef.current(value);
    }
  }, [currentLatestValue, value]);

  return setLastesValue;
}
