/* global $ */
import styled from 'styled-components';

import { fileUploader } from '../../../../sitemap/tree-renderer/config';
import { editScreenshot } from '../../../../sitemap/tree-renderer/actions';
import { UploadProgressBar } from '../../../content-editor/file-upload/UploadProgressBar';
import { AbortUploadButton } from '../../../content-editor/file-upload/AbortUploadButton';
import { absoluteThumbUrl, treeNodeThumbUrl } from '../../../../sitemap/utils';
import Backdrop from '../../../content-editor/Backdrop';
import UploadContainer from '../../../content-editor/file-upload/UploadContainer';

const Image = styled.img`
  width: 165px;
`;

export function PageThumbnail({ node, readonly }) {
  const { data: page } = node;

  const thumbnailUrl = absoluteThumbUrl(page.thumbnail || treeNodeThumbUrl(page.screenshot));

  const disabled = Boolean(page.upload) || readonly;
  const handleClick = (e) => {
    editScreenshot(e, node);
    $(`#${fileUploader}`).trigger('click');
  };

  return (
    <div className="position-relative backdrop-parent">
      <Image src={thumbnailUrl} alt="thumbnail" className="border border-muted rounded" />
      {!disabled && (
        <Backdrop className="rounded align-items-center justify-content-center">
          <button
            type="button"
            className="btn btn-link"
            aria-label="Click to change screenshot"
            title="Click to change screenshot"
            onClick={handleClick}
            disabled={disabled}
          >
            <i className="far fa-edit" />
          </button>
        </Backdrop>
      )}
      {page.upload && (
        <UploadContainer>
          <UploadProgressBar
            progress={page.upload.progress}
            abort={<AbortUploadButton onClick={() => page.upload.controller.abort()} />}
          />
        </UploadContainer>
      )}
    </div>
  );
}

export default PageThumbnail;
