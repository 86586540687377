/* global moment */
import Moment from 'react-moment';
import styled from 'styled-components';
import classNames from 'classnames';

const SaveStatus = styled.span.attrs({ className: 'd-inline-flex align-items-center' })`
  gap: 0.5rem;

  .fas {
    color: inherit;
  }

  &.saved {
    color: #00ffaf;
  }
`;

const UpdatedIndicatorCointaner = styled.div.attrs({ className: 'd-flex align-items-center' })`
  gap: 15px;
`;

const UpdatedIndicator = ({ saving, updatedAt }) => {
  if (!updatedAt) {
    return null;
  }

  const isToday = moment(updatedAt).isSame(new Date(), 'day');
  const saved = !saving;
  return (
    <UpdatedIndicatorCointaner>
      <SaveStatus className={classNames({ saved })}>
        {saving ? (
          'Saving...'
        ) : (
          <>
            <span>Saved</span> <i className="fas fa-check" />
          </>
        )}
      </SaveStatus>
      <span>
        Last edit was&nbsp;
        {isToday && <Moment date={updatedAt} format="[Today, on] hh:mma" titleFormat="MMM D, YYYY [at] H:mma" />}
        {!isToday && <Moment fromNow date={updatedAt} titleFormat="MMM D, YYYY [at] H:mma" />}
      </span>
    </UpdatedIndicatorCointaner>
  );
};

export default UpdatedIndicator;
