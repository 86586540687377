/* global Sitemap */
import { useState, useEffect } from 'react';
import { forceCheck } from 'react-lazyload';

import SitemapPage from './SitemapPagesSearchResult/SitemapPage';
import { useSitemapTree } from '../../hooks/useSitemapTree';
import { useCurrentSelection } from '../sitemap-view/hooks/useCurrentSelection';
import { DEFAULT_FILTER, filterMatcher, isNotEmptyFilter, nameMatcher } from '../../sitemap/tree-filter';
import { useSitemapPageSearch } from '../../stores/sitemap-page-search';

const pageMatchingNameAndFilter = (term, filter) => {
  const matchName = nameMatcher(term);
  const matchFilter = filterMatcher(filter);

  return (page) => matchName(page) && matchFilter(page);
};

function SitemapPagesSearchResult() {
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState(DEFAULT_FILTER);
  const [searchResultPages, setSearchResultPages] = useState([]);
  const { currentSelection, toggleSelectionMultiple } = useCurrentSelection();
  const tree = useSitemapTree('treeRendered');
  const { isOpen: isVisible } = useSitemapPageSearch();

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const matches = Sitemap.tree.filter(tree, pageMatchingNameAndFilter(searchValue, filterValue));

    setSearchResultPages(matches);

    // force lazyload to recheck visibility, on some large sitemaps lazyload fails
    // to detect visibility on its own
    setTimeout(forceCheck, 500);
  }, [tree, searchValue, filterValue, isVisible]);

  useEffect(() => {
    const onSearchValueUpdated = ({ detail }) => {
      setSearchValue(detail.searchValue);
    };

    const onFilterUpdated = ({ detail }) => {
      setFilterValue(detail);
    };

    document.addEventListener('sitemapPagesSearchValueUpdated', onSearchValueUpdated);
    document.addEventListener('treeNodeFilterChange', onFilterUpdated);

    return () => {
      document.removeEventListener('sitemapPagesSearchValueUpdated', onSearchValueUpdated);
      document.removeEventListener('treeNodeFilterChange', onFilterUpdated);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  const onSelectResults = (e) => {
    e.preventDefault();
    toggleSelectionMultiple(
      searchResultPages.map((node) => node.data.id),
      true
    );
  };

  const onClearSelectionResults = (e) => {
    e.preventDefault();
    toggleSelectionMultiple(
      searchResultPages.map((node) => node.data.id),
      false
    );
  };

  const matchedPages = searchResultPages.map((node) => {
    return <SitemapPage key={node.data.id} node={node} />;
  });

  const allSelected = searchResultPages.every((node) => {
    return currentSelection.includes(node.data.id);
  });

  const someSelected = searchResultPages.some((node) => {
    return currentSelection.includes(node.data.id);
  });

  const filterApplied = searchValue.trim().length > 0 || isNotEmptyFilter(filterValue);

  return (
    <div className="sitemap-pages-search-result-container">
      <div className="sitemap-pages-search-result">
        <div className="search-result-header">
          <span className="result-count">{`${searchResultPages.length} `}</span>
          <span>Results:</span>
          {filterApplied && searchResultPages.length > 0 && (
            <button
              type="button"
              className="select-all btn btn-link px-0"
              onClick={onSelectResults}
              disabled={allSelected}
            >
              Select All
            </button>
          )}
          {filterApplied && someSelected && (
            <button type="button" className="btn btn-link px-0" onClick={onClearSelectionResults}>
              Clear Selection
            </button>
          )}
        </div>
        <div className="search-result-body thin-scrollbars">{matchedPages}</div>
      </div>
    </div>
  );
}

export default SitemapPagesSearchResult;
