/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import LazyLoad from 'react-lazyload';

import Loader from './Loader';

function LazyImage({ src, alt, onLoad, className, role, onClick, hideLoader = false, loaderWidth, loaderHeight }) {
  const [ready, setReady] = useState(false);

  const onImageLoad = () => {
    setReady(true);
    if (onLoad) {
      onLoad();
    }
  };
  return (
    <LazyLoad once>
      <img
        className={className}
        style={ready ? {} : { display: 'none' }}
        src={src}
        alt={alt}
        role={role}
        onClick={onClick}
        onLoad={onImageLoad}
      />
      <Loader show={!hideLoader && !ready} width={loaderWidth} height={loaderHeight} />
    </LazyLoad>
  );
}

export default LazyImage;
