import ContentForm from '../content-editor/ContentForm';
import { stringToKeywords } from '../content-editor/utils';
import { addEditorFiles } from './api';
import { useSitemapContent, useUpdateSitemapContent } from './hooks';

export default function SitemapContentForm({ sitemapId }) {
  const save = useUpdateSitemapContent();

  const { data, isLoading } = useSitemapContent();

  const handleSave = async (formData) => {
    const info = { ...formData, keywords: stringToKeywords(formData.keywords) };

    // Delete unpermitted params
    delete info.id;
    delete info.keywords;
    delete info.files;
    delete info.gdocs;
    delete info.updated_at;

    await save.mutateAsync(info);
  };

  const handleFileUpload = (newFileSignedId) => {
    // Tinymce upload images one at a time...not multiple
    return addEditorFiles(sitemapId, [newFileSignedId]).then((res) => res[0]);
  };

  return <ContentForm data={data} isInitialLoading={isLoading} onSave={handleSave} onFileUpload={handleFileUpload} />;
}
