import config from '../../config';

const { USER_FLOWS_URL } = config;

export const isShareContext = (context) => context === 'share';
export const isEmbedContext = (context) => context === 'embed';
export const isAnonymousContext = (context) => context === 'anonymous';

export const isIncommingEvent = (event) => {
  const { origin, data } = event;

  if (origin !== USER_FLOWS_URL) {
    return false;
  }

  const isIncommingAction = (msg) => msg?.action?.startsWith('fragmentEvent:');

  return isIncommingAction(data?.message);
};

export const parseIncommingAction = ({ action }) => action.split(':')[1];
