/* global toastr, google, dataConfirmModal */

import { useCallback, useEffect, useState } from 'react';
import useDrivePicker from '@yellowspot/react-google-drive-picker';

import config from '../../../../config';
import { useAuth } from '../../../../contexts/AuthContext';

const { GOOGLE_API_KEY, GOOGLE_CLIENT_ID, GOOGLE_APP_ID } = config;

const TOKEN_STORAGE_KEY = 'gdocPickerToken';
const TOKEN_EXPIRE_AT_STORAGE_KEY = 'gdocPickerTokenExpireAt';
const TOKEN_EXPIRE_GRACE_TIME = 300; // five minutes grace time to avoid using a token near to expiration

function saveToken(user, { access_token, expires_in }) {
  // eslint-disable-next-line camelcase
  sessionStorage.setItem(`${TOKEN_EXPIRE_AT_STORAGE_KEY}_${user.id}`, new Date(Date.now() + expires_in * 1000));
  sessionStorage.setItem(`${TOKEN_STORAGE_KEY}_${user.id}`, access_token);
}

function loadToken(user) {
  const expireAtWithGrace = new Date(
    new Date(sessionStorage.getItem(`${TOKEN_EXPIRE_AT_STORAGE_KEY}_${user.id}`)) - TOKEN_EXPIRE_GRACE_TIME * 1000
  );
  if (new Date() >= expireAtWithGrace) {
    return null;
  }
  return sessionStorage.getItem(`${TOKEN_STORAGE_KEY}_${user.id}`);
}

export default function GdocPicker({ disabled, onChange, name, gdocs }) {
  const [newGdocs, setNewGdocs] = useState([]);
  const [openPicker, authResponse] = useDrivePicker();
  const { user } = useAuth();

  useEffect(() => {
    if (authResponse) {
      saveToken(user, authResponse);
    }
  }, [user, authResponse]);

  const onPickCallback = useCallback(
    (data) => {
      if (data.action === 'picked') {
        const notIncludedDocs = data.docs.filter((doc) => gdocs.every((d) => d.id !== doc.id));
        const alreadyAddedSelected = data.docs.length !== notIncludedDocs.length;

        if (notIncludedDocs.length > 0) {
          setNewGdocs((current) => [...current, ...notIncludedDocs]);
        }
        if (alreadyAddedSelected) {
          toastr.error('Document already exists');
        }
      }
    },
    [gdocs, setNewGdocs]
  );

  const explainPermissions = (onConfirm) => {
    dataConfirmModal.confirm({
      title: 'Important Notice',
      text: 'Please ensure that the Google doc’s shared settings = <span style="color: #20BBFF">Anyone with the link > Role = Editor</span>',
      cancelClass: 'd-none',
      commit: "Understood, let's continue!",
      commitClass: 'btn btn-primary',
      focus: 'commit',
      onConfirm,
      onCancel: () => {},
    });
  };

  const handleOpenPicker = () => {
    explainPermissions(() => {
      const viewIds = ['DOCUMENTS', 'SPREADSHEETS', 'PRESENTATIONS'];

      openPicker({
        clientId: GOOGLE_CLIENT_ID,
        developerKey: GOOGLE_API_KEY,
        appId: GOOGLE_APP_ID,
        token: loadToken(user),
        disableDefaultView: true,
        customScopes: ['https://www.googleapis.com/auth/drive.file'],
        customViews: viewIds.map((viewId) => google.picker.ViewId[viewId]),
        multiselect: true,
        callbackFunction: onPickCallback,
      });
    });
  };

  useEffect(() => {
    onChange({ target: { name, value: newGdocs } });
  }, [newGdocs, onChange, name]);

  if (disabled) {
    return null;
  }

  return (
    <div className="align-self-center">
      <button className="btn btn-outline-primary border-primary" type="button" onClick={handleOpenPicker}>
        + Add
      </button>
    </div>
  );
}
