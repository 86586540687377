/* eslint-disable import/prefer-default-export */

const toggleComments = (event) => {
  event.preventDefault();
  if (window.commentsWarning) {
    window.commentsWarning();
    return;
  }
  document.dispatchEvent(new Event('toggleCommentsList'));
};

export const useToggleComments = () => toggleComments;
