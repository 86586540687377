/* global Sitemap */
import { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import { Box } from '../../shared/Box';
import { sortNodeByTypeAndName } from '../../../sitemap/utils';
import { useNodes } from '../hooks/useNodes';
import { useTreeDescendants } from '../hooks/useTreeDescendants';
import { NodeSelect } from './MoveNodes/NodeSelect';
import { MoveIcon } from './MoveNodes/MoveIcon';

const NodeList = ({ excluding, onSelect }) => {
  const nodes = useNodes();
  const excludingDescendants = useTreeDescendants(excluding);

  const sorted = useMemo(
    () => nodes.filter(Sitemap.tree.belongsToCurrentOrganization).sort(sortNodeByTypeAndName),
    [nodes]
  );

  const isDisabled = (node) => excludingDescendants.indexOf(node.id) >= 0;

  const handleChange = (option, { action }) => {
    if (action === 'select-option') {
      onSelect(option.id);
    }
  };

  return (
    <Box className="px-2 pt-2 pb-3">
      <NodeSelect
        nodes={sorted}
        autoFocus
        placeholder="Search…"
        emptyMessage="No values"
        disabledMessage="Sorry, you can't move a parent item into it's own child item."
        isDisabled={isDisabled}
        onChange={handleChange}
      />
    </Box>
  );
};

const popperOptions = {
  placement: 'bottom-end',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [0, 5] },
    },
  ],
};

const FloatingContainer = styled.div`
  z-index: 1060;
`;

const Backdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: default;
  z-index: 1060;
`;

export const MoveNodes = ({ nodes, onMove }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, popperOptions);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleSelection = (id) => {
    close();
    onMove(id);
  };

  return (
    <>
      <button type="button" className="btn btn-link" onClick={open} ref={setReferenceElement}>
        <MoveIcon className="svg-icon mr-1 align-bottom" /> Move
      </button>
      {isOpen &&
        createPortal(
          <>
            <Backdrop onClick={close} />
            <FloatingContainer
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              onClick={(e) => e.stopPropagation()}
            >
              <NodeList excluding={nodes} onSelect={handleSelection} />
            </FloatingContainer>
          </>,
          document.body
        )}
    </>
  );
};

export default MoveNodes;
