import Color from 'color';

import { createColor } from '../../../sitemap/tagging/colors';

const BLACK = Color('black');
const WHITE = Color('white');

export const shadeColor = (background, weight) => createColor(background).mix(BLACK, weight).hex();
export const tintColor = (background, weight) => createColor(background).mix(WHITE, weight).hex();

export const hoverBackground = (background, shadeWeight, tintWeight) => {
  const bg = createColor(background);

  if (bg.isDark()) {
    return tintColor(bg, tintWeight);
  }

  return shadeColor(bg, shadeWeight);
};

export { colorContrast, createColor } from '../../../sitemap/tagging/colors';
