import { useLayoutEffect, useState } from 'react';

export default function AuthenticityToken() {
  const [token, setToken] = useState('');

  useLayoutEffect(() => {
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content');

    setToken(csrfToken);
  }, []);

  return <input name="authenticity_token" type="hidden" value={token} />;
}
