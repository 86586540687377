import { useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const GDoc = styled.div`
  display: flex;
  gap: 0.25rem;
  background-color: #fff;
  padding: 12px;
  border-bottom: 1px solid #dbdbdb;

  &.selected {
    border: 1px solid #007bff;
  }
`;

const IconContainer = styled.div`
  border: 1px solid #dbdbdb;
  padding: 6px 25px;
  margin: auto;

  cursor: pointer;
`;

const Info = styled.div.attrs({ className: 'd-flex flex-column py-1' })`
  flex: 1;
  min-width: 0;
  font-size: 0.875rem;
  gap: 0.5rem;

  .btn {
    text-align: start;
    font-size: inherit;
  }
`;

const filterUniqDocs = (docs) => docs.filter((doc, idx) => docs.findIndex((a) => a.id === doc.id) === idx);

function GdocList({ name, value, onChange, gdocs, newGdocs, disabled, selectedId, onSelect }) {
  const filtered = useMemo(() => {
    return filterUniqDocs([...gdocs, ...newGdocs]).filter((f) => !value.includes(f.id));
  }, [gdocs, value, newGdocs]);

  const handleRemove = (fileId) => {
    if (selectedId === fileId) {
      onSelect(null);
    }
    onChange({ target: { name, value: [...value, fileId] } });
  };

  if (disabled && filtered.length === 0) {
    return 'No documents';
  }

  return filtered.map(({ id, name: filename, iconUrl }) => (
    <GDoc key={id} className={classNames({ selected: selectedId === id })}>
      <DriveIcon iconUrl={iconUrl} size="44" onClick={() => onSelect(id)} />
      <Info>
        <button
          type="button"
          className="btn btn-link text-decoration-none text-dark p-0 text-truncate"
          onClick={() => onSelect(id)}
          title={filename}
        >
          {filename}
        </button>
        {!disabled && (
          <button
            type="button"
            className="btn btn-link text-danger text-decoration-none p-0 align-self-start"
            title="Click to remove the file"
            onClick={() => handleRemove(id)}
          >
            <i className="fa fa-trash" /> Remove
          </button>
        )}
      </Info>
    </GDoc>
  ));
}

function DriveIcon({ iconUrl, size, onClick }) {
  const src = iconUrl.replace(/\/(\d+)\//, `/64/`);

  return (
    <IconContainer onClick={onClick}>
      <img src={src} alt="File type icon" height={size} />
    </IconContainer>
  );
}

export default GdocList;
