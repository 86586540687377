function similarityLevel(similarityIndex) {
  if (similarityIndex > 0.99) {
    return 'ultra-high';
  }
  if (similarityIndex > 0.9) {
    return 'high';
  }
  if (similarityIndex > 0.7) {
    return 'medium';
  }
  return 'low';
}

export default similarityLevel;
