import styled from 'styled-components';

const Container = styled.div.attrs({ className: 'shadow' })`
  position: relative;
  min-height: 100px;
  aspect-ratio: 1.07;
  display: flex; // Make sure image object-fit works

  .preview {
    border-radius: inherit;
    font-size: 1.5rem;
  }

  img.preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
  }
`;

export default function Thumbnail({ src, className, alt, children }) {
  return (
    <Container className={className}>
      {src ? (
        <img src={src} className="preview border border-muted" alt={alt} />
      ) : (
        <div className="preview fallback border border-muted d-flex w-100">
          <i className="fa fa-paperclip text-muted m-auto" />
        </div>
      )}
      {children}
    </Container>
  );
}
