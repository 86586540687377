/* global tagsState */
import { useState, useEffect, useCallback } from 'react';

/**
 * Hook to return existing tag collection. Is something similar to `useContext(TagContext)`.
 * Tags are shared between different apps. We don't have a context, we have a global variable for that
 */
export const useTags = () => {
  const [tags, setTags] = useState(tagsState.tags);

  useEffect(() => {
    const subscription = tagsState.subscribe((newValue) => {
      setTags(newValue);
    });

    // Fetch tags if state has not been initialized
    if (!tagsState.initalized) {
      tagsState.init();
    }

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const addTag = useCallback((tag) => tagsState.add(tag), []);
  const updateTag = useCallback((tag) => tagsState.update(tag), []);
  const removeTag = useCallback((tag) => tagsState.remove(tag), []);

  return [tags, { addTag, updateTag, removeTag }];
};

export default useTags;
