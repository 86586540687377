import { createRef, PureComponent } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div.attrs(({ x, y }) => ({
  style: {
    left: `${x}px`,
    top: `${y}px`,
  },
}))`
  position: fixed;
  z-index: 3;

  ${({ $isVisible }) =>
    $isVisible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

export default class Tooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.containerRef = createRef();
    this.state = {
      xPosition: 0,
      yPosition: 0,
      mouseMoved: false,
      listenerActive: false,
      isReversed: false,
    };
  }

  componentDidMount() {
    this.addListener();
  }

  componentDidUpdate() {
    this.updateListener();
  }

  componentWillUnmount() {
    this.removeListener();
  }

  getTooltipPosition = ({ clientX, clientY }) => {
    const { targetRef } = this.props;

    this.setState({
      xPosition: clientX,
      yPosition: clientY,
      mouseMoved: true,
      isReversed: clientX > targetRef.offsetWidth - this.containerRef.current.offsetWidth,
    });
  };

  addListener = () => {
    window.addEventListener('mousemove', this.getTooltipPosition);
    this.setState({ listenerActive: true });
  };

  removeListener = () => {
    window.removeEventListener('mousemove', this.getTooltipPosition);
    this.setState({ listenerActive: false });
  };

  updateListener = () => {
    if (!this.state.listenerActive && this.props.visible) {
      this.addListener();
    }

    if (this.state.listenerActive && !this.props.visible) {
      this.removeListener();
    }
  };

  render() {
    const { children, className, visible, offsetX, offsetY } = this.props;
    const { xPosition, yPosition, mouseMoved, isReversed } = this.state;

    return (
      <Container
        className={className}
        ref={this.containerRef}
        y={yPosition + offsetY}
        $isVisible={visible && mouseMoved}
        x={isReversed ? xPosition - 175 : xPosition + offsetX}
      >
        {children}
      </Container>
    );
  }
}
