import styled from 'styled-components';

export const Select = styled.div`
  margin-left: auto;
  font-weight: 600;
  position: relative;
  width: 160px;
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #303030;
  color: #dadada;
  border-radius: 3px;
  display: flex;
  align-items: center;
`;

export const Caret = styled.span`
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top: 4px solid #aeaeae;
  margin-left: auto;
  margin-right: 1rem;
`;

export const Option = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
`;

export const OptionsList = styled.ul`
  background: #303030;
  color: #dadada;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 160px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  overflow: hidden;

  li {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }
`;

export const Button = styled.button`
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: left;
  transition: all 0.4s ease;
  font-size: 12px;
  font-weight: 400;
  color: #dadada;
  padding: 0;
  text-transform: uppercase;

  &:hover {
    background-color: #595959;
  }
`;

export const Bullet = styled.span`
  width: 9px;
  height: 9px;
  background-color: ${({ color }) => color};
  display: inline-block;
  border-radius: 9px;
  margin-right: 1rem;
  margin-left: 1rem;
`;
