/* global Sitemap, $ */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useSitemapTree } from '../../hooks/useSitemapTree';
import { Modal } from '../content-editor/Modal';
import { SitemapProvider } from '../../contexts/SitemapContext';
import { AuthProvider } from '../../contexts/AuthContext';
import NodeTitle from './PageContentEditor/NodeTitle';
import { FeaturesProvider } from '../../contexts/FeaturesContext';
import { PresenceProvider } from './PresenceContext';
import { pageContentKey } from './hooks';
import PageContentForm from './PageContentForm';
import NodeFooter from './PageContentEditor/NodeFooter';

const queryClient = new QueryClient();

function useSitemapNode(pageId) {
  // Listen 'treeRendered' to make sure node.data.tags returns information
  const tree = useSitemapTree('treeRendered');

  return useMemo(() => pageId && Sitemap.tree.find(tree, (n) => n.data.pageId === pageId), [tree, pageId]);
}

export default function PageContentEditor({
  sitemapId,
  pageEditionEnabled,
  tagEditionEnabled,
  contentEditionEnabled,
  commentsEnabled,
}) {
  const [{ pageId, offcanvas }, setState] = useState({});

  const node = useSitemapNode(pageId);

  useEffect(() => {
    const editContentRequest = (e) => {
      if (window.pageContentAccessWarning) {
        window.pageContentAccessWarning();
        return;
      }

      setState({ pageId: e.detail.pageId, offcanvas: e.detail.offcanvas || false });
    };

    const pageContentsChanges = (e) => {
      queryClient.invalidateQueries({ queryKey: pageContentKey(sitemapId, e.detail.page_id), exact: true });
    };

    document.addEventListener('page/EditContent', editContentRequest);
    document.addEventListener('page/ContentsChanges', pageContentsChanges);

    return () => {
      document.removeEventListener('page/EditContent', editContentRequest);
      document.removeEventListener('page/ContentsChanges', pageContentsChanges);
    };
  }, [sitemapId]);

  const close = useCallback(() => {
    // Keep offcanvas value on close. Do not change modal width during fade-out animation.
    setState((v) => ({
      ...v,
      pageId: undefined,
    }));
  }, []);

  useEffect(() => {
    // We can't keep editor open when user clicks on a comment from comments list.
    // The editor goes over the page screenshot modal. The change needed to stack modals is far from trivial.
    // This is a compromise solution.
    document.addEventListener('openComment', close);

    return () => {
      document.removeEventListener('openComment', close);
    };
  }, [close]);

  useEffect(() => {
    document.addEventListener('page/EditContent:close', close);

    return () => {
      document.removeEventListener('page/EditContent:close', close);
    };
  }, [close]);

  // Close the modal when node is removed from the tree
  useEffect(() => {
    if (!node) {
      close();
    }
  }, [node, close]);

  const isOpen = Boolean(node);

  useEffect(() => {
    $(document.body).toggleClass('page-content-opened', isOpen);
  }, [isOpen]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SitemapProvider sitemapId={sitemapId}>
          <FeaturesProvider
            features={{ tagEditionEnabled, contentEditionEnabled, pageEditionEnabled, commentsEnabled }}
          >
            <PresenceProvider sitemapId={pageId ? sitemapId : null} pageId={pageId}>
              {/* FIXME: we should try to use bootstrap modals. I'm not using react-strap or any other library because we have an old version of bootstrap (4.0.0) */}
              <Modal
                open={isOpen}
                offcanvas={offcanvas}
                title={<NodeTitle node={node} disabled={!pageEditionEnabled} updateIndicator={!offcanvas} />}
                footer={offcanvas && <NodeFooter node={node} />}
                onClose={close}
              >
                {isOpen && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
                {isOpen && <PageContentForm node={node} sitemapId={sitemapId} small={offcanvas} />}
              </Modal>
            </PresenceProvider>
          </FeaturesProvider>
        </SitemapProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
