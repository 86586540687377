import { ErrorMessage, Field } from 'formik';

export default function Input({ name, title, className, ...props }) {
  return (
    <div className="form-group col">
      {title && <label>{title}</label>}
      <Field name={name} className="form-control" {...props} />
      <ErrorMessage name={name} component="div" className="help-block form-text with-errors form-control-feedback" />
    </div>
  );
}
