import styled from 'styled-components';

const Backdrop = styled.div.attrs({ className: 'border border-primary' })`
  display: none;
  background: #303030;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .btn {
    font-size: 1.2rem;
  }

  .backdrop-parent:hover & {
    display: flex;
  }
`;

export default Backdrop;
