/* global toastr */
import { useState } from 'react';

import UserSelector from '../../UserSelector';
import { setFlagNotificationUserIds as apiCall } from './request';

function FlagSettingsModal({
  sitemapComparisonId,
  onClose,
  users,
  inviteUserUrl,
  flagNotificationUserIds: initialFlagNotificationUserIds,
  showIntroText = false,
  onUserIdsSelection = () => {},
}) {
  const [flagNotificationUserIds, setFlagNotificationUserIds] = useState(initialFlagNotificationUserIds || []);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const onSave = () => {
    setButtonsEnabled(false);
    apiCall(sitemapComparisonId, flagNotificationUserIds)
      .then(() => {
        onUserIdsSelection(flagNotificationUserIds);
      })
      .catch(() => {
        toastr.error('Sorry, something went wrong. Please try again later');
        setButtonsEnabled(true);
      });
  };

  const onNoThanks = () => {
    setButtonsEnabled(false);
    setFlagNotificationUserIds([]);
    apiCall(sitemapComparisonId, [])
      .then(() => {
        onUserIdsSelection([]);
      })
      .catch(() => {
        toastr.error('Sorry, something went wrong. Please try again later');
        setButtonsEnabled(true);
      });
  };

  return (
    <div className="modal-container">
      <div className="flag-settings-modal">
        {showIntroText && (
          <div className="row">
            <div className="col-sm-12">
              <p className="intro-text">
                👋 &nbsp;&nbsp;Before you start flagging away, you may want to consider emailing the flag notifications
                to anyone one on the list below:
              </p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 mb-4">
            <h5>Send Email Flag Notifications to:</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-4">
            <UserSelector
              users={users}
              onUserIdsSelection={setFlagNotificationUserIds}
              initiallySelectedUserIds={flagNotificationUserIds}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-5">
            <span>Not listed above? </span>
            <span>
              <a href={inviteUserUrl} target="_blank" rel="noopener noreferrer">
                Invite them first by email...
              </a>
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 text-right">
            <button className="btn btn-wide btn-link" type="button" onClick={onClose} disabled={!buttonsEnabled}>
              Cancel
            </button>
            <button
              className="btn btn-wide btn-primary alto ml-0"
              type="button"
              onClick={onNoThanks}
              disabled={!buttonsEnabled}
            >
              No Thanks
            </button>
            <button className="btn btn-wide btn-primary" type="button" onClick={onSave} disabled={!buttonsEnabled}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlagSettingsModal;
