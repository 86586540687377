import pick from 'lodash.pick';
import { debounce } from 'throttle-debounce';

import {
  fetchUserFlowContext,
  fetchUserFlowTemplate,
  fetchUserFlowTemplates,
  newThumbnail,
  newUpload,
  updateOptions,
  updateSharing,
  updateThumbnail,
  updateUserFlow,
} from './api';
import { isEmbedContext, isShareContext, isAnonymousContext } from './utils';
import { getVisibilityTracker } from './visibility-tracker';

const processUpdate = async (promise, onSuccess, onError) => {
  let r;
  try {
    r = await promise;
  } catch (e) {
    onError(e);
    throw e;
  }
  onSuccess(r);
};

const debouncedUpdateState = debounce(1000, (id, state, onSuccess = () => {}, onError = () => {}) => {
  return processUpdate(updateUserFlow(id, { state }), onSuccess, onError);
});

const debouncedUpdateZoom = debounce(1000, (id, zoom, onSuccess = () => {}, onError = () => {}) => {
  return processUpdate(updateUserFlow(id, { zoom }), onSuccess, onError);
});

const debouncedUpdatePosition = debounce(1000, (id, position, onSuccess = () => {}, onError = () => {}) => {
  return processUpdate(updateUserFlow(id, { position }), onSuccess, onError);
});

const sharedPosition = () => {
  const params = new URLSearchParams(window.location.search);
  if (!params.has('s')) {
    return { zoom: null, position: null };
  }

  try {
    const state = JSON.parse(window.atob(params.get('s')));

    return pick(state, ['zoom', 'position']);
  } catch (e) {
    return {};
  }
};

export const dataPayload = (flow, context) => {
  const { state, options } = flow;
  const shareContext = isShareContext(context);

  const permissions = { share: true, ...flow.permissions };

  if (isEmbedContext(context)) {
    Object.assign(permissions, { share: false, access: 'read' });
  }

  const payload = { data: state, permissions, options };

  if (shareContext) {
    Object.assign(payload, sharedPosition());
  } else {
    Object.assign(payload, { zoom: null, position: null });
  }

  return payload;
};

const flowContainerVisible = async ({ iframe, context, state }) => {
  // Memoize the instance. Keep state active for future calls to this function.
  if (!state.iframeVisibilityTracker) {
    // eslint-disable-next-line no-param-reassign
    state.iframeVisibilityTracker = getVisibilityTracker({ target: iframe, context });
  }

  try {
    await state.iframeVisibilityTracker.track();
  } catch (_e) {
    // Problems waiting for the iframe to be ready. Just ignore it.
  }

  return true;
};

/**
 * Handler signature: (request: Request, response: Response) => void
 *
 * - request.props: { id: string, context: string }. Readonly.
 * - request.state: object. Shared between message calls. Handler can mutate it.
 *                  Initialized with hasThumb, lastThumbResponse, flow.
 * - request.canWrite: boolean. Does user have write access to the flow?
 * - request.message: object. Message from the iframe.
 *
 * response.sendSuccess: Send back to the iframe a simple payload "{ success: <boolean> }"
 * response.sendResponse: Send back to the iframe the given payload.
 */
const handlers = {
  async getData({ props, state, iframe }, { sendResponse }) {
    const payload = dataPayload(state.flow, props.context);

    // Copy back permissions. Disable access in embed context
    // eslint-disable-next-line no-param-reassign
    state.flow.permissions = payload.permissions;

    // On embed context we must wait until the iframe is visible to send the data.
    // Without this FlowApp does not fit-content correctly during the initial render.
    await flowContainerVisible({ state, iframe, context: props.context });

    sendResponse(payload);
  },
  saveData({ props, message, canWrite }, { sendSuccess }) {
    canWrite && debouncedUpdateState(props.id, message.content, sendSuccess(true), sendSuccess(false));
  },
  saveZoom({ props, message, canWrite }, { sendSuccess }) {
    canWrite &&
      !isShareContext(props.context) &&
      debouncedUpdateZoom(props.id, message.content, sendSuccess(true), sendSuccess(false));
  },
  savePosition({ props, message, canWrite }, { sendSuccess }) {
    canWrite &&
      !isShareContext(props.context) &&
      debouncedUpdatePosition(props.id, message.content, sendSuccess(true), sendSuccess(false));
  },
  newUpload({ props, canWrite }, { sendResponse }) {
    canWrite && newUpload(props.id).then(sendResponse);
  },
  newThumbnail({ props, state, canWrite }, { sendResponse }) {
    if (!canWrite) {
      return;
    }

    newThumbnail(props.id, state.lastThumbResponse).then((r) => {
      // eslint-disable-next-line no-param-reassign
      state.lastThumbResponse = r;
      sendResponse(r);
    });
  },
  thumbnailSaved({ props, state }) {
    // Since we override the thubmnail we just want to make an API call only if thumb is not defined.
    // We are not going to change the URL anyway
    if (!state.hasThumb) {
      updateThumbnail(props.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        state.hasThumb = true;
      });
    }
  },
  getTemplate({ message }, { sendResponse }) {
    fetchUserFlowTemplate(message.content).then((template) => sendResponse({ template }));
  },
  getTemplates(_request, { sendResponse }) {
    fetchUserFlowTemplates().then((templates) => sendResponse({ templates }));
  },
  getShareData({ state, canWrite }, { sendResponse }) {
    let response = state.flow.sharing || {};
    // Override sharing url. Add any extra parameter flow-app decide to append to the URL.
    // This is useful for example re-share the board position (flow-app adds that parameter to the URL).
    if (!canWrite && response) {
      response = { ...response, url: window.location.href };
    }

    sendResponse(response);
  },
  saveSharedData({ props, state, message, canWrite }, { sendResponse }) {
    if (!canWrite) {
      return;
    }

    updateSharing(props.id, message.content).then(() => {
      Object.assign(state.flow.sharing, message.content);
      sendResponse(state.flow.sharing);
    });
  },
  getContext({ props }, { sendResponse }) {
    fetchUserFlowContext(props.id).then((r) => sendResponse(r.context));
  },
  saveOptions({ props, state, message, canWrite }, { sendResponse }) {
    if (!canWrite) {
      return;
    }

    updateOptions(props.id, message.content).then(() => {
      const options = state.flow.options || {};
      // eslint-disable-next-line no-param-reassign
      state.flow.options = { ...options, ...message.content };
      sendResponse(state.flow.options);
    });
  },
  signUp({ props }) {
    if (isAnonymousContext(props.context)) {
      document.querySelector('.btn-save-standalone-flow')?.click();
    }
  },
  ready() {
    // It's a noop. It's just to avoid the "no handler" error.
  },
};

export default handlers;
