import { createRef, Component } from 'react';
import styled, { css } from 'styled-components';

import Comments from '../Comment/Comments';
import { moveComment, createComment } from '../api/request';
import {
  absolutePosition,
  relativePositionFromEvent,
  relativePosition,
  relativePositionFromRect,
} from '../Comment/helpers';
import { POINTER_RADIUS } from '../Comment/config';
import { AuthContext } from '../../../contexts/AuthContext';
import { showAlert } from '../../../common/alert';
import { commentsTheme } from '../../shared/theme';

const Container = styled.div.attrs(({ x, y }) => ({
  style: {
    transform: `translate(${x - POINTER_RADIUS}px, ${y - POINTER_RADIUS}px)`,
  },
}))`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'move')};
  position: absolute;
  width: ${POINTER_RADIUS * 2}px;
  height: ${POINTER_RADIUS * 2}px;
  line-height: 36px;
  text-align: center;
  border-radius: ${POINTER_RADIUS * 2}px;
  z-index: 2;
  font-weight: bold;
  background-color: ${({ $isResolved }) => ($isResolved ? '#00FFAF' : '#553daf')};
  border: 3px solid #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  user-select: none;
  color: ${({ $isResolved }) => ($isResolved ? '#2A2A2A' : '#FFF')};
  transition: all 0.5s ease;
  transition-property: background-color, color;
  font-family: ${commentsTheme.fontFamily};

  ${({ $isResolved }) =>
    $isResolved &&
    css`
      font-size: 22px;
    `};

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    `};

  ${({ $isActive }) =>
    $isActive &&
    css`
      z-index: 3;
    `};
`;

let cancelClick;
let isValidClick = true;
const holdClick = {
  delay: 250,
  reset: () => {
    isValidClick = false;
  },
};

class Pointer extends Component {
  constructor(props) {
    super(props);

    const { x, y, isNew, comments, targetRef } = this.props;
    this.pointerRef = createRef();

    const position = absolutePosition({ x, y }, targetRef);

    this.state = {
      comments,
      rect: targetRef.getBoundingClientRect(),
      isDragging: false,
      isRightSided: position.x - 500 + POINTER_RADIUS * 2 > 500,
      showComments: isNew,
      posX: position.x,
      posY: position.y,
    };
  }

  componentDidMount() {
    const { commentNumber } = this.props;

    if (commentNumber) {
      this.handleLookingForComment();
    }

    document.addEventListener('openComment', this.handleLookingForComment);
    window.addEventListener('resize', () => setTimeout(this.onResize, 0));
  }

  componentDidUpdate(prevProps) {
    const { commentNumber, x, y, comments } = this.props;

    if (prevProps.commentNumber !== commentNumber) {
      this.handleLookingForComment();
    }

    if (x !== prevProps.x || y !== prevProps.y || comments !== prevProps.comments) {
      this.onUpdate((state) => this.setState(state));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('openComment', this.handleLookingForComment);
    window.removeEventListener('resize', this.onResize);

    clearTimeout(cancelClick);
  }

  onUpdate = (callback) => {
    const { x, y, targetRef, comments } = this.props;

    const position = absolutePosition({ x, y }, targetRef);

    callback({
      comments,
      posX: position.x,
      posY: position.y,
      isDragging: false,
      isRightSided: position.x - 500 + POINTER_RADIUS * 2 > 500,
    });
  };

  onResize = () => {
    const { targetRef } = this.props;
    const { posX: x, posY: y, rect } = this.state;

    // Get relative position with the previous container
    const pos = relativePositionFromRect({ x, y }, rect);

    const position = absolutePosition(pos, targetRef);

    this.setState({ posX: position.x, posY: position.y, rect: targetRef.getBoundingClientRect() });
  };

  handleLookingForComment = (event) => {
    const { children, toggleOpenComments } = this.props;
    const commentNumber = event ? event.detail.commentNumber : this.props.commentNumber;

    if (commentNumber === children) {
      this.setState(
        {
          showComments: true,
        },
        () => {
          document.querySelector('.mfp-wrap').scroll(0, this.state.posY);

          toggleOpenComments(true);
        }
      );
    }
  };

  handleMouseDown = () => {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    cancelClick = setTimeout(holdClick.reset, holdClick.delay);

    this.setState({
      isDragging: true,
    });
  };

  handleMouseMove = ({ clientX, clientY }) => {
    const { isDragging } = this.state;

    if (!isDragging) {
      return;
    }

    this.setState(() => {
      const rect = this.props.targetRef.getBoundingClientRect();
      const currentX = clientX - rect.x - POINTER_RADIUS;
      const currentY = clientY - rect.y - POINTER_RADIUS;

      if (
        currentX <= 0 ||
        currentY <= 0 ||
        currentX >= rect.width - POINTER_RADIUS * 2 ||
        currentY >= rect.height - POINTER_RADIUS
      ) {
        return null;
      }

      const posX = clientX - rect.x;
      const posY = clientY - rect.y;
      const commentsWidth = this.commentsRef ? this.commentsRef.offsetWidth : 0;
      const isRightSided = posX - commentsWidth + POINTER_RADIUS * 2 > 500;

      return {
        posX,
        posY,
        isRightSided,
      };
    });
  };

  handleMouseUp = (event) => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    clearTimeout(cancelClick);

    if (isValidClick) {
      this.onClick(event);
    } else {
      this.onMouseUp(event);
    }

    isValidClick = true;
  };

  onMouseUp = ({ clientX, clientY }) => {
    const { user } = this.context;

    this.setState(
      () => {
        return { isDragging: false };
      },
      () => {
        if (this.state.comments.length <= 0) {
          return;
        }

        const parentComment = this.state.comments[0];
        const position = relativePositionFromEvent({ clientX, clientY }, this.props.targetRef);

        moveComment(parentComment.id, position, user);
      }
    );
  };

  onClick = (event) => {
    event.stopPropagation();
    const { clientX } = event;

    document.dispatchEvent(new Event('closeCommentsList'));

    if ((this.props.isNew && this.state.comments.length <= 0) || this.state.comments.length <= 0) {
      return this.props.removePointer(this.props.children);
    }

    // If other is open, do nothing
    if (this.props.areCommentsOpen && !this.state.showComments) {
      return null;
    }

    const rect = this.props.targetRef.getBoundingClientRect();
    const translateX = clientX - rect.x;
    const commentsWidth = this.commentsRef ? this.commentsRef.offsetWidth : 0;
    const isRightSided = translateX - commentsWidth + POINTER_RADIUS * 2 > 500;

    this.setState(
      {
        isRightSided,
      },
      () => {
        this.toggleComments();
        this.props.toggleOpenComments();
      }
    );

    return null;
  };

  toggleComments = () => {
    this.setState((state) => ({
      showComments: !state.showComments,
    }));
  };

  handleSetComment = (comment, isDeleting = false) => {
    const { comments } = this.state;
    const isAlreadyInComments = comments.findIndex((c) => comment.id && c.id === comment.id);

    if (isAlreadyInComments > -1 && !isDeleting) {
      this.setState(
        (prevState) => {
          const copyComments = [...prevState.comments];

          copyComments[isAlreadyInComments] = comment;

          return {
            comments: copyComments,
          };
        },
        () => {
          this.dispatchComments(this.state.comments);
        }
      );
    } else if (isAlreadyInComments > -1 && isDeleting) {
      this.setState(
        (prevState) => {
          const copyComments = [...prevState.comments];

          copyComments.splice(isAlreadyInComments, 1);

          return {
            comments: copyComments,
          };
        },
        () => {
          if (this.state.comments.length === 0 && !this.props.isNew) {
            this.props.removePointer(this.props.children);
          }

          this.dispatchComments(this.state.comments);
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          comments: [...prevState.comments, comment],
        }),
        () => {
          this.dispatchComments(this.state.comments);
        }
      );
    }
  };

  dispatchComments = (comments) => {
    this.props.onDispatchComments(comments, this.props.children);
  };

  handleAddComment = async (comment) => {
    const position = relativePosition({ x: this.state.posX, y: this.state.posY }, this.props.targetRef);
    const { user } = this.context;

    try {
      return await createComment({ ...comment, ...position }, user);
    } catch (err) {
      if (err.status === 422) {
        const json = await err.json();
        if (json.errors.some((msg) => msg === 'Page is invalid')) {
          // Now that we have auto-save the most probable cause of this error is that the sitemap is running.
          let message =
            'Sorry, the sitemap owner will need to either PAUSE or STOP crawling this sitemap for you to leave a new comment.';

          // share link (public view, non-team members) doesn't subscribe to sitemap updated, it keeps the initial status.
          // Be more accurate for team-members and use the most likley cause for others.
          if (user.is_current_team_member) {
            if (['finished', 'paused'].includes(window.sitemapStatus)) {
              message =
                'PLEASE NOTE: For newly created pages, you will first need to save your sitemap before you can add a comment to them.';
            } else {
              message =
                'Sorry, you will need to either PAUSE or STOP crawling this sitemap for you to leave a new comment.';
            }
          }

          showAlert(message, { container: '.screenshot-alert-container', id: 'add-comment-issue-alert' });
        }
      }
      throw err;
    }
  };

  handleHidePointerComment = () => {
    this.setState(
      (prevState) => {
        if (this.props.isNew && prevState.comments.length <= 0) {
          this.props.removePointer(this.props.children);
        }

        return { showComments: false };
      },
      () => {
        this.props.toggleOpenComments(false);
      }
    );
  };

  setCommentsRef = (commentsRef) => {
    this.commentsRef = commentsRef;
  };

  render() {
    const { children, removePointer, onMouseOver, onMouseLeave, sitemapId, removeComment } = this.props;
    const { comments, showComments, posX, posY, isDragging, isRightSided } = this.state;
    const isResolved = comments[0] && comments[0].resolved;
    const { user: currentUser } = this.context;

    const pointerContent = isResolved ? '✓' : children;

    return (
      <>
        {currentUser && (
          <Container
            x={posX}
            y={posY}
            ref={this.pointerRef}
            $isActive={showComments}
            $isDragging={isDragging}
            $isResolved={isResolved}
            onFocus={() => undefined}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onMouseDown={this.handleMouseDown}
            onClick={(event) => event.stopPropagation()}
          >
            {pointerContent}
          </Container>
        )}
        {!currentUser && (
          <Container
            x={posX}
            y={posY}
            ref={this.pointerRef}
            $isActive={showComments}
            $isResolved={isResolved}
            onClick={this.onClick}
          >
            {pointerContent}
          </Container>
        )}

        {showComments && (
          <Comments
            {...this.props}
            x={posX}
            y={posY}
            comments={comments}
            isResolved={isResolved}
            commentNumber={children}
            isRightSided={isRightSided}
            sitemapId={sitemapId}
            setCommentsRef={this.setCommentsRef}
            pointerRef={this.pointerRef.current}
            handleSetComment={this.handleSetComment}
            handleAddComment={this.handleAddComment}
            handleHidePointerComment={this.handleHidePointerComment}
            handleRemovePointer={() => removePointer(children)}
            handleRemoveComment={removeComment}
          />
        )}
      </>
    );
  }
}

Pointer.contextType = AuthContext;

export default Pointer;
