import { useEffect } from 'react';
import pick from 'lodash.pick';

import { dataPayload } from '../message-handlers';
import { fetchUserFlow } from '../api';

const sendMessage = (iframe, payload) => {
  if (!Object.keys(payload).length) {
    return;
  }

  const message = {
    ...payload,
    action: 'pushEvent:setData',
    caller: 'vsm',
  };

  iframe.contentWindow.postMessage({ message }, '*');
};

function changedFields(changeEvent) {
  const fields = new Set(changeEvent.detail.changes);

  // Changes in sharing settings may affect permissions or "include board position" (share zoom & position)
  if (fields.has('sharing')) {
    fields.add('permissions');
    fields.add('zoom');
    fields.add('position');
  }

  return fields;
}

// Turn a set of flow fields into an array of fields that can appear in 'getData' response.
function renameToDataFields(fields) {
  if (fields.has('state')) {
    fields.delete('state');
    fields.add('data');
  }

  return Array.from(fields);
}

function useFlowRefresher({ id, context, iframeRef, stateRef }) {
  useEffect(() => {
    const refreshFlow = async (event) => {
      // Extract which fields need to be updated.
      const fields = changedFields(event);

      if (fields.size === 0) {
        return;
      }

      // Search for the fields that changed
      const newFlow = await fetchUserFlow(id, Array.from(fields)).catch((err) => {
        if (err.status >= 400 && err.status < 500) {
          // Reload to display forbidden page.
          window.location.reload();
          return null;
        }

        throw err;
      });

      if (!newFlow) {
        return;
      }

      // Update our cache
      Object.assign(stateRef.current.flow, newFlow);

      // Prepare the payload. Send just the fields that changed
      const payload = pick(dataPayload(stateRef.current.flow, context), renameToDataFields(fields));

      // Notify FlowApp about the changes
      sendMessage(iframeRef.current, payload);
    };

    // This event is fired by ActionCable
    document.addEventListener('userFlow:stateChanged', refreshFlow);

    return () => document.removeEventListener('userFlow:stateChanged', refreshFlow);
  }, [id, context, iframeRef, stateRef]);
}

export default useFlowRefresher;
