/* global $, treeState, saveAs */

import classNames from 'classnames';
import { stringify } from 'csv-stringify/browser/esm';
import { useState } from 'react';

import { get } from '../../common/request';

async function getPagesContents(sitemapSessionId) {
  const url = `/api/v1/sitemaps/${sitemapSessionId}/pages/export`;

  return get(url);
}

async function getSitemapContent(sitemapSessionId) {
  const url = `/api/v1/sitemaps/${sitemapSessionId}/content/export`;

  return get(url);
}

function serializePage(includePageContent = true) {
  const pathName = (url) => {
    if (!url) {
      return '';
    }

    try {
      return new URL(url).pathname;
    } catch (e) {
      return '';
    }
  };

  return (data) => {
    const [page, pageContent = {}] = data;
    const { id, url, screenshot, name } = page;
    const { meta_description, keywords, h1, h2, notes, gdocs, files, content } = pageContent;

    const tags = treeState.tagsOf(id, { hydratate: true }).map((t) => t.name);

    const result = {
      depth: pathName(url).split('/').length - 2,
      url,
      screenshot,
      name,
      tags,
    };

    if (includePageContent) {
      Object.assign(result, {
        keywords,
        meta_description,
        h1,
        h2,
        notes,
        gdocs,
        files,
        content,
      });
    }

    return result;
  };
}

function serializeSitemap({ gdocs, files, content }) {
  return { name: 'Project Deails', gdocs, files, content };
}

function getColumns({ includePageContent = true, includeSitemapContent = true } = {}) {
  const columns = [
    { key: 'depth', header: 'Depth' },
    { key: 'url', header: 'URL' },
    { key: 'screenshot', header: 'Screenshot' },
    { key: 'name', header: 'Title' },
  ];

  if (includePageContent) {
    columns.push(
      ...[
        { key: 'keywords', header: 'SEO Keywords' },
        { key: 'h1', header: 'SEO h1' },
        { key: 'h2', header: 'SEO h2' },
        { key: 'meta_description', header: 'SEO Meta description' },
        { key: 'content', header: 'Content' },
        { key: 'notes', header: 'Internal Notes' },
      ]
    );
  }

  if (includeSitemapContent || includePageContent) {
    columns.push(
      ...[
        { key: 'gdocs', header: 'Google Docs' },
        { key: 'files', header: 'Files' },
      ]
    );
  }

  columns.push({ key: 'tags', header: 'Tags' });

  return columns;
}

function saveCsv(sitemapContent, pagesContents) {
  const includePageContent = Boolean(pagesContents);
  const includeSitemapContent = Boolean(sitemapContent);
  const data = treeState.visiblePages
    .map((page) => [page, pagesContents?.find((pc) => pc.page_id === page.pageId.toString())])
    .map(serializePage(includePageContent));

  if (includeSitemapContent) {
    data.unshift(serializeSitemap(sitemapContent));
  }

  stringify(
    data,
    {
      header: true,
      columns: getColumns({ includeSitemapContent, includePageContent }),
      cast: {
        object(value) {
          if (Array.isArray(value)) {
            return value.join(', ');
          }
          return JSON.stringify(value);
        },
      },
    },
    (err, csv) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } else {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'sitemap.csv');
      }
    }
  );
}

export default function ExportCsvButton({ sitemapSessionId, disabled }) {
  const [generating, setGenerating] = useState();
  const generateCsv = async () => {
    // When user is not allowed to export page contents or sitemap contents, we still want to export.
    // Return null on any error
    const [sitemapContent, pagesContents] = await Promise.all([
      getSitemapContent(sitemapSessionId).catch(() => null),
      getPagesContents(sitemapSessionId).catch(() => null),
    ]);

    saveCsv(sitemapContent, pagesContents);
    $.post('/track', { type: 'exportSitemapCsv', sitemapSessionId });
  };

  const handleClick = async () => {
    setGenerating(true);
    try {
      await generateCsv();
    } finally {
      setGenerating(false);
    }
  };

  return (
    <button
      type="button"
      className={classNames('btn btn-secondary export csv_export', { disabled, pro: disabled })}
      onClick={handleClick}
    >
      csv&nbsp;
      {generating && <i className="fas fa-spinner fa-spin" />}
    </button>
  );
}
