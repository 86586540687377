import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { selectTheme } from './theme';
import CloseButton from './CloseButton';
import AuthenticityToken from './AuthenticityToken';

const DEFAULT_CONFIG = {
  formMethod: 'post',
  projectParamName: 'project_id',
  extraHiddens: {},
};

function ProjectSelector({ projects, currentProjectId }) {
  const [config, setConfig] = useState({});
  const [visible, setVisible] = useState(false);
  const projectParam = useRef();
  const form = useRef();

  useEffect(() => {
    const onInit = ({ detail }) => {
      setConfig({ ...DEFAULT_CONFIG, ...detail });
      setVisible(true);
    };

    document.addEventListener('moveProjectInit', onInit);

    return () => {
      document.removeEventListener('moveProjectInit', onInit);
    };
  }, []);

  const onChange = (project) => {
    if (projectParam.current) {
      projectParam.current.value = project.id;
    }

    form.current && form.current.submit();

    setVisible(false);
  };

  const closeModal = (event) => {
    event?.preventDefault();
    setVisible(false);
  };

  const detectClickOutsideRef = useDetectClickOutside({
    disableClick: true,
    disableTouch: true,
    onTriggered: () => closeModal(),
  });

  if (!visible) {
    return null;
  }

  const { formAction, formMethod, extraHiddens, projectParamName } = config;
  const isProjectDisabled = (project) => project.id === currentProjectId;

  return (
    <>
      <div className="project-selector-backdrop" ref={detectClickOutsideRef} />
      <div className="project-selector-modal" ref={detectClickOutsideRef}>
        <CloseButton className="modal-close" onClick={closeModal} />

        <form action={formAction} method={formMethod} data-remote="true" ref={form}>
          <AuthenticityToken />
          <input name={projectParamName} type="hidden" ref={projectParam} />
          {Object.entries(extraHiddens).map(([name, value]) => (
            <input key={name} name={name} type="hidden" value={value} />
          ))}

          <div className="pt-2">
            <ProjectSelect
              projects={projects}
              autoFocus
              placeholder="Search…"
              emptyMessage="No values"
              onChange={onChange}
              isOptionDisabled={isProjectDisabled}
              disabledMessage="You are working on this project"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
  }),
  control: (provided) => ({
    ...provided,
    order: 1, // Invert order: menu up, control down
    marginTop: '0.75rem',
    backgroundColor: 'transparent',
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    color: state.theme.colors.primary,
    backgroundColor: 'transparent',
    minHeight: 150,
    width: '100%',
    paddingLeft: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    fontSize: '1rem',
    lineHeight: 1.1875,
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isFocused ? 'white' : 'inherit',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    position: 'relative',
  }),
};

const ThinScrollbarsMenuList = ({ children, ...rest }) => {
  return (
    <components.MenuList className="thin-scrollbars" {...rest}>
      {children}
    </components.MenuList>
  );
};

const ProjectOption = ({ children, innerRef, ...rest }) => {
  const { data, isFocused, isDisabled } = rest;
  const { disabledMessage } = rest.selectProps;

  const classes = useMemo(() => {
    return classNames('btn btn-primary ml-auto', { invisible: !isFocused });
  }, [isFocused]);

  return (
    <div title={isDisabled ? disabledMessage : `Move to ${data.name}`}>
      <components.Option {...rest}>
        <div className="d-inline-flex align-items-center w-100">
          <div className="text-truncate mr-3">{children}</div>
          <button type="button" className={classes}>
            Move here
          </button>
        </div>
      </components.Option>
    </div>
  );
};

export const selectComponents = {
  DropdownIndicator: null,
  MenuList: ThinScrollbarsMenuList,
  Option: ProjectOption,
};

function ProjectSelect({
  projects,
  autoFocus,
  placeholder,
  emptyMessage,
  isOptionDisabled,
  disabledMessage,
  onChange,
}) {
  const noMessage = useCallback(() => emptyMessage, [emptyMessage]);

  return (
    <Select
      autoFocus={autoFocus}
      placeholder={placeholder}
      noOptionsMessage={noMessage}
      tabSelectsValue={false}
      options={projects}
      getOptionLabel={(project) => project.name}
      getOptionValue={(project) => project.id}
      menuIsOpen
      styles={selectStyles}
      theme={selectTheme}
      isOptionDisabled={isOptionDisabled}
      onChange={onChange}
      components={selectComponents}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      disabledMessage={disabledMessage}
    />
  );
}

export default ProjectSelector;
