import { createRoot } from 'react-dom/client';

import 'blueimp-file-upload/js/jquery.fileupload';
import 'blueimp-file-upload/js/jquery.fileupload-validate';

import { AuthProvider } from '../contexts/AuthContext';
import { showAlert } from '../common/alert';
import ReactRailsUJS, { setupReactRailsForVite } from '../react-rails';

// Be able to reneder all components in the components folder using React-Rails gem
setupReactRailsForVite({
  components: import.meta.glob('@/components/**/*.jsx', { eager: true }),
  path: '/components',
});

const getCommentsContainer = (baseContainer) => {
  let commentsContainer = document.querySelector('#comments-container');
  if (commentsContainer) {
    return commentsContainer;
  }

  const img = baseContainer.querySelector('img');
  commentsContainer = document.createElement('div');
  commentsContainer.setAttribute('id', 'comments-container');
  commentsContainer.classList.add('comments-container');
  commentsContainer.style.top = `${img.offsetTop}px`;
  commentsContainer.style.bottom = 0;
  commentsContainer.style.left = 0;
  commentsContainer.style.right = 0;
  commentsContainer.style.position = 'absolute';

  const figure = baseContainer.querySelector('figure');
  figure.appendChild(commentsContainer);

  return commentsContainer;
};

const CommentsContext = {
  createCommentsButtonRoot: (domContainer) => {
    if (!window.commentsEnabled) {
      return null;
    }

    const ToggleListButton = ReactRailsUJS.getConstructor('comments/ToggleListButton');

    const root = createRoot(domContainer);

    return {
      render(props) {
        root.render(
          <AuthProvider>
            <ToggleListButton {...props} />
          </AuthProvider>
        );

        return this;
      },
      reattach(newContainer) {
        newContainer.parentNode.replaceChild(domContainer, newContainer);
      },
      unmount() {
        root.unmount();
      },
    };
  },
  createCommentsRoot: (domContainer) => {
    if (!window.commentsEnabled && !window.commentsWarning) {
      return null;
    }

    const Comments = ReactRailsUJS.getConstructor('comments/Comments');

    const container = getCommentsContainer(domContainer);
    const root = createRoot(container);

    return {
      render(item, commentNumber) {
        const props = {
          image: item.src,
          annotations: item.data.commentThreads,
          sitemapId: item.data.sitemapId,
          pageId: item.data.pageId,
          commentNumber,
          disabledWarning: window.commentsWarning,
        };

        root.render(
          <AuthProvider>
            <Comments {...props} />
          </AuthProvider>
        );

        return this;
      },
      unmount() {
        root.unmount();
      },
    };
  },
};

window.Comments = CommentsContext;
window.showAlert = showAlert;
