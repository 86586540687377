import styled from 'styled-components';

import { badgeVariant } from './utils/forms';

export const Badge = styled.span.attrs({
  className: 'badge text-capitalize text-truncate text-left flex-shrink-0',
})`
  min-width: ${({ $variant }) => ($variant === 'short' ? 'auto' : '50px')};
  max-width: 200px;
  padding: 5px 7px;

  ${({ color }) => badgeVariant(color)}
`;

export default Badge;
