import styled from 'styled-components';

import { TagPicker } from '../../sitemap-tagging/TagPicker';
import useTagInput from '../../sitemap-tagging/hooks/useTagInput';

const StyledTagPicker = styled(TagPicker)`
  min-width: 200px;
`;

export const InputTag = ({ className, currentSelection, editionEnabled }) => {
  const props = useTagInput(currentSelection);
  return (
    <div className={className}>
      Change Tags:
      <StyledTagPicker className="ml-2 px-2" clearable charsBreakpoint={0} editable={editionEnabled} {...props} />
    </div>
  );
};

export default InputTag;
