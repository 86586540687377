import { ErrorMessage, Field } from 'formik';
import classNames from 'classnames';

export const Input = ({ name, as, className, ...props }) => {
  const Component = as || Field;
  const classes = classNames(className, { 'form-control': Component === Field });

  return (
    <div className="form-group">
      <Component name={name} className={classes} {...props} />
      <ErrorMessage name={name} component="div" className="help-block form-text with-errors form-control-feedback" />
    </div>
  );
};

export default Input;
