import { memo } from 'react';
import styled from 'styled-components';

import { Comments } from './styles';
import RenderComment from './RenderComment';
import { useAuth } from '../../../contexts/AuthContext';
import { shouldOpenInNewTab } from '../../../common/location';

const LoginLink = styled.a`
  color: #20bbff;
  &:hover {
    color: #20bbff;
  }
`;

const loginTarget = shouldOpenInNewTab() ? '_blank' : undefined;

const RenderComments = ({
  comments,
  isResolved,
  sitemapId,
  handleSetComment,
  handleRemovePointer,
  teamMembers,
  handleRemoveComment,
}) => {
  const { user } = useAuth();

  return (
    <Comments>
      {comments.map((comment) => (
        <RenderComment
          key={`${comment.created_at}-${comment.id}`}
          comment={comment}
          comments={comments}
          sitemapId={sitemapId}
          isResolved={isResolved}
          teamMembers={teamMembers}
          handleSetComment={handleSetComment}
          handleRemovePointer={handleRemovePointer}
          handleRemoveComment={handleRemoveComment}
        />
      ))}
      {!user && (
        <LoginLink href="/users/sign_up" target={loginTarget}>
          Sign up to leave a comment
        </LoginLink>
      )}
    </Comments>
  );
};

export default memo(RenderComments);
