import styled from 'styled-components';

import { commentsTheme } from '../../shared/theme';

export const Pointer = styled.div`
  cursor: move;
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border-radius: 40px;
  z-index: 2;
  font-weight: bold;
  background-color: ${({ $isResolved }) => ($isResolved ? '#00FFAF' : '#553daf')};
  border: 3px solid #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  color: ${({ $isResolved }) => ($isResolved ? '#2A2A2A' : '#FFF')};
  font-family: ${commentsTheme.fontFamily};
`;

export const PointerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;
