import { get, post, patch, destroy, parsedErrors } from '../../../common/request';

export const getTags = async (sitemapId) => {
  const url = `/api/v1/sitemaps/${sitemapId}/tags`;

  return get(url).catch(parsedErrors);
};

export const createTag = async (sitemapId, tag) => {
  // FIXME: don't grab from window. Use it as a parameter
  return post(`/api/v1/sitemaps/${sitemapId}/tags`, {
    data: { tag, done_by: window.App.sitemaTagsSubscription?.userUuid },
  })
    .then((data) => data.tag)
    .catch(parsedErrors);
};

export const updateTag = async (tagId, tag) => {
  // FIXME: don't grab from window. Use it as a parameter
  return patch(`/api/v1/tags/${tagId}`, { data: { tag, done_by: window.App.sitemaTagsSubscription?.userUuid } }).catch(
    parsedErrors
  );
};

export const deleteTag = async (tagId) => {
  // FIXME: don't grab from window. Use it as a parameter
  return destroy(`/api/v1/tags/${tagId}?done_by=${window.App.sitemaTagsSubscription?.userUuid}`).catch(parsedErrors);
};
