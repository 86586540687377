import { useState } from 'react';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import PageButtons from './PageButtons';
import LazyImage from './LazyImage';

function ExpandedSitemapComparisonPageModal({
  pageInfo,
  onClose,
  flaggingEnabled,
  flagNotificationUserIds,
  sitemapComparisonId,
  openFlagSettings,
}) {
  const { page, sitemapAPage, sitemapBPage } = pageInfo;

  const [activeImage, setActiveImage] = useState('DIFF'); // DIFF, SITEMAP_A, SITEMAP_B

  const rotateActiveImage = () => {
    if (activeImage === 'DIFF') {
      setActiveImage('SITEMAP_B');
    } else if (activeImage === 'SITEMAP_B') {
      setActiveImage('SITEMAP_A');
    } else if (activeImage === 'SITEMAP_A') {
      setActiveImage('DIFF');
    }
  };

  return (
    <div className="modal-container">
      <div className="expanded-sitemap-comparison-page-modal">
        <div className="comparison-result-page">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">{sitemapAPage.pageTitle}</div>
              <div className="url-field">{sitemapAPage.url}</div>
            </div>
            <div className="col-sm-6">
              <div className="page-title page-title--cut">{sitemapAPage.pageTitle}</div>
              <div className="url-field">{sitemapBPage.url}</div>
              <PageButtons
                className="mb-2 mb-md-0"
                showExpandButton={false}
                onShrink={onClose}
                page={page}
                flaggingEnabled={flaggingEnabled}
                flagNotificationUserIds={flagNotificationUserIds}
                openFlagSettings={openFlagSettings}
                sitemapComparisonId={sitemapComparisonId}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="selector-buttons">
                <button
                  className={classNames('btn btn-selector btn-muted', { gray: activeImage !== 'SITEMAP_A' })}
                  type="button"
                  onClick={() => {
                    setActiveImage('SITEMAP_A');
                  }}
                >
                  ORIGINAL
                </button>
                <button
                  className={classNames('btn btn-selector btn-muted', { gray: activeImage !== 'DIFF' })}
                  type="button"
                  onClick={() => {
                    setActiveImage('DIFF');
                  }}
                >
                  DIFF
                </button>
                <button
                  className={classNames('btn btn-selector btn-muted', { gray: activeImage !== 'SITEMAP_B' })}
                  type="button"
                  onClick={() => {
                    setActiveImage('SITEMAP_B');
                  }}
                >
                  NEW
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <LazyLoad once>
                <div className="thumbnail-container">
                  <LazyImage
                    className={classNames({ 'd-none': activeImage !== 'DIFF' })}
                    hideLoader={activeImage !== 'DIFF'}
                    loaderWidth="100%"
                    loaderHeight="800px"
                    role="button"
                    src={page.imagePath}
                    onClick={rotateActiveImage}
                    alt="Diff result"
                  />
                  <LazyImage
                    className={classNames({ 'd-none': activeImage !== 'SITEMAP_A' })}
                    hideLoader={activeImage !== 'SITEMAP_A'}
                    loaderWidth="100%"
                    loaderHeight="800px"
                    role="button"
                    src={sitemapAPage.screenshotPath}
                    onClick={rotateActiveImage}
                    alt="Screenshot"
                  />
                  <LazyImage
                    className={classNames({ 'd-none': activeImage !== 'SITEMAP_B' })}
                    hideLoader={activeImage !== 'SITEMAP_B'}
                    loaderWidth="100%"
                    loaderHeight="800px"
                    role="button"
                    src={sitemapBPage.screenshotPath}
                    onClick={rotateActiveImage}
                    alt="Screenshot"
                  />
                </div>
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpandedSitemapComparisonPageModal;
