import ReactRailsUJS from 'react_ujs';

export function setupReactRailsForVite({ components, path }) {
  const componentsContext = (fileName) => {
    return components[`${path}/${fileName.substring(2)}.jsx`];
  };

  // https://github.com/reactjs/react-rails/tree/v2.7.0#getconstructor
  const original = ReactRailsUJS.constructorFromRequireContextWithGlobalFallback(componentsContext);
  ReactRailsUJS.getConstructor = (name) => {
    // Force ReactRailsUJS to use default export. We always work with ESM modules.
    // Original code requires module to have __esModule property.
    // We can not add this property to the module because it is read-only.
    return original(name).default;
  };
}

export default ReactRailsUJS;
