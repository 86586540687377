import { Children, cloneElement, Component } from 'react';
import getCaretCoordinates from 'textarea-caret';

function getHookObject(type, element, startPoint) {
  const caret = getCaretCoordinates(element, element.selectionEnd);

  const result = {
    hookType: type,
    cursor: {
      selectionStart: element.selectionStart,
      selectionEnd: element.selectionEnd,
      top: caret.top,
      left: caret.left,
      height: caret.height,
    },
  };

  if (!startPoint) {
    return result;
  }

  result.text = element.value.substr(startPoint, element.selectionStart);

  return result;
}

class InputTrigger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      triggered: false,
      triggerStartPosition: null,
    };

    this.handleTrigger = this.handleTrigger.bind(this);
    this.resetState = this.resetState.bind(this);
    const { elementRef } = this.props;
    this.element = elementRef;
  }

  componentDidMount() {
    const { endTrigger } = this.props;

    endTrigger(this.resetState);
  }

  handleTrigger(event) {
    const { trigger, onStart, onCancel, onType } = this.props;

    const { key, which } = event;

    const { selectionStart } = event.target;
    const { triggered, triggerStartPosition } = this.state;

    if (!triggered) {
      if (key === trigger.key) {
        this.setState(
          {
            triggered: true,
            triggerStartPosition: selectionStart + 1,
          },
          () => {
            setTimeout(() => {
              onStart(getHookObject('start', this.element));
            }, 0);
          }
        );
        return null;
      }
    } else {
      if (which === 8 && selectionStart <= triggerStartPosition) {
        this.setState(
          {
            triggered: false,
            triggerStartPosition: null,
          },
          () => {
            setTimeout(() => {
              onCancel(getHookObject('cancel', this.element));
            }, 0);
          }
        );

        return null;
      }

      setTimeout(() => {
        onType(getHookObject('typing', this.element, triggerStartPosition));
      }, 0);
    }

    return null;
  }

  resetState() {
    this.setState({
      triggered: false,
      triggerStartPosition: null,
    });
  }

  render() {
    const { elementRef, children, trigger, onStart, onCancel, onType, endTrigger, ...rest } = this.props;

    return (
      <div role="textbox" tabIndex={-1} onKeyDown={this.handleTrigger} {...rest}>
        {!elementRef
          ? Children.map(children, (child) =>
              cloneElement(child, {
                ref: (element) => {
                  this.element = element;
                  if (typeof child.ref === 'function') {
                    child.ref(element);
                  }
                },
              })
            )
          : children}
      </div>
    );
  }
}

InputTrigger.defaultProps = {
  trigger: {
    keyCode: null,
    key: null,
    shiftKey: false,
    ctrlKey: false,
    metaKey: false,
  },
  onStart: () => {},
  onCancel: () => {},
  onType: () => {},
  endTrigger: () => {},
  elementRef: null,
};

export default InputTrigger;
