/* eslint-disable import/prefer-default-export */
import { create } from 'zustand';

export const useSitemapTreeMode = create((set) => ({
  orientation: 'vertical', // vertical | horizontal
  isGridView: false,
  setMode: (mode) => {
    if (mode === 'grid') {
      set({ isGridView: true });
    } else {
      set({ orientation: mode, isGridView: false });
    }
  },
  setGridView: (isGridView) => set({ isGridView }),
}));
