import { useIsMutating } from '@tanstack/react-query';

import Title from '../content-editor/Title';
import UpdatedIndicator from '../content-editor/UpdatedIndicator';
import { sitemapContentKey, useSitemapContent } from './hooks';
import { useSitemap } from '../../contexts/SitemapContext';

export default function SitemapTitle({ title }) {
  return (
    <Title>
      <h2 className="font-weight-bold text-truncate m-0" title={title}>
        {title}
      </h2>
      <SitemapUpdateIndicator />
    </Title>
  );
}

function SitemapUpdateIndicator() {
  const { sitemapId } = useSitemap();
  const { data } = useSitemapContent();
  const saving = useIsMutating({ mutationKey: sitemapContentKey(sitemapId), exact: true });

  return <UpdatedIndicator saving={Boolean(saving)} updatedAt={data?.updated_at} />;
}
