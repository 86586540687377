import { useState, useCallback, memo } from 'react';
import { useAbortItem, useItemProgressListener, useItemFinalizeListener, FILE_STATES } from '@rpldy/uploady';
import { useRetry } from '@rpldy/retry-hooks';

import { UploadProgressBar } from './UploadProgressBar';
import { AbortUploadButton } from './AbortUploadButton';
import Backdrop from '../Backdrop';
import Thumbnail from './Thumbnail';
import UploadContainer from './UploadContainer';

const STATES = {
  PROGRESS: 'PROGRESS',
  DONE: 'DONE',
  ABORTED: 'ABORTED',
  ERROR: 'ERROR',
};

const CONTAINER_CLASSES = {
  [STATES.ERROR]: 'text-danger',
  [STATES.ABORTED]: 'text-danger',
  [STATES.DONE]: 'text-primary',
};

const getStateReason = ({ state, uploadStatus }) => {
  if (state === FILE_STATES.ABORTED || state === FILE_STATES.CANCELLED) {
    return 'Canceled';
  }

  if (state === FILE_STATES.ERROR) {
    return uploadStatus === 0 ? 'Network error' : `Request Failed with ${uploadStatus}`;
  }

  return '';
};

function AbortButton({ id, onClick }) {
  const abortItem = useAbortItem();
  const onAbort = useCallback(() => abortItem(id), [id, abortItem]);

  const handleClick = () => {
    onAbort();
    onClick && onClick();
  };

  return <AbortUploadButton onClick={handleClick} />;
}

function RetryButton({ id, className }) {
  const retry = useRetry();
  const onRetry = useCallback(() => {
    retry(id);
  }, [retry, id]);

  return (
    <button
      type="button"
      className={`btn btn-link text-primary ${className}`}
      aria-label="Retry"
      title="Retry"
      onClick={onRetry}
    >
      <i className="fas fa-redo" />
    </button>
  );
}

const QueueItem = memo(({ id, url, name, onRemove, removePreview }) => {
  const [percent, setPercent] = useState(0);
  const [itemState, setItemState] = useState(STATES.PROGRESS);
  const [stateReason, setStateReason] = useState('');

  useItemProgressListener((item) => {
    setPercent(Math.round(item.completed));
  }, id);

  useItemFinalizeListener((item) => {
    if (item.state === FILE_STATES.ABORTED) {
      removePreview();
      // Do not listen to updates after abort
      return;
    }

    setItemState(item.state === FILE_STATES.FINISHED ? STATES.DONE : STATES.ERROR);
    setStateReason(getStateReason(item));
  }, id);

  const handleRemove = () => {
    removePreview();
    if (onRemove) {
      onRemove();
    }
  };

  return (
    <div className={`${CONTAINER_CLASSES[itemState]} backdrop-parent`}>
      <Thumbnail className="rounded" src={url} alt="Upload preview">
        {itemState === STATES.ERROR && (
          <Backdrop className="rounded py-2 flex-column">
            <RetryButton id={id} className="ml-auto" />
            <button
              type="button"
              className="btn btn-link text-danger ml-auto mt-auto"
              aria-label="Remove"
              title="Remove"
              onClick={handleRemove}
            >
              <i className="fas fa-trash" />
            </button>
          </Backdrop>
        )}
        {itemState === STATES.DONE && (
          <Backdrop className="rounded py-2">
            <button
              type="button"
              className="btn btn-link text-danger ml-auto mt-auto"
              aria-label="Remove"
              title="Remove"
              onClick={handleRemove}
            >
              <i className="fas fa-trash" />
            </button>
          </Backdrop>
        )}
        {itemState === STATES.PROGRESS && (
          <UploadContainer>
            <UploadProgressBar progress={percent} abort={<AbortButton id={id} />} />
          </UploadContainer>
        )}
      </Thumbnail>
      <div className="my-2 text-break" title={stateReason}>
        {name}
      </div>
    </div>
  );
});

export default QueueItem;
