/* global Sitemap */

export default function EditionGuard({ disabled, children }) {
  if (disabled) {
    return children;
  }

  const handleClick = (e) => {
    if (Sitemap.pageContentGuard.check()) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="position-relative" onClickCapture={handleClick}>
      {children}
    </div>
  );
}
