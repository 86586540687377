/* global $ */
/* global easydropdown */
import { useState, useEffect } from 'react';

import CloseButton from '../shared/CloseButton';
import ComparisonSubject from './SitemapComparisonModal/ComparisonSubject';

function SitemapComparisonModal() {
  const [sitemapA, setSitemapA] = useState(false);
  const [sitemapB, setSitemapB] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [isDisplayed, setIsDisplayed] = useState(false);

  const handleSitemapSelected = ({ detail }) => {
    if (!sitemapA) {
      setSitemapA(detail);
    } else if (!sitemapB) {
      setSitemapB(detail);
    }

    if (sitemapA || sitemapB) {
      const selection = [sitemapA?.sitemapId, sitemapB?.sitemapId, detail.sitemapId].filter(Boolean);
      document.dispatchEvent(
        new CustomEvent('disableSitemapComparisonCheckboxes', {
          detail: { except: selection },
        })
      );
    }
  };

  const handleSitemapDeselected = ({ detail }) => {
    document.dispatchEvent(new CustomEvent('enableSitemapComparisonCheckboxes'));

    if (sitemapA && sitemapA.sitemapId === detail.sitemapId) {
      setSitemapA(false);
    }
    if (sitemapB && sitemapB.sitemapId === detail.sitemapId) {
      setSitemapB(false);
    }
  };

  // Sync modal state with sitemap-list checkbox state
  const handleSelectionSync = () => {
    const stateDetail = {
      checked: [sitemapA?.sitemapId, sitemapB?.sitemapId].filter(Boolean),
      uncheckedEnabled: !sitemapA || !sitemapB,
    };
    document.dispatchEvent(new CustomEvent('resetSitemapComparisonCheckboxes', { detail: stateDetail }));
  };

  const deselectSitemap = (detail) => {
    handleSitemapDeselected({ detail });
    document.dispatchEvent(new CustomEvent('uncheckSitemapComparisonCheckbox', { detail }));
  };

  const closeModal = (event) => {
    event.preventDefault();
    setSitemapA(false);
    setSitemapB(false);
    document.dispatchEvent(new CustomEvent('resetSitemapComparisonCheckboxes'));
  };

  useEffect(() => {
    document.addEventListener('sitemapSelectedForComparison', handleSitemapSelected);
    document.addEventListener('sitemapDeselectedForComparison', handleSitemapDeselected);
    document.addEventListener('sitemapComparisonSelectionSyncRequired', handleSelectionSync);

    return () => {
      document.removeEventListener('sitemapSelectedForComparison', handleSitemapSelected);
      document.removeEventListener('sitemapDeselectedForComparison', handleSitemapDeselected);
      document.removeEventListener('sitemapComparisonSelectionSyncRequired', handleSelectionSync);
    };
  });

  useEffect(() => {
    setIsDisplayed(Boolean(sitemapA || sitemapB));
  }, [sitemapA, sitemapB]);

  useEffect(() => {
    if (isDisplayed) {
      easydropdown('#sensitivity-select');
      $('#sensitivity-tooltip').tooltip({ container: '.sitemap-comparison-modal' });
    }
  }, [isDisplayed]);

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonEnabled(false);
    $('#sitemap_comparison_sensitivity').val($('#sensitivity-select').val());
    $('#sitemap_comparison_sitemap_a_id').val(sitemapA.sitemapId);
    $('#sitemap_comparison_sitemap_b_id').val(sitemapB.sitemapId);
    $('#sitemap-comparison-form').submit();
  };

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="sitemap-comparison-modal">
      <CloseButton className="modal-close" onClick={closeModal} />

      <div className="row mt-4 mt-md-0">
        <div className="col-md-9 col-sm-12 pt-2">
          <div className="comparison-icon">
            <i className="fontello-icon-compare" />
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <ComparisonSubject className="mr-3" sitemap={sitemapA} onClose={() => deselectSitemap(sitemapA)} />
            </div>
            <div className="col-md-6 col-sm-12">
              <ComparisonSubject className="ml-3" sitemap={sitemapB} onClose={() => deselectSitemap(sitemapB)} />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-12 compare-button-container pr-3 mb-1">
          <div className="form-group">
            <label htmlFor="sensitivity-select">Pixel Sensitivity</label>
            <i
              id="sensitivity-tooltip"
              className="fontello-icon-info text-oslo-gray ml-1"
              data-html="true"
              data-placement="top"
              data-title="Pixel Sensitivity = the threshold used in the visual comparisons between two screenshots. We recommend using 'Medium' to start."
              data-toggle="tooltip"
              data-original-title=""
              title=""
            />
            <div className="input-group with-prepend">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="far fa-eye text-primary" />
                </div>
              </div>
              <select id="sensitivity-select" defaultValue="0.1">
                <option value="0.05">High</option>
                <option value="0.1">Medium</option>
                <option value="0.3">Low</option>
              </select>
            </div>
          </div>
          <button
            className={`btn btn-muted ${sitemapA && sitemapB && buttonEnabled ? '' : 'disabled'}`}
            type="button"
            onClick={onSubmit}
          >
            + Compare
          </button>
        </div>
      </div>
    </div>
  );
}

export default SitemapComparisonModal;
