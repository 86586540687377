import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Field, useFormikContext } from 'formik';
import styled from 'styled-components';

import GdocPicker from './GdocPicker';
import GdocList from './GdocList';
import { Modal } from '../../Modal';

const GdocEditor = styled.iframe`
  width: 100%;
  height: 100%;
  border: 3px solid #3295ff;
`;

const DocsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 1rem;
`;

export default function Gdocs({ gdocs, disabled }) {
  const form = useFormikContext();
  const newGdocs = form.values.new_gdocs;
  const [selectedId, setSelectedId] = useState();
  const selectedUrl = [...gdocs, ...newGdocs].find((g) => g.id === selectedId)?.url;

  return (
    <>
      {createPortal(
        <Modal open={selectedUrl} onClose={() => setSelectedId(null)} bodyNoScrolling borderLess className="h100">
          {selectedUrl && <GdocEditor title="Document Editor" src={selectedUrl} />}
        </Modal>,
        document.body
      )}

      <DocsGrid>
        <Field
          as={GdocList}
          name="remove_gdocs"
          gdocs={gdocs}
          newGdocs={newGdocs}
          disabled={disabled}
          selectedId={selectedId}
          onSelect={setSelectedId}
        />
        <Field as={GdocPicker} name="new_gdocs" disabled={disabled} gdocs={gdocs} />
      </DocsGrid>
    </>
  );
}
