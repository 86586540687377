import { useEffect } from 'react';
import retryEnhancer from '@rpldy/retry-hooks';
import Uploady, { UPLOADER_EVENTS, useAbortAll } from '@rpldy/uploady';

import fetchPresignedUrl from '../../../common/presigned-url';

const onRequestPreSend = async ({ items }) => {
  // Abort request by returning empty URL
  let result = {
    items,
    options: { destination: { url: '' } },
  };

  try {
    const { file } = items[0];

    const response = await fetchPresignedUrl(file);

    result = {
      items: [{ ...items[0], blob: response }],
      options: { destination: response.direct_upload },
    };
  } catch (e) {
    // Nothing to do here. We will abort request by returning empty URL
  }

  return result;
};

const listeners = {
  [UPLOADER_EVENTS.REQUEST_PRE_SEND]: onRequestPreSend,
};

/**
 * Abort all uploads on unmount
 */
function AbortAllOnUnmount() {
  const abortAll = useAbortAll();

  useEffect(() => {
    return () => {
      abortAll();
    };
  }, [abortAll]);

  return null;
}

/** Bridge between active-storage and uploady */
function ActiveStorageUploady({ maxConcurrent = 3, fileFilter, children }) {
  return (
    <Uploady
      maxConcurrent={maxConcurrent}
      fileFilter={fileFilter}
      sendWithFormData={false}
      listeners={listeners}
      method="put"
      enhancer={retryEnhancer}
    >
      <AbortAllOnUnmount />
      {children}
    </Uploady>
  );
}

export default ActiveStorageUploady;
