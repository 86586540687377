/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* global $, toastr */
import { useState, useEffect } from 'react';
import classNames from 'classnames';

import SitemapComparisonPageSelectors from './SitemapComparisonPageSelectors';
import flagSettingsImage from './flag-settings.svg';
import { flagAllPages, resolveAllFlaggedPages } from './request';

function SitemapComparisonHeader({
  sitemapARootUrl,
  sitemapBRootUrl,
  flaggingEnabled,
  onPageSelectionChange,
  pages,
  differentPagesCount,
  flagNotificationUserIds,
  openFlagSettings,
  sitemapComparisonId,
}) {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [canFlagAll, setCanFlagAll] = useState(pages.some((p) => p.similarityIndex < 1.0 && p.status !== 'FLAGGED'));
  const [canResolveAll, setCanResolveAll] = useState(pages.some((p) => p.status === 'FLAGGED'));
  const [areAllFlaggedPagesResolved, setAreAllFlaggedPagesResolved] = useState(
    pages.some((p) => p.status === 'RESOLVED') && pages.every((p) => p.status !== 'FLAGGED')
  );
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const passedPagesCount = pages.filter((p) => p.similarityIndex >= 1.0).length;
  const flaggedPagesCount = pages.filter((p) => p.status === 'FLAGGED').length;
  const resolvedPagesCount = pages.filter((p) => p.status === 'RESOLVED').length;

  useEffect(() => {
    setCanFlagAll(pages.some((p) => p.similarityIndex < 1.0 && p.status !== 'FLAGGED'));
    setCanResolveAll(pages.some((p) => p.status === 'FLAGGED'));
    setAreAllFlaggedPagesResolved(
      pages.some((p) => p.status === 'RESOLVED') && pages.every((p) => p.status !== 'FLAGGED')
    );
  }, [pages]);

  const onWindowScroll = () => {
    const threshold = 210;
    if ($(window).scrollTop() > threshold && !isHeaderFixed) {
      setIsHeaderFixed(true);
    }
    if ($(window).scrollTop() < threshold && isHeaderFixed) {
      setIsHeaderFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  });

  const batchPageUpdates = (apiCall, eventName) => {
    if (!flagNotificationUserIds) {
      openFlagSettings();
      return;
    }
    setButtonsEnabled(false);
    apiCall(sitemapComparisonId)
      .then(() => {
        document.dispatchEvent(new CustomEvent(eventName));
      })
      .catch(() => {
        toastr.error('Sorry, something went wrong. Please try again later');
      })
      .finally(() => {
        setButtonsEnabled(true);
      });
  };

  const flagAll = () => {
    batchPageUpdates(flagAllPages, 'comparisonAllPagesFlagged');
  };

  const resolveAll = () => {
    batchPageUpdates(resolveAllFlaggedPages, 'comparisonAllFlaggedPagesResolved');
  };

  const flagAllButton = flaggingEnabled && canFlagAll && (
    <button className="btn btn-muted coral-red" type="button" onClick={flagAll} disabled={!buttonsEnabled}>
      Flag All
    </button>
  );

  const allFlaggedButton = flaggingEnabled && !canFlagAll && (
    <button className="btn btn-primary coral-red" type="button" disabled={!buttonsEnabled}>
      All Flagged
    </button>
  );

  const resolveAllButton = flaggingEnabled && canResolveAll && (
    <button className="btn btn-muted gray" type="button" onClick={resolveAll} disabled={!buttonsEnabled}>
      Resolve All
    </button>
  );

  const allResolvedButton = flaggingEnabled && areAllFlaggedPagesResolved && (
    <button className="btn btn-primary" type="button" disabled={!buttonsEnabled}>
      All Resolved
    </button>
  );

  const reviewFlaggedPages = () => {
    document.dispatchEvent(new CustomEvent('comparisonReviewFlaggedPages'));
  };

  const reviewButton = flaggedPagesCount > 0 && (
    <button className="btn btn-muted btn-review" type="button" onClick={reviewFlaggedPages}>
      {'Review '}
      <span className="text-sunglow">{flaggedPagesCount}</span>
      {flaggedPagesCount > 1 ? ' Issues' : ' Issue'}
    </button>
  );

  const flagSettingsButton = (
    <img
      className="flag-settings mr-3"
      src={flagSettingsImage}
      alt="Flag settings"
      aria-label="Flag settings"
      role="button"
      onClick={openFlagSettings}
    />
  );

  const headerButtons = flaggingEnabled && (
    <div className="header-buttons">
      {flagSettingsButton}
      {flagAllButton}
      {allFlaggedButton}
      {resolveAllButton}
      {allResolvedButton}
      {reviewButton}
    </div>
  );

  const comparisonUrl = (
    <span>
      {sitemapARootUrl}
      {sitemapARootUrl !== sitemapBRootUrl && (
        <>
          &nbsp;⟷&nbsp;
          {sitemapBRootUrl}
        </>
      )}
    </span>
  );

  return (
    <>
      <div className={classNames('comparison-result-header-container-placeholder', { 'd-none': !isHeaderFixed })} />
      <div className={classNames('comparison-result-header-container', { fixed: isHeaderFixed })}>
        <div className={classNames('comparison-result-header', { 'with-flagging-buttons': flaggingEnabled })}>
          <h5 className="dashboard-table-header">Comparison Results</h5>
          <div className="comparison-url">{comparisonUrl}</div>
          <div className="comparison-counter">
            <span>Passed </span>
            <span className="passed">{passedPagesCount}</span>
            <span> : Failed </span>
            <span className="failed">{differentPagesCount}</span>
            <span> : Resolved </span>
            <span className="resolved">{`${resolvedPagesCount} / ${flaggedPagesCount + resolvedPagesCount}`}</span>
          </div>
          {headerButtons}
          <hr className="d-sm-block d-md-none" />
          <SitemapComparisonPageSelectors onPageSelectionChange={onPageSelectionChange} />
        </div>
      </div>
    </>
  );
}

export default SitemapComparisonHeader;
