/* eslint-disable import/prefer-default-export */
/* global treeState */

import { useMemo } from 'react';
import classNames from 'classnames';

import { useFeatures } from '../../../contexts/FeaturesContext';
import useTagInput from '../../sitemap-tagging/hooks/useTagInput';
import { Label as Tags } from '../../sitemap-tagging/Label';
import { TagPicker } from '../../sitemap-tagging/TagPicker';
import { PageThumbnail } from './SitemapPage/PageThumbnail';
import ToggleCommentsList from '../../comments/ToggleListButton';
import { editUrl } from '../../../sitemap/tree-renderer/actions';

export function SitemapPage({ node, readonly, small = false }) {
  const { data: page } = node;
  return (
    <div className="d-flex w-100 pt-4">
      <PageThumbnail node={node} readonly={readonly} />
      <div className="ml-5 w-100">
        <PageLink node={node} readonly={readonly} />
        <div className={classNames('d-flex flex-gap-3', { 'align-items-start': !small, 'flex-column': small })}>
          <PageTags page={page} readonly={readonly} className={classNames({ 'order-1': small })} />
          <PageComments className={classNames({ 'ml-auto': !small, 'justify-content-center': small })} />
        </div>
      </div>
    </div>
  );
}

function PageLink({ node, readonly }) {
  const { data: page } = node;

  if (!page.url && readonly) {
    return null;
  }

  const handleClick = (e) => editUrl(e, node);

  return (
    <div className="mb-3 d-flex">
      {page.url && (
        <a href={page.url} className="external-link" target="_blank" rel="noreferrer noopener">
          {page.url}
        </a>
      )}
      <button
        type="button"
        className="btn btn-sm text-capitalize shadow-none btn-outline-dark-gray ml-2 align-self-baseline flex-shrink-0"
        onClick={handleClick}
      >
        Edit Url
      </button>
    </div>
  );
}

function PageTags({ page, readonly, className }) {
  const selection = useMemo(() => [page.id], [page.id]);
  const props = useTagInput(selection);
  const { tagEditionEnabled } = useFeatures();

  const tags = page.tags || treeState.tagsOf(page.id, { hydratate: true });

  return (
    <div className={classNames('d-flex flex-gap-2', className)}>
      {Boolean(tags?.length) && <Tags tags={tags} className="d-inline-flex flex-wrap" />}
      {!readonly && (
        <TagPicker
          {...props}
          className="border-0 p-0 flex-shrink-0"
          label={false}
          editable={!readonly && tagEditionEnabled}
          toggle={
            <button
              type="button"
              className="btn btn-sm text-capitalize shadow-none btn-outline-dark-gray align-self-baseline"
            >
              Edit Tags
            </button>
          }
        />
      )}
    </div>
  );
}

function PageComments({ className }) {
  const { commentsEnabled } = useFeatures();

  if (!commentsEnabled) {
    return null;
  }

  return <ToggleCommentsList className={className} />;
}
