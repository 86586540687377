/* global treeState, Sitemap */
import { useMemo, useState, useEffect } from 'react';

import { useTags } from './useTags';
import { dispatchEvent } from '../../shared/utils';
import config from '../../../config';
import { isReloadContext } from '../../../sitemap/utils';

const { NODE_TAGS_LIMIT } = config;

const contrainedTo = (tags) => (id) => tags.some((tag) => tag.id === id);

/**
 * Return a list of tag ids such that each elements is assigned to at least one node. Each id of the list
 * should be present on the tags list.
 *
 * @param {number|string[]} nodes - list of node ids
 * @param {any[]} tags - list of tags. Constraint returned elements to this list
 * @returns {number[]} list of tag ids
 */
const nodeTags = (nodes, tags) => {
  // list of ids. Union of tags assigned to each node.
  const ids = treeState.tagsOf(nodes);

  return ids.filter(contrainedTo(tags));
};

/**
 * Track "tagging version". It's an object that change every time sitemap reloads.
 *
 * Context: We want to recaculate the memoized "nodeTags" value every time the tagging (tag-node relation state) changes.
 *          Currenty that can only happen (outside this component) when tree reloads (external change).
 *          This is the fastest way to refresh this component state
 */
const useTaggingVersion = () => {
  const [version, setVersion] = useState({});

  useEffect(() => {
    const refreshVersion = (event) => {
      if (isReloadContext(event)) {
        // Force refresh after sitemap tree reload event. Maybe we have a new tagging state
        setVersion({});
      }
    };

    document.addEventListener('treeStateChanged', refreshVersion);

    return () => {
      document.removeEventListener('treeStateChanged', refreshVersion);
    };
  }, []);

  return version;
};

/**
 * Logic to edit tags of a given nodes selection.
 *
 * Separate from the UI to allow different UI implementations.
 */
function useTagInput(currentSelection) {
  const [tags, { addTag, updateTag: onUpdate, removeTag: onDelete }] = useTags();
  const taggingVersion = useTaggingVersion();

  const currentSelectionTags = useMemo(() => {
    return nodeTags(currentSelection, tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection, tags, taggingVersion]);

  const [value, setValue] = useState(currentSelectionTags);

  useEffect(() => {
    setValue(currentSelectionTags);
  }, [currentSelectionTags]);

  const onSelect = (ids) => {
    if (Sitemap.editionGuard.check()) {
      dispatchEvent('treeNodeAddTag', { nodes: currentSelection, tags: ids });
      setValue([...value, ...ids]);
    }
  };

  const onUnselect = (ids) => {
    if (Sitemap.editionGuard.check()) {
      dispatchEvent('treeNodeDeleteTag', { nodes: currentSelection, tags: ids });
      setValue(value.filter((tId) => ids.indexOf(tId) < 0));
    }
  };

  const onCreate = (tag) => {
    addTag(tag);
    if (value && value.length < NODE_TAGS_LIMIT && Sitemap.editionGuard.check({ interactive: false })) {
      onSelect([tag.id]);
    }
  };

  return {
    tags,
    value,
    onCreate,
    onSelect,
    onUnselect,
    onUpdate,
    onDelete,
    maxLength: NODE_TAGS_LIMIT,
  };
}

export default useTagInput;
