import styled from 'styled-components';

import { colorContrast } from '../../sitemap/tagging/colors';

const Square = styled.div.attrs({
  className: 'd-inline-flex align-items-center justify-content-center',
})`
  width: 20px;
  height: 20px;
  font-weight: 500;
  background: ${({ color }) => color};
  color: ${({ color }) => colorContrast(color)};
  text-transform: capitalize;
  border: 2px solid white;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
`;

export const TagSquare = ({ tag, className }) => {
  return (
    <Square key={tag.id} color={tag.color} className={className}>
      {tag.name[0]}
    </Square>
  );
};

export default TagSquare;
