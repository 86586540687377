/* global treeState */
import { useState, useEffect } from 'react';

import { dispatchEvent } from '../utils';

const clearSelection = () => {
  dispatchEvent('treeNodeClearSelection');
};

const toggleSelectionMultiple = (nodeIds, selected) => {
  dispatchEvent('treeNodeToggleSelectionMultiple', { nodeIds, selected });
};

export const useCurrentSelection = () => {
  const [currentSelection, setCurrentSelection] = useState([]);

  useEffect(() => {
    const refreshSelection = () => {
      // Take advantage of the fact that treeState.selection is inmutable.
      // each time we select/deselect a node we get a new array.
      setCurrentSelection(treeState.selection);
    };

    // Listen to every tree refresh. refreshSelection should be fast but if it gets heavy we
    // should trigger a different event
    document.addEventListener('treeRendered', refreshSelection);

    return () => {
      document.removeEventListener('treeRendered', refreshSelection);
    };
  }, []);

  return { currentSelection, clearSelection, toggleSelectionMultiple };
};

export default useCurrentSelection;
