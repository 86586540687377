export const POINTER_RADIUS = 20;

export const FILTERS = [
  { name: 'All', key: 'all', color: '#FFFFFF' },
  { name: 'Public', key: 'public', color: '#20BBFF' },
  { name: 'Private', key: 'private', color: '#FF9900' },
  { name: 'Unseen', key: 'unseen', color: '#EF5252' },
  { name: 'Unresolved', key: 'unresolved', color: '#8361FF' },
  { name: 'Resolved', key: 'resolved', color: '#F3F70A' },
];

export const PUBLIC_FILTERS = [
  { name: 'All', key: 'all', color: '#FFFFFF' },
  { name: 'Public', key: 'public', color: '#20BBFF' },
  { name: 'Unresolved', key: 'unresolved', color: '#8361FF' },
  { name: 'Resolved', key: 'resolved', color: '#F3F70A' },
];

export const filterForUser = (user) => {
  let filters = user ? FILTERS : PUBLIC_FILTERS;

  if (!user?.is_current_team_member) {
    filters = filters.filter((filter) => filter.key !== 'private');
  }

  return filters;
};

export const defaultFilter = (user) => (user ? FILTERS[3] : FILTERS[0]);
