/* global Sitemap */
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

export default function AutoSave({ onSave }) {
  const callbackRef = useRef();
  const formik = useFormikContext();
  const formikRef = useRef();
  formikRef.current = formik;

  callbackRef.current = onSave;

  useEffect(() => {
    const interval = setInterval(async () => {
      const { current: form } = formikRef;
      const { dirty, values } = form;
      if (dirty && Sitemap.pageContentGuard.check({ interactive: false })) {
        form.resetForm({ values });
        await callbackRef.current(values);
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    return () => {
      const { current: form } = formikRef;
      const { dirty, values } = form;
      if (dirty && Sitemap.pageContentGuard.check()) {
        callbackRef.current(values);
      }
    };
  }, []);

  return null;
}
