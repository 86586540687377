/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, forwardRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { useInputSearch } from '../../stores/input-search';

const Container = styled.div`
  input {
    transition: all ${({ $isInitialized }) => ($isInitialized ? '0.25s' : '0')} ease-in-out;
  }
`;

const SearchInput = forwardRef(({ initialSearchValue = '', onClear, onChange, onOpen }, ref) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [isInitialized, setIsInitialized] = useState(false);
  const { isOpen, setIsOpen } = useInputSearch();

  useLayoutEffect(() => {
    const q = new URLSearchParams(window.location.search).get('q');
    if (q) {
      setIsOpen(true);
    }
    setIsInitialized(true);
  }, [setIsOpen]);

  const onInputChange = (event) => {
    setSearchValue(event.target.value);
    onChange && onChange(event.target.value);
  };

  const onClearHandler = () => {
    setSearchValue('');
    setIsOpen(false);
    onClear && onClear();
  };

  const handleClick = () => {
    setIsOpen(true);
    onOpen && onOpen();
  };

  return (
    <Container
      id="pages-search-bar"
      className={classNames('search-bar d-flex align-items-center', { isOpen })}
      $isInitialized={isInitialized}
    >
      <i className="fas fa-search" onClick={handleClick} />
      <input ref={ref} value={searchValue} placeholder="Search" onChange={onInputChange} className="search-bar-input" />
      <i className="fas fa-times-circle" onMouseDown={onClearHandler} />
    </Container>
  );
});

export default SearchInput;
