import { Field } from 'formik';
import styled from 'styled-components';

import { colorContrast } from '../utils/colors';

const ColorBubble = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  i {
    font-size: 0.6rem;
  }
`;

const PickerContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(9, auto);
  grid-gap: 8px;
`;

export const ColorPicker = ({ colors, value, onChange }) => {
  let options = colors;

  if (value && options.every((color) => color !== value)) {
    options = [...options, value];
  }

  return (
    <PickerContainer>
      {options.map((c) => (
        <ColorBubble
          key={c}
          type="button"
          style={{ background: c, color: colorContrast(c) }}
          onClick={() => onChange(c)}
          title="Click to select the color"
          className="mb-2"
        >
          {value === c && <i className="fa fa-check" />}
        </ColorBubble>
      ))}
    </PickerContainer>
  );
};

export const ColorPickerField = ({ colors, ...props }) => {
  return (
    <Field {...props}>
      {({ field }) => <ColorPicker colors={colors} {...field} onChange={(c) => field.onChange(field.name)(c)} />}
    </Field>
  );
};

export default ColorPicker;
