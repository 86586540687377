import { useState } from 'react';

import Checkbox from './shared/Checkbox';

function UserSelector({ users, name = '', initiallySelectedUserIds = [], onUserIdsSelection = () => {} }) {
  const [selectedUserIds, setSelectedUserIds] = useState(initiallySelectedUserIds);

  const onCheckboxChange = (userId, isSelected) => {
    if (isSelected) {
      setSelectedUserIds(selectedUserIds.concat(userId));
      onUserIdsSelection(selectedUserIds.concat(userId));
    } else {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
      onUserIdsSelection(selectedUserIds.filter((id) => id !== userId));
    }
  };

  const userRows = users.map((user) => {
    return (
      <div key={user.id}>
        <div className="user-selector-item">
          <div className="checkbox">
            <Checkbox
              isInitiallyChecked={initiallySelectedUserIds.includes(user.id)}
              onChange={(isChecked) => onCheckboxChange(user.id, isChecked)}
            />
          </div>
          <div className="avatar">
            <img src={user.gravatar} alt="user profile" />
          </div>
          <div className="labels">
            <div className="name">{user.fullName}</div>
            <div className="email">{user.email}</div>
          </div>
        </div>
        <div className="separator" />
      </div>
    );
  });

  return (
    <div className="user-selector-container">
      <div className="separator" />
      <div>{userRows}</div>
      <input type="hidden" name={name} value={JSON.stringify(selectedUserIds)} />
    </div>
  );
}

export default UserSelector;
