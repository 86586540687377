import { useCallback } from 'react';

import handlers from '../message-handlers';
import { isEmbedContext, isIncommingEvent, parseIncommingAction } from '../utils';

function useIncomingMessageHandler({ stateRef, iframeRef, id, context }) {
  return useCallback(
    (msgEvt) => {
      if (!isIncommingEvent(msgEvt)) {
        return;
      }

      const { message, trackingProperties } = msgEvt.data;
      const action = parseIncommingAction(message);

      const sendResponse = (response) => {
        iframeRef.current.contentWindow.postMessage(
          {
            response,
            trackingProperties,
          },
          '*'
        );
      };

      const state = stateRef.current;
      const canWrite = !isEmbedContext(context) && state.flow?.permissions?.access === 'write';
      const iframe = iframeRef.current;
      const sendSuccess = (success) => () => sendResponse({ success });

      const handler = handlers[action];

      if (handler) {
        const request = { props: { id, context }, state, canWrite, message, iframe };
        const response = { sendSuccess, sendResponse };

        handler(request, response);
      } else {
        // eslint-disable-next-line no-console
        console.error('unknown action', action);
      }
    },
    [id, context, stateRef, iframeRef]
  );
}

export default useIncomingMessageHandler;
