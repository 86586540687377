import { useState, useEffect } from 'react';

import { useAuth } from '../../../contexts/AuthContext';

const initialPermissions = {
  canResolveComment: false,
  canChangeTypeComment: false,
  canEditComment: false,
  canDeleteComment: false,
};

const isEditableComment = (user, comment) => {
  if (user && user.is_current_team_admin) {
    return true;
  }

  return user && comment && user.email === comment.author_email;
};

const isResolvableComment = (user, comment) => {
  if (user && user.is_current_team_member) {
    return true;
  }

  return user && comment && user.email === comment.author_email;
};

const WithPermissions = ({ children, comment }) => {
  const { user } = useAuth();

  const [permissions, setPermissions] = useState({ ...initialPermissions });

  useEffect(() => {
    if (user) {
      const canEditComment = isEditableComment(user, comment);
      const canResolveComment = isResolvableComment(user, comment);

      setPermissions({
        canResolveComment,
        canChangeTypeComment: canResolveComment,
        canEditComment,
        canDeleteComment: canEditComment,
      });
    } else {
      setPermissions({ ...initialPermissions });
    }
  }, [user, comment]);

  return children(permissions);
};

export default WithPermissions;
