/* global treeState */
import { useState, useEffect } from 'react';

export const useNodes = () => {
  const [nodes, setNodes] = useState(treeState.nodes);

  useEffect(() => {
    const onTreeStateChanged = () => {
      setNodes(treeState.nodes);
    };

    document.addEventListener('treeStateChanged', onTreeStateChanged);

    return () => {
      document.removeEventListener('treeStateChanged', onTreeStateChanged);
    };
  }, []);

  return nodes;
};

export default useNodes;
