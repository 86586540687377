import styled from 'styled-components';

const Container = styled.div.attrs({ className: 'upload' })`
  position: relative;

  .progress {
    font-size: 0.75rem;
    height: 1.25rem;
  }

  .abort {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  :hover {
    .abort {
      display: flex;
    }
  }
`;

export function UploadProgressBar({ progress, abort, className }) {
  return (
    <Container className={className}>
      <div className="progress shadow">
        <div className="progress-bar text-dark font-weight-bold text-right pl-1 pr-3" style={{ width: `${progress}%` }}>
          {progress}%
        </div>
      </div>
      {abort && <div className="abort align-items-center">{abort}</div>}
    </Container>
  );
}

export default UploadProgressBar;
