export function keywordsToString(value) {
  if (!value) {
    return '';
  }

  return Array.isArray(value) ? value.join(',') : value;
}

export function stringToKeywords(value) {
  if (!value) {
    return [];
  }

  return Array.isArray(value) ? value : value.split(',');
}
