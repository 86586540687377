import styled from 'styled-components';
import Gravatar from 'react-gravatar';

import { Pointer, PointerContainer } from './List.styles';
import { humanCommentText } from '../Comment/helpers';
import { absoluteThumbUrl } from '../../../sitemap/utils';

const CommentContainer = styled.div`
  cursor: pointer;
`;

const SinglePage = ({ page, sitemap, teamMembers, onCommentClick }) => {
  const { moment } = window;
  const TODAY = moment().clone().startOf('d');

  const comment = page.comments[page.comments.length - 1];
  const users = [...new Set(page.comments.map((i) => i.author_name))];
  const avatars = [...new Set(page.comments.map((i) => i.author_email))];

  const isToday = (date) => date.isSame(TODAY, 'd');
  const imgUrl = absoluteThumbUrl(sitemap.screenshot);

  return (
    <CommentContainer
      className={`comment__content ${!comment.read ? 'isUnread' : ''}`}
      onClick={() => onCommentClick(sitemap, page.commentNumber)}
    >
      <div className="comment__img" style={{ backgroundImage: `url(${imgUrl})` }}>
        <PointerContainer>
          <Pointer $isResolved={comment.resolved}>{page.commentNumber}</Pointer>
        </PointerContainer>
      </div>

      <div className="comment__preview">
        <p>{humanCommentText(comment.text, teamMembers)}</p>

        <div className="comment__users-and-date">
          <span className="users">
            {users.map((user) => `${user} `)} ({users.length})
          </span>
        </div>

        <div className="comment__avatars-and-date">
          <div className="comment__avatars">
            {avatars.map((avatar) => (
              <Gravatar key={avatar} email={avatar} />
            ))}
          </div>
          <span className="date">
            {isToday(moment(comment.created_at)) ? 'Today' : moment(comment.created_at).format('MMM DD')}
          </span>
        </div>
      </div>
    </CommentContainer>
  );
};

export default SinglePage;
