import styled from 'styled-components';

import { deleteComment } from '../api/request';
import { CommentButton as Button } from './styles';
import { useAuth } from '../../../contexts/AuthContext';

const DeleteWrapper = styled.div`
  text-align: center;
`;

const DeleteComment = styled.p`
  padding: 1rem 0;
  color: #6b6b6b;
`;

const DeleteMessage = styled.span`
  color: red;
`;

const DeleteCommentComponent = ({ comment, isRoot, onCancel, onCommentDeleted, onThreadDeleted }) => {
  const { user } = useAuth();

  const onDeleteClick = () => {
    deleteComment(comment.id, user).then(() => {
      isRoot ? onThreadDeleted() : onCommentDeleted(comment.id);
    });
  };

  return (
    <DeleteWrapper>
      <DeleteComment>
        {isRoot ? (
          <DeleteMessage>
            <i className="icon-feather-alert-triangle" />
            &nbsp;Delete the entire conversation?!
          </DeleteMessage>
        ) : (
          'Delete this comment?'
        )}
      </DeleteComment>
      <div>
        <Button onClick={onDeleteClick} style={{ marginRight: '1rem' }} type="button">
          Yes, delete
        </Button>
        <Button $light onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </DeleteWrapper>
  );
};

export default DeleteCommentComponent;
