/* global dataConfirmModal */
import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { deleteTag } from './api/request';
import { Box } from './Box';
import { TagList } from './TagList';
import { TagForm, defaultColor } from './TagForm';
import { useEventCallbackRef } from '../../hooks/useEventCallback';

/**
 * Display a list of tags with add/edit/remove capabilities
 */
export const EditableTagList = ({ tags, editable, onCreate, onUpdate, onDelete, onLayoutChange, ...listProps }) => {
  const [editTag, setEditTag] = useState(null);
  const layoutChangeCallbackRef = useEventCallbackRef(onLayoutChange);

  const handleDelete = async (tag) => {
    const tagName = tag.name.length > 30 ? `${tag.name.substring(0, 30)}…` : tag.name;
    dataConfirmModal.confirm({
      title: 'Tag Deletion... You Sure?',
      text: `You're about to delete tag '${tagName}'. This action can't be undone and will affect every other sitemap that uses this tag in your workspace.`,
      commit: 'Delete',
      commitClass: 'btn btn-danger',
      focus: 'commit',
      onConfirm: async () => {
        await deleteTag(tag.id);

        onDelete(tag);
      },
      onCancel: () => {},
    });
  };

  // Notify parent that we switched from list to form (or the other way around).
  // This is usefull in case the component is inside a popover.
  useEffect(() => {
    const { current: callback } = layoutChangeCallbackRef;

    callback && callback();
  }, [editTag, tags.length, layoutChangeCallbackRef]);

  const handleFormSubmit = (callback) => (tag) => {
    callback(tag);
    setEditTag(null);
  };

  const addTagClasses = classNames('btn btn-sm btn-block text-capitalize shadow-none btn-outline-dark-gray', {
    'mt-3': tags.length > 0,
  });

  return (
    <>
      {!editTag && (
        <Box className="py-3 px-0">
          <TagList tags={tags} editable={editable} onEdit={setEditTag} onDelete={handleDelete} {...listProps} />
          {editable && (
            <div className="px-3">
              <button
                type="button"
                className={addTagClasses}
                onClick={() => setEditTag({ color: defaultColor, name: 'New Tag' })}
              >
                Create New +
              </button>
            </div>
          )}
        </Box>
      )}
      {editTag && (
        <TagForm
          tag={editTag}
          onClose={() => setEditTag(null)}
          onUpdate={handleFormSubmit(onUpdate)}
          onCreate={handleFormSubmit(onCreate)}
        />
      )}
    </>
  );
};

export default EditableTagList;
