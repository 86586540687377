import { useEffect, useState, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Gravatar from 'react-gravatar';

import { projectMember } from './helpers';

const Container = styled.div`
  width: 360px;
  max-width: 100%;
  height: 300px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
`;

const Section = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 1rem;
  color: #838792;
  font-size: 12px;

  ${({ top }) =>
    top &&
    css`
      border-top: 1px solid #eee;
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      border-bottom: 1px solid #eee;
    `}
`;

const Users = styled.div`
  height: 100%;
  overflow-y: auto;

  ul {
    list-style: none;
  }
`;

const User = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  height: 75px;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 0;

  &:hover {
    background-color: #ecf0f4;
  }

  &.active {
    background-color: #ecf0f4;
  }

  .img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 1rem;
    margin-left: 1rem;
  }
`;

const Username = styled.p`
  font-weight: 600;
  color: #4c5363;
  line-height: normal;
  margin: 0;
`;

const Email = styled.span`
  color: #aab1bf;
  display: block;
  line-height: normal;
  margin-top: 5px;
`;

const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: #838792;
    font-size: 1rem;
  }
`;

function Mentions({ teamMembers, onMemberSelected, parentRef, text, style }) {
  const [currentSelection, setCurrentSelection] = useState(0);

  const filteredMembers = useMemo(() => {
    const allMembers = teamMembers.concat([projectMember]);

    if (!text) {
      return allMembers;
    }

    return allMembers.filter((user) => user && user.name && user.name.toLowerCase().indexOf(text) !== -1);
  }, [teamMembers, text]);

  const handleKeyDown = useCallback(
    (event) => {
      const { which } = event;
      if (filteredMembers.length === 0) {
        setCurrentSelection(0);
        return;
      }

      // Stop right/left arrows
      if (which === 37 || which === 39) {
        event.preventDefault();
        event.stopPropagation();
      }

      // Arrow down
      if (which === 40) {
        event.preventDefault();
        setCurrentSelection((index) => (index + 1) % filteredMembers.length);
      }

      // Arrow up
      if (which === 38) {
        event.preventDefault();
        setCurrentSelection((index) => (index - 1) % filteredMembers.length);
      }

      // Enter
      if (which === 13) {
        event.preventDefault();
        const member = filteredMembers[currentSelection];
        if (member) {
          onMemberSelected(member);
        }
      }
    },
    [currentSelection, setCurrentSelection, filteredMembers, onMemberSelected]
  );

  useEffect(() => {
    const { current } = parentRef;

    if (current) {
      current.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (current) {
        current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [parentRef, handleKeyDown]);

  return (
    <Container style={style}>
      <Section bottom>
        <span>Mention someone by name</span>
      </Section>
      <Users>
        {filteredMembers.length > 0 ? (
          filteredMembers.map((member, index) => (
            <User
              key={member.email}
              onClick={() => onMemberSelected(member)}
              className={currentSelection === index ? 'active' : ''}
            >
              <Gravatar email={member.email} className="img-responsive img" />

              <div>
                <Username>{member.name}</Username>
                <Email>{member.email}</Email>
              </div>
            </User>
          ))
        ) : (
          <NotFoundContainer>
            <p>No users found.</p>
          </NotFoundContainer>
        )}
      </Users>
      <Section top>
        <span>
          <b>@project</b>
          &nbsp;will notify everyone collaborating on this project
        </span>
      </Section>
    </Container>
  );
}

export default Mentions;
