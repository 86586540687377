import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';

import useTooltip from '../../hooks/useTooltip';

const Ellipsis = styled.div`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0px;
    animation: ellipsis steps(4, end) 1s infinite;
    margin-left: 5px;
    margin-right: 0.8em;
  }

  @keyframes ellipsis {
    to {
      margin-right: 0;
      width: 0.8em; /* Ellipsis is 0.6em width. Animations use an extra "invisible dot". So 0.8 = 0.2 (dot width) * 4 (dots) */
    }
  }
`;

const ResultMessage = ({ success, withColor = false }) => {
  const ref = useRef();

  const message = success ? 'Saved' : 'Error';
  const icon = success ? 'fa-check' : 'fa-times';
  const title = success ? 'All changes saved to Cloud' : 'Some changes could not be saved to Cloud';

  let color = '';
  if (withColor) {
    color = success ? 'text-primary' : 'text-danger';
  }

  useTooltip(ref, [title]);

  return (
    <div ref={ref} className={`${color} d-inline-flex align-items-center`} title={title}>
      {message}
      <i className={`fas ${icon} ml-2`} />
    </div>
  );
};

const LastSaveElapsedTime = ({ timestamp, className }) => {
  const ref = useRef();

  useTooltip(ref, [timestamp]);

  return (
    <div className={className} ref={ref}>
      Last edit was <Moment date={timestamp} fromNow withTitle titleFormat="MMM D, YYYY [at] H:mma" />.
    </div>
  );
};

export default function SavingIndicator({ lastEdition }) {
  const [timestamp, setTimestamp] = useState(lastEdition);
  const [state, setState] = useState('idle');
  const [success, setSuccess] = useState(true);

  // Listen to save events and change internal state
  useEffect(() => {
    const startHandler = () => setState('saving');
    const doneHandler = () => {
      setSuccess(true);
      setTimestamp(new Date());
      setState('finishing');
    };
    const errorHandler = () => {
      setSuccess(false);
      setState('finishing');
    };

    // Not as accurate as it should be but we don't have sitemap tree state updated at info right now
    const updateTimestamp = () => setTimestamp(new Date());

    document.addEventListener('treeAutosaveStart', startHandler);
    document.addEventListener('treeAutosaveSuccess', doneHandler);
    document.addEventListener('treeAutosaveFail', errorHandler);
    document.addEventListener('treeManualsaveSuccess', doneHandler);
    document.addEventListener('refreshTreeState', updateTimestamp);

    return () => {
      document.removeEventListener('treeAutosaveStart', startHandler);
      document.removeEventListener('treeAutosaveSuccess', doneHandler);
      document.removeEventListener('treeAutosaveFail', errorHandler);
      document.removeEventListener('treeManualsaveSuccess', doneHandler);
      document.removeEventListener('refreshTreeState', updateTimestamp);
    };
  }, []);

  // Finishing should stay there for 3 seconds
  useEffect(() => {
    if (state === 'finishing') {
      // Wait 3 seconds, then move to idle
      const timer = setTimeout(() => setState('idle'), 3000);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [state]);

  return (
    <div className="saving-indicator">
      {state === 'saving' && <Ellipsis>Saving</Ellipsis>}
      {state === 'finishing' && <ResultMessage success={success} withColor />}
      {state === 'idle' && timestamp && (
        <div className="text-right">
          <ResultMessage success={success} />
          <LastSaveElapsedTime className="timestamp mt-1" timestamp={timestamp} />
        </div>
      )}
    </div>
  );
}
