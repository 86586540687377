export function AbortUploadButton(props) {
  return (
    <button
      type="button"
      className="btn btn-link text-danger text-decoration-none pt-0 pb-0"
      title="Click to cancel upload"
      {...props}
    >
      <i className="fas fa-times-circle" />
    </button>
  );
}

export default AbortUploadButton;
