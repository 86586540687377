import { useState, useLayoutEffect, useEffect, useCallback } from 'react';

import StyledCheckbox from './StyledCheckbox';

function BulkActionsCheckbox({ eventsPrefix, recordId, canMove, canDelete }) {
  const [isChecked, setIsChecked] = useState(false);

  const notifySelection = useCallback(
    (checked, detail) => {
      if (checked) {
        document.dispatchEvent(new CustomEvent(`${eventsPrefix}/SelectedForBulkAction`, { detail }));
      } else {
        document.dispatchEvent(new CustomEvent(`${eventsPrefix}/DeselectedForBulkAction`, { detail }));
      }
    },
    [eventsPrefix]
  );

  useEffect(() => {
    document.dispatchEvent(new CustomEvent(`${eventsPrefix}/BulkActionSyncRequired`));
  }, [eventsPrefix]);

  const onClick = (event) => {
    event.stopPropagation();
    setIsChecked(!isChecked);
    notifySelection(!isChecked, { recordId, canMove, canDelete });
  };

  // Register listener ASAP. Ajax pagination triggers a state sync. We need to make sure that
  // all listeners are registered before it does it.
  useLayoutEffect(() => {
    const reset = ({ detail }) => {
      const checked = detail !== null && detail.length > 0 && detail.indexOf(recordId) >= 0;
      if (checked) {
        setIsChecked(true);
        // Notify selection to update permissions in case they changed
        notifySelection(true, { recordId, canMove, canDelete });
      } else {
        setIsChecked(false);
      }
    };
    const eventName = `${eventsPrefix}/resetBulkActionCheckboxes`;
    document.addEventListener(eventName, reset);

    return () => {
      document.removeEventListener(eventName, reset);
    };
  }, [recordId, canDelete, canMove, notifySelection, eventsPrefix]);

  return (
    <div className="text-center">
      <StyledCheckbox $isChecked={isChecked} onClick={onClick} />
    </div>
  );
}

export default BulkActionsCheckbox;
