import styled from 'styled-components';
import classNames from 'classnames';

import { Badge } from '../Badge';

const ItemContainer = styled.div.attrs({
  className: 'd-flex align-items-center',
})`
  cursor: pointer;
  padding-right: 0.75rem;

  .btn-action {
    visibility: hidden;
  }

  &:hover .btn-action {
    visibility: visible;
  }

  &:hover {
    background-color: rgba(162, 162, 162, 0.2);
  }

  &.disabled {
    opacity: 0.3;
  }
`;

export const TagListItem = ({ tag, editable, selected, disabled, className, onEdit, onDelete, onClick }) => {
  const delegateToListener = (listener) => (e) => {
    e.stopPropagation();
    listener(tag);
  };

  const handleClick = () => {
    if (!disabled) {
      onClick(tag);
    }
  };

  const containerClasses = classNames(className, { disabled });
  const buttonClasses = classNames('btn btn-link p-0 ml-2', { 'btn-link-muted': !selected });
  const iconClasses = classNames('far', { 'fa-eye': selected, 'fa-eye-slash': !selected });
  const iconLabel = selected ? 'unselect the tag' : 'select the tag';

  return (
    <ItemContainer className={containerClasses} onClick={delegateToListener(handleClick)}>
      <Badge color={tag.color}>{tag.name}</Badge>
      <button type="button" className={buttonClasses} aria-label={iconLabel}>
        <i className={iconClasses} />
      </button>
      {editable && (
        <div className="d-inline-block ml-auto pl-3 text-nowrap">
          <button
            type="button"
            className="btn btn-link btn-link-secondary btn-action p-0 mx-2"
            title="click to edit the tag"
            aria-label="click to edit the tag"
            onClick={delegateToListener(onEdit)}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-link btn-link-secondary btn-action p-0 ml-0"
            aria-label="click to delete the tag"
            title="click to delete the tag"
            onClick={delegateToListener(onDelete)}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      )}
    </ItemContainer>
  );
};

export default TagListItem;
