import loaderImage from './loader-image.svg';

function Loader({ width, height, show }) {
  const containerStyle = {
    width,
    height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div style={show ? containerStyle : { display: 'none' }}>
      <object
        type="image/svg+xml"
        style={{
          width: '200px',
          height: '200px',
        }}
        data={loaderImage}
        alt="Loader"
        aria-label="Loader"
      />
    </div>
  );
}

export default Loader;
