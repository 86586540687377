import fileChecksum from './file-checksum';
import { post } from './request';

export default async function fetchPresignedUrl(file) {
  const blob = {
    byte_size: file.size,
    content_type: file.type,
    filename: file.name,
    checksum: await fileChecksum(file),
  };

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': document.head.querySelector(`meta[name="csrf-token"]`).getAttribute('content'),
  };

  return post('/rails/active_storage/direct_uploads', { headers, data: { blob } });
}
