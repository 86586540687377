import * as Yup from 'yup';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames';

import { useFeatures } from '../../contexts/FeaturesContext';
import Spinnner from '../shared/Spinner';
import Input from './form/Input';
import { SitemapPage } from '../sitemap-page-content/PageContentEditor/SitemapPage';
import FileInput from './form/FileInput';
import TagInput from './form/TagInput';
import FileList from './form/FileList';
import AutoSave from './AutoSave';
import { keywordsToString } from './utils';
import ResetFormOnChange from './ResetFormOnChange';
import EditionGuard from './EditionGuard';
import WarningForSimultaneousEdition from './WarningForSimultaneousEdition';
import Section from './Section';
import RichTextInput from './form/RichTextInput';
import Gdocs from './form/Gdocs';

const SitemapSection = styled.div`
  display: flex;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const FileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 121px);
  grid-gap: 1rem;
`;

const ContentSchema = Yup.object().shape({
  content: Yup.string().nullable(),
  new_files: Yup.array(),
  new_gdocs: Yup.array(),
  keywords: Yup.string().nullable(),
  h1: Yup.string().nullable(),
  h2: Yup.string().nullable(),
  meta_description: Yup.string().nullable(),
  notes: Yup.string().nullable(),
});

const toInitialValue = (data) => {
  const values = data || {};

  return {
    content: values.content ?? '',
    files: values.files ?? [],
    gdocs: values.gdocs ?? [],
    new_files: values.new_files ?? [],
    new_gdocs: values.new_gdocs ?? [],
    remove_files: values.remove_files ?? [],
    remove_gdocs: values.remove_gdocs ?? [],
    keywords: keywordsToString(values.keywords ?? []),
    h1: values.h1 ?? '',
    h2: values.h2 ?? '',
    meta_description: values.meta_description ?? '',
    notes: values.notes ?? '',
  };
};

export default function ContentForm({ node, onSave, onFileUpload, data, isInitialLoading, small = false }) {
  const { contentEditionEnabled, pageEditionEnabled } = useFeatures();

  if (isInitialLoading) {
    return (
      <SpinnerContainer>
        <Spinnner />
      </SpinnerContainer>
    );
  }

  const initialValue = toInitialValue(data);

  const { files, gdocs } = initialValue;

  const disabled = !contentEditionEnabled;

  return (
    <Formik initialValues={initialValue} validationSchema={ContentSchema}>
      <Form>
        <ResetFormOnChange data={data} />
        {!disabled && <AutoSave onSave={onSave} />}

        <WarningForSimultaneousEdition isSitemap={!node} />

        {node && (
          <SitemapSection>
            <SitemapPage node={node} readonly={!pageEditionEnabled} small={small} />
          </SitemapSection>
        )}

        <hr />

        <EditionGuard disabled={disabled}>
          <Section title={node ? 'Content Brief' : null}>
            <div className="row">
              <Input
                as={RichTextInput}
                name="content"
                disabled={disabled}
                minHeight={400}
                maxHeight={800}
                onUpload={onFileUpload}
              />
            </div>
          </Section>

          <hr />

          <Section title="Google Docs">
            <Gdocs gdocs={gdocs} disabled={disabled} />
          </Section>

          <hr />

          <Section title="Files" className={classNames({ 'mb-4': !node })}>
            <FileGrid>
              <Field as={FileList} name="remove_files" files={files} disabled={disabled} />
              <Field
                as={FileInput}
                name="new_files"
                disabled={disabled}
                constraintParams={{ files, removedField: 'remove_files' }}
              />
            </FileGrid>
          </Section>

          {node && (
            <>
              <hr />
              <Section title="Seo Metadata">
                <div className="row">
                  <Input
                    as={TagInput}
                    title="Keywords"
                    name="keywords"
                    autoComplete="off"
                    placeholder="Add keywords separated by comma or Enter"
                    disabled={disabled}
                  />
                  <Input title="H1" name="h1" autoComplete="off" disabled={disabled} />
                </div>
                <div className="row">
                  <Input title="Meta Description" name="meta_description" as="textarea" rows="2" disabled={disabled} />
                  <Input title="H2" name="h2" autoComplete="off" disabled={disabled} />
                </div>
                <div className="row">
                  <Input title="Internal Notes" name="notes" as="textarea" rows="3" disabled={disabled} />
                </div>
              </Section>
            </>
          )}
        </EditionGuard>
      </Form>
    </Formik>
  );
}
