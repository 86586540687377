/* global App */
import { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from '../../contexts/AuthContext';

export const PresenceContext = createContext({ sitemapId: null, pageId: null });

export function PresenceProvider({ children, sitemapId, pageId }) {
  const { user } = useAuth();
  const [value, setValue] = useState({ usersCount: 0 });

  useEffect(() => {
    let channel;
    if (sitemapId && pageId && user?.random_uuid) {
      channel = App.createSitemapContentsPresenceSubscription(sitemapId, pageId, user.random_uuid);
    } else if (sitemapId && user?.random_uuid) {
      channel = App.createSitemapContentsPresenceSubscription(sitemapId, null, user.random_uuid);
    }

    return () => {
      if (channel) {
        channel.presence(false);
        channel.unsubscribe();
      }
    };
  }, [pageId, sitemapId, user]);

  useEffect(() => {
    const onPresenceChange = ({ detail }) => {
      setValue({ usersCount: detail.size });
    };

    document.addEventListener('page/ContentsPresenceChanges', onPresenceChange);
    document.addEventListener('sitemap/ContentsPresenceChanges', onPresenceChange);

    return () => {
      document.removeEventListener('page/ContentsPresenceChanges', onPresenceChange);
      document.removeEventListener('sitemap/ContentsPresenceChanges', onPresenceChange);
    };
  }, []);

  return <PresenceContext.Provider value={value}>{children}</PresenceContext.Provider>;
}

export function usePresence() {
  const ctx = useContext(PresenceContext);

  if (ctx === undefined) {
    throw new Error('usePresence must be used within a PresenceProvider');
  }

  return ctx;
}
