const accuracy = 10000;

export const relativePositionFromRect = ({ x, y }, rect) => {
  const percentajeX = (x * 100) / rect.width;
  const percentajeY = (y * 100) / rect.height;

  return {
    x: parseInt(percentajeX * accuracy, 10),
    y: parseInt(percentajeY * accuracy, 10),
  };
};

export const relativePosition = ({ x, y }, container) => {
  const rect = container.getBoundingClientRect();

  return relativePositionFromRect({ x, y }, rect);
};

export const relativePositionFromEvent = ({ clientX, clientY }, container) => {
  const rect = container.getBoundingClientRect();
  const x = clientX - rect.x;
  const y = clientY - rect.y;

  return relativePosition({ x, y }, container);
};

export const absolutePosition = ({ x, y }, container) => {
  const rect = container.getBoundingClientRect();

  return {
    x: (x / accuracy / 100) * rect.width,
    y: (y / accuracy / 100) * rect.height,
  };
};

export const projectMember = { id: 'project', name: 'project', email: '@project' };

export const humanCommentText = (text, users) => {
  if (!text) {
    return '';
  }

  const allUsers = (users || []).concat([projectMember]);
  let newText = text;

  allUsers.forEach((user) => {
    const regex = new RegExp(`<@${user.id}>`, 'g');

    newText = newText.replace(regex, `@${user.name}`);
  });

  return newText;
};

export const richCommentText = (text, users) => {
  if (!text) {
    return '';
  }

  const allUsers = (users || []).concat([projectMember]);
  let formattedText = text.replace(/\n/g, '<br />');

  allUsers.forEach((user) => {
    const regex = new RegExp(`<@${user.id}>`, 'g');

    formattedText = formattedText.replace(regex, `<button type="button">@${user.name}</button>`);
  });

  return formattedText;
};

export const rawCommentText = (text, users) => {
  if (!text) {
    return '';
  }

  const allUsers = (users || []).concat([projectMember]);
  let newText = text;

  allUsers.forEach((user) => {
    const regex = new RegExp(`@${user.name}`, 'g');

    newText = newText.replace(regex, `<@${user.id}>`);
  });

  return newText;
};
