import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { changeCommentType } from '../api/request';
import { useAuth } from '../../../contexts/AuthContext';

const Select = styled.div`
  margin-left: auto;
  font-weight: 600;
  position: relative;
  height: 100%;
  min-height: 35px;
  display: flex;
  align-items: center;
`;

const Caret = styled.span`
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top: 4px solid #aeaeae;
  margin-left: 1rem;
`;

const Option = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
`;

const OptionsList = styled.ul`
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 136px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }
`;

const Button = styled.button`
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: left;
  transition: all 0.4s ease;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Bullet = styled.span`
  width: 9px;
  height: 9px;
  background-color: ${({ color }) => color};
  display: inline-block;
  border-radius: 9px;
  margin-right: 1rem;
  margin-left: 1rem;
`;

const options = [
  {
    name: 'Public',
    key: 'public',
    color: '#20BBFF',
  },
  {
    name: 'Private',
    key: 'private',
    color: '#FF9900',
  },
];

const optionsForUser = (user) => {
  if (!user?.is_current_team_member) {
    return options.filter((opt) => opt.key !== 'private');
  }

  return options;
};

function SelectStatus({ comment, readOnly, handleSetComment, onChange }) {
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const selectedOption = options.find((o) => comment && o.key === comment.comment_type);
  const [selected, setSelected] = useState(selectedOption || options[0]);

  const userOptions = optionsForUser(user);

  const onUpdateCommentType = (option) => () => {
    const changeStatus = comment && comment.id ? changeCommentType(comment.id, option.key, user) : Promise.resolve();

    changeStatus
      .then((updatedComment) => {
        if (updatedComment) {
          handleSetComment(updatedComment);
        } else {
          onChange(option.key);
        }
        setSelected(option);
      })
      .finally(() => setIsOpen(false));
  };

  const handleClickOutside = (event) => {
    if (menuRef && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const option = options.find((o) => comment && o.key === comment.comment_type);
    setSelected(option || options[0]);
  }, [comment]);

  const onClick = () => {
    if (readOnly || userOptions.length === 1) {
      return;
    }

    setIsOpen(true);
  };

  return (
    <Select ref={menuRef}>
      <Option onClick={onClick}>
        <Bullet color={selected.color} />

        {selected.name}

        {!readOnly && userOptions.length > 1 && <Caret />}
      </Option>

      {isOpen && (
        <OptionsList>
          {userOptions.map((option) => {
            return (
              <li key={option.key} className={`${option.key} ${selected === option.key ? 'selected' : ''}`}>
                <Button onClick={onUpdateCommentType(option)}>
                  <Bullet color={option.color} />

                  {option.name}
                </Button>
              </li>
            );
          })}
        </OptionsList>
      )}
    </Select>
  );
}

export default SelectStatus;
