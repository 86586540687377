/* global $ */
import { useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import ExpandCollapseOption from './ExpandCollapseOption';
import SitemapPagesSearchBar from '../sitemap-pages-search/SitemapPagesSearchBar';
import NodeFilter from './SitemapViewOptions/NodeFilter';
import { useIsOrganizedByPath } from './hooks/useIsOrganizedByPath';
import SwitchTreeViewOption from './SitemapViewOptions/SwitchTreeViewOption';
import ToggleComments from './SitemapViewOptions/ToggleComments';
import OpenSitemapContent from './OpenSitemapContent';
import { useSitemapTreeMode } from '../../stores/sitemap-tree-mode';

const titles = {
  organization: {
    [true]: 'Organize by /directory/. Click to switch to organize by first-found referral',
    [false]: 'Organize by first-found referral. Click to switch to organize by /directory/',
  },
};

const OptionsBar = styled.div`
  display: flex;
  gap: 1.2rem;
  justify-content: flex-end;
  border: 1px solid #97979729;
  border-radius: 11px;
  background-color: #73737329;
  margin-top: 1.5rem;
  margin-right: calc(3rem - 10px);
  padding: 0px 8px;
  height: 40px;

  .search-container {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    min-width: 30px;
    #pages-search-bar {
      margin-left: 0;
    }
  }
`;

const StyledItem = styled.div``;

function OptionItem({ children, className, renderIf = true, hide = false }) {
  if (!renderIf) {
    return null;
  }

  return (
    <StyledItem
      className={classNames(className, {
        'd-none': hide,
        'd-inline-flex': !hide,
      })}
    >
      {children}
    </StyledItem>
  );
}

export default function SitemapViewOptions({
  isInitiallyVertical = true,
  sitemapId,
  tagEditionEnabled,
  organizeTreeEnabled,
  pageContentEnabled,
  commentSitemapEnabled,
  sitemapEditionEnabled,
}) {
  const isOrganizedByPath = useIsOrganizedByPath();
  const { setMode, isGridView } = useSitemapTreeMode();

  useEffect(() => {
    setMode(isInitiallyVertical ? 'vertical' : 'horizontal');
  }, [isInitiallyVertical, setMode]);

  const onToggleOrganization = (event) => {
    event.preventDefault();
    document.dispatchEvent(new CustomEvent('treeReorganize', { detail: { organizedByPath: !isOrganizedByPath } }));
  };

  useEffect(() => {
    if (isGridView) {
      $('#tree-container, #sitemap-footer').addClass('d-none');
    } else {
      $('#tree-container, #sitemap-footer').removeClass('d-none');
    }
  }, [isGridView]);

  return (
    <OptionsBar>
      <OptionItem renderIf={tagEditionEnabled || pageContentEnabled || commentSitemapEnabled}>
        <NodeFilter
          sitemapId={sitemapId}
          tagEditionEnabled={tagEditionEnabled}
          pageContentEnabled={pageContentEnabled}
          commentSitemapEnabled={commentSitemapEnabled}
          sitemapEditionEnabled={sitemapEditionEnabled}
        />
      </OptionItem>

      <OptionItem
        renderIf={
          pageContentEnabled || Boolean(window.pageContentEditionWarning) || Boolean(window.pageContentAccessWarning)
        }
      >
        <OpenSitemapContent />
      </OptionItem>

      <OptionItem renderIf={commentSitemapEnabled || Boolean(window.commentsWarning)}>
        <ToggleComments />
      </OptionItem>

      <OptionItem>
        <SwitchTreeViewOption />
      </OptionItem>

      <OptionItem renderIf={organizeTreeEnabled} hide={isGridView}>
        <button
          type="button"
          id="organization-toggle"
          className={classNames('btn btn-link button-icon', { path: isOrganizedByPath, ref: !isOrganizedByPath })}
          title={titles.organization[isOrganizedByPath]}
          aria-label={titles.organization[isOrganizedByPath]}
          onClick={onToggleOrganization}
        />
      </OptionItem>

      <OptionItem hide={isGridView}>
        <ExpandCollapseOption />
      </OptionItem>

      <OptionItem>
        <SitemapPagesSearchBar />
      </OptionItem>
    </OptionsBar>
  );
}
