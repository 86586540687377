import styled from 'styled-components';

const UploadContainer = styled.div`
  position: absolute;
  top: 75%;
  transform: translateY(-50%);
  left: 0.75rem;
  right: 0.75rem;
`;

export default UploadContainer;
