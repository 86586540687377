/* global toastr */
import { useState, useEffect } from 'react';

import similarityLevel from './similarityLevel';
import { flagPage, unflagPage, resolvePage } from './request';

const pageStatuses = ['PRISTINE', 'FLAGGED', 'RESOLVED'];

function PageButtons({
  className = '',
  page,
  onExpand = () => {},
  onShrink = () => {},
  showExpandButton = true,
  flaggingEnabled = false,
  flagNotificationUserIds,
  openFlagSettings,
  sitemapComparisonId,
}) {
  const [pageStatus, setPageStatus] = useState(pageStatuses.includes(page.status) ? page.status : 'PRISTINE');
  const [flaggingButtonsEnabled, setFlaggingButtonsEnabled] = useState(true);

  useEffect(() => {
    if (pageStatuses.includes(page.status)) {
      setPageStatus(page.status);
    }
  }, [page.status]);

  const markPageAs = (status) => {
    if (!flagNotificationUserIds) {
      openFlagSettings();
      return;
    }

    let apiCall;
    switch (status) {
      case 'FLAGGED':
        apiCall = flagPage;
        break;
      case 'RESOLVED':
        apiCall = resolvePage;
        break;
      case 'PRISTINE':
      default:
        apiCall = unflagPage;
        break;
    }

    setFlaggingButtonsEnabled(false);
    apiCall(sitemapComparisonId, page.url)
      .then(() => {
        setPageStatus(status);
      })
      .catch(() => {
        toastr.error('Sorry, something went wrong. Please try again later');
      })
      .finally(() => {
        setFlaggingButtonsEnabled(true);
      });
  };

  const flag = (event) => {
    event.preventDefault();
    markPageAs('FLAGGED');
  };

  const unflag = (event) => {
    event.preventDefault();
    markPageAs('PRISTINE');
  };

  const resolve = (event) => {
    event.preventDefault();
    markPageAs('RESOLVED');
  };

  const flagButton = flaggingEnabled && (pageStatus === 'PRISTINE' || pageStatus === 'RESOLVED') && (
    <button
      className="btn btn-muted coral-red btn-wide"
      type="button"
      onClick={flag}
      disabled={!flaggingButtonsEnabled}
    >
      Flag
    </button>
  );
  const flaggedButton = flaggingEnabled && pageStatus === 'FLAGGED' && (
    <button
      className="btn btn-primary coral-red btn-wide"
      type="button"
      onClick={unflag}
      disabled={!flaggingButtonsEnabled}
    >
      Flagged
    </button>
  );
  const resolveButton = flaggingEnabled && pageStatus === 'FLAGGED' && (
    <button className="btn btn-muted btn-wide gray" type="button" onClick={resolve} disabled={!flaggingButtonsEnabled}>
      Resolve
    </button>
  );
  const resolvedButton = flaggingEnabled && pageStatus === 'RESOLVED' && (
    <button className="btn btn-primary btn-wide no-action" type="button" disabled={!flaggingButtonsEnabled}>
      Resolved
    </button>
  );
  const expandButton = showExpandButton && (
    <button className="btn btn-muted gray" type="button" aria-label="expand" onClick={onExpand}>
      <i className="fas fa-expand-alt" />
    </button>
  );
  const shrinkButton = !showExpandButton && (
    <button className="btn btn-muted gray" type="button" aria-label="collapse" onClick={onShrink}>
      <i className="fas fa-compress-alt" />
    </button>
  );

  return (
    <div className={`page-buttons ${className}`}>
      <span className={`similarity-indicator ${similarityLevel(page.similarityIndex)}`}>
        {`${Math.floor(page.similarityIndex * 100)}%`}
      </span>
      {expandButton}
      {resolveButton}
      {resolvedButton}
      {flagButton}
      {flaggedButton}
      {shrinkButton}
    </div>
  );
}

export default PageButtons;
