import { put, post, patch, get } from '../../common/request';

export const updateUserFlow = (id, data) => {
  return put(`/api/v1/user_flows/${id}`, {
    data: { user_flow: data, done_by: window.AuthUser?.random_uuid },
  });
};

export const newUpload = (id) => {
  return post(`/api/v1/user_flows/${id}/new_upload.json`).then((response) => {
    const { presigned_url: presignedUrl, consume_url: consumeUrl } = response;
    return { presignedUrl, consumeUrl };
  });
};

export const newThumbnail = (id, cache) => {
  // If cache is still valid, use it
  if (cache && cache.expiresAt - 5000 > new Date().getTime()) {
    return Promise.resolve(cache);
  }

  return post(`/api/v1/user_flows/${id}/new_thumbnail.json`).then((response) => {
    const { presigned_url: presignedUrl, expires_at: expiresAt } = response;
    return { presignedUrl, expiresAt };
  });
};

export const updateThumbnail = (id) => {
  return patch(`/api/v1/user_flows/${id}/update_thumbnail.json`);
};

export const fetchUserFlowContext = (id) => {
  return get(`/api/v1/user_flows/${id}/context.json`);
};

export const fetchUserFlow = (id, fields = []) => {
  const params = fields.length ? { fields: fields.join(',') } : {};
  return get(`/api/v1/user_flows/${id}.json?${new URLSearchParams(params)}`).then((response) => response.user_flow);
};

export const updateSharing = (id, data) => {
  return updateUserFlow(id, { sharing: data });
};

export const updateOptions = (id, data) => {
  return updateUserFlow(id, { options: data });
};

export const fetchUserFlowTemplate = (id) => {
  return get(`/api/v1/user_flow_templates/${id}.json`);
};

export const fetchUserFlowTemplates = () => {
  return get(`/api/v1/user_flow_templates.json`);
};
