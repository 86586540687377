import classNames from 'classnames';
import styled from 'styled-components';

import IconComments from './Icon/icon-comments.svg?react';
import IconUnreadComments from './Icon/icon-comments-unread.svg?react';
import { useCommentsCount } from './hooks/useCommentsCount';
import { useListState } from './hooks/useListState';

const Container = styled.div`
  svg {
    fill: #737373;
  }

  svg.no-comments {
    fill: none;
  }

  &:hover svg {
    stroke: #00ffaf;
  }
`;

export default function CommentsIcon({ className }) {
  const { unreadCount, totalCount } = useCommentsCount();
  const { isOpen } = useListState();

  const count = unreadCount > 0 ? unreadCount : totalCount;

  const classes = classNames(
    'position-relative d-flex justify-content-center align-items-center comments-icon',
    className,
    {
      'with-unread-comments': unreadCount > 0,
    }
  );

  return (
    <Container className={classes}>
      <Icon isOpen={isOpen} count={totalCount} unread={unreadCount} />
      {count > 0 && <span className="position-absolute comments-icon-badge">{count}</span>}
    </Container>
  );
}

function Icon({ isOpen, count, unread }) {
  const stroke = isOpen ? '#00FFAF' : '#737373 ';

  if (!unread && !count) {
    return <IconComments className="no-comments" stroke={stroke} title="No comments" />;
  }

  if (unread) {
    return <IconUnreadComments className="text-primary" title="Unread comments" />;
  }

  return <IconComments stroke={stroke} title="Comments" />;
}
