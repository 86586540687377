import { memo, useEffect, useState } from 'react';

const UnreadCount = ({ threads }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (threads) {
      setCount(
        threads.reduce((sum, thread) => {
          const unread = thread.comments.some((c) => !c.read) ? 1 : 0;

          return sum + unread;
        }, 0)
      );
    }
  }, [threads]);

  if (count === 0) {
    return null;
  }

  return <span className="comment__unread-count">{count}</span>;
};

export default memo(UnreadCount);
