/* eslint-disable import/prefer-default-export */
import { useShallow } from 'zustand/react/shallow';

import { useCommentStore } from '../stores/comments';

const commentsCountSelector = (state) => ({
  unreadCount: state.unreadCount,
  totalCount: state.totalCount,
  setCounts: state.setCounts,
});

export function useCommentsCount() {
  return useCommentStore(useShallow(commentsCountSelector));
}
