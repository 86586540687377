import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: 6px;
  width: 420px;
  max-width: 100%;
  z-index: 1;
  position: relative;
`;

export const Header = styled.div`
  background-color: #f2f2f2;
  border-bottom: 1px solid #eaeaea;
  color: #969696;
  padding: 1.5rem;
  display: flex;
  align-items: center;

  & .img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #fff;
  padding: 1.5rem;
  position: relative;
`;

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 1rem;
`;

export const State = styled.div`
  margin-left: auto;

  select {
    font-size: 16px;
    font-weight: 700;
    color: #5dc2f8;
    border: none;
    border-radius: 3px;
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const Textarea = styled.textarea`
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 4px;
  resize: vertical;
  line-height: normal;

  &:focus {
    background-color: #fff;
  }
`;

export const CommentButton = styled.button`
  font-weight: 600;
  padding: 1.5rem;
  border: none;
  background-color: #f2f2f2;
  color: #303030;
  border-radius: 6px;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #75fab5;
  }

  ${({ small }) =>
    !small &&
    css`
      width: 104px;
      height: 30px;
      padding: 0;
      text-align: center;
      line-height: 30px;
      font-weight: 500;
    `}

  ${({ $light, $small }) =>
    $light &&
    css`
      color: #9a9a9a;
      font-weight: 400;
      background-color: #fff;

      &:hover {
        color: #303030;
      }

      ${$small &&
      css`
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      `}
    `}

  ${({ $small }) =>
    $small &&
    css`
      font-size: 12px;
      padding: 0.3rem 0.6rem;
    `}

  ${({ $right }) =>
    $right &&
    css`
      float: right;
    `}
`;

export const Comments = styled.div`
  width: 100%;
`;

export const CommentWrapper = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    ${({ $isResolved }) =>
      $isResolved &&
      css`
        margin-bottom: 0;
      `}
  }
`;

export const CommentHeader = styled.div`
  display: flex;
  align-items: center;

  & .img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

export const Username = styled.p`
  margin: 0 0.5rem 0 0;
  font-weight: 600;
  color: #807f80;
`;

export const TimeAgo = styled.span`
  color: #979797;
  font-size: 10px;
`;

export const ActionsContainer = styled.div`
  margin-left: auto;
  display: none;

  ${CommentWrapper}:hover & {
    display: block;
  }
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  i {
    font-weight: 600;
    color: #c6bdc3;
    font-size: 14px;
  }
`;

export const CommentContent = styled.div`
  padding: 1rem;
  color: #6b6b6b;
  line-height: normal;

  p {
    max-height: 250px;
    overflow-y: auto;
    margin: 0;

    button {
      border: none;
      background: none;
      font-weight: 500;
      color: #52a47b;
      padding: 0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  button {
    margin-left: auto;
  }
`;
