import styled from 'styled-components';

import { usePresence } from '../sitemap-page-content/PresenceContext';

const WarningForSimultaneousEditionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  display: flex;
  justify-content: space-around;
`;

const WarningForSimultaneousEdition = ({ isSitemap }) => {
  const { usersCount } = usePresence();

  if (usersCount > 1) {
    return (
      <WarningForSimultaneousEditionContainer>
        <div className="alert alert-warning">
          WARNING! Another Member is currently editing {isSitemap ? 'this sitemap' : 'this page'}! Any updates you make
          now may be lost due to syncing issues. We advice that only one of you edit at time.
        </div>
      </WarningForSimultaneousEditionContainer>
    );
  }
  return null;
};

export default WarningForSimultaneousEdition;
