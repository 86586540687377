import { useState, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Select, { components } from 'react-select';
import { usePopper } from 'react-popper';

import { SitemapPageThumb } from './SitemapPageThumb';
import { nameOrDefault } from '../../../../sitemap/utils';
import { selectTheme } from '../../../shared/theme';

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
  }),
  control: (provided) => ({
    ...provided,
    order: 1, // Invert order: menu up, control down
    marginTop: '0.75rem',
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    color: state.theme.colors.primary,
    minHeight: 300,
    width: 380,
    paddingLeft: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    fontSize: '1rem',
    lineHeight: 1.1875,
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isFocused ? 'white' : 'inherit',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    position: 'relative',
  }),
};

const ThinScrollbarsMenuList = ({ children, ...rest }) => {
  return (
    <components.MenuList className="thin-scrollbars" {...rest}>
      {children}
    </components.MenuList>
  );
};

const popperOptions = {
  strategy: 'fixed',
  placement: 'left',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [5, 0] },
    },
  ],
};

const NodeTitle = ({ message, children }) => {
  if (!message) {
    return children;
  }

  return <div title={message}>{children}</div>;
};

const NodeOption = ({ children, innerRef, ...rest }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, popperOptions);

  const { data, isFocused, isDisabled } = rest;
  const { disabledMessage } = rest.selectProps;
  const { isFolder } = data;

  useEffect(() => {
    if (innerRef) {
      innerRef(referenceElement);
    }
  }, [innerRef, referenceElement]);

  return (
    <NodeTitle message={isDisabled ? disabledMessage : ''}>
      <components.Option innerRef={setReferenceElement} {...rest}>
        {isFolder && <i className="far fa-folder mr-2" />}
        {!isFolder &&
          isFocused &&
          createPortal(
            <SitemapPageThumb
              page={data}
              innerRef={setPopperElement}
              styles={styles.popper}
              attributes={attributes.popper}
            />,
            document.body
          )}
        {children}
      </components.Option>
    </NodeTitle>
  );
};

export const selectComponents = {
  DropdownIndicator: null,
  MenuList: ThinScrollbarsMenuList,
  Option: NodeOption,
};

export const NodeSelect = ({ nodes, autoFocus, placeholder, emptyMessage, disabledMessage, isDisabled, onChange }) => {
  const noMessage = useCallback(() => emptyMessage, [emptyMessage]);

  return (
    <Select
      autoFocus={autoFocus}
      placeholder={placeholder}
      noOptionsMessage={noMessage}
      tabSelectsValue={false}
      options={nodes}
      getOptionLabel={(node) => nameOrDefault(node)}
      getOptionValue={(node) => node.id}
      menuIsOpen
      styles={selectStyles}
      theme={selectTheme}
      isOptionDisabled={isDisabled}
      onChange={onChange}
      components={selectComponents}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      disabledMessage={disabledMessage}
    />
  );
};

export default NodeSelect;
