import styled from 'styled-components';

const Button = styled.button`
  background: transparent;
  color: #737373;
  border: none;
  outline: none !important;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0;
  :hover {
    color: #00ffaf;
  }
`;

export default function OpenSitemapContent() {
  return (
    <Button type="button" onClick={() => document.dispatchEvent(new CustomEvent('sitemap/EditContent'))}>
      <i title="Edit Sitemap Details" className="far fa-file-alt" />
    </Button>
  );
}
