/* eslint-disable jsx-a11y/no-autofocus */
import { createRef, PureComponent } from 'react';
import styled from 'styled-components';

import InputTrigger from '../utils/InputTrigger';
import Mentions from './Mentions';
import { humanCommentText, projectMember } from './helpers';

const Container = styled.div`
  position: relative;
  width: 100%;
  z-index: 99;
`;

class TextareaComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.containerRef = createRef();

    this.state = {
      top: null,
      left: null,
      show: false,
      startPosition: null,
      text: '',
      textareaValue: humanCommentText(props.initialValue, props.teamMembers) || '',
    };
  }

  componentDidMount() {
    const { current } = this.containerRef;

    if (current) {
      current.addEventListener('keydown', this.captureArrows);
      current.addEventListener('keyup', this.captureEsc);
    }
  }

  componentWillUnmount() {
    const { current } = this.containerRef;

    if (current) {
      current.removeEventListener('keydown', this.captureArrows);
      current.removeEventListener('keyup', this.captureEsc);
    }
  }

  handleSuggestor = (metaData) => {
    const { hookType, cursor } = metaData;

    switch (hookType) {
      case 'start':
        return this.setState({
          show: true,
          left: cursor.left,
          top: cursor.top + cursor.height,
          startPosition: cursor.selectionStart,
          text: '',
        });
      case 'cancel':
        return this.setState({
          show: false,
          left: null,
          top: null,
          startPosition: null,
          text: '',
        });
      default:
        break;
    }

    return null;
  };

  handleInput = (metaData) => {
    this.setState({ text: metaData.text });
  };

  handleMemberSelected = (user) => {
    const { startPosition, textareaValue } = this.state;
    const { teamMembers } = this.props;

    const text = `${textareaValue.slice(0, startPosition - 1)}<@${user.id}> `;

    this.setState(
      {
        show: false,
        left: null,
        top: null,
        text: '',
        startPosition: null,
        textareaValue: humanCommentText(text, teamMembers),
      },
      () => {
        this.handleCallbackValue();
        this.textareaRef.focus();
      }
    );

    this.endHandler();
  };

  handleTextareaInput = (event) => {
    const { value } = event.target;

    this.setState(
      {
        textareaValue: value,
      },
      () => this.handleCallbackValue()
    );
  };

  handleSubmission = (event) => {
    if (!(event.keyCode === 13 && (event.metaKey || event.ctrlKey))) {
      return;
    }

    this.props.onSubmit();
  };

  handleCallbackValue = () => {
    if (this.props.handleChange) {
      const { teamMembers } = this.props;
      let text = this.state.textareaValue;

      teamMembers.concat([projectMember]).forEach((user) => {
        const regex = new RegExp(`@${user.name}`, 'g');

        text = text.replace(regex, `<@${user.id}>`);
      });

      this.props.handleChange(text);
    }
  };

  setTextareaRef = (textareaRef) => {
    this.textareaRef = textareaRef;

    if (this.props.setTextareaRef) {
      this.props.setTextareaRef(textareaRef);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  onFocus = (event) => {
    const { target } = event;
    // move cursor to the end
    target.setSelectionRange(target.value.length, target.value.length);
  };

  // eslint-disable-next-line class-methods-use-this
  captureArrows = (event) => {
    const { which } = event;

    // Stop right/left arrows
    if (which === 37 || which === 39) {
      event.stopPropagation();
    }
  };

  captureEsc = (event) => {
    const { which } = event;

    // Stop ESC
    if (which === 27) {
      event.stopPropagation();
      if (this.state.show) {
        // Close mention list
        this.handleSuggestor({ hookType: 'cancel' });
        this.endHandler();
      } else if (this.textareaRef) {
        // Blur from textearea
        this.textareaRef.blur();
      }
    }
  };

  render() {
    const { onBlur, placeholder, teamMembers } = this.props;
    const { top, left, show, textareaValue, text } = this.state;

    return (
      <Container ref={this.containerRef} onKeyDown={this.handleSubmission}>
        <InputTrigger
          style={{ width: '100%' }}
          trigger={{ key: '@' }}
          onStart={(metaData) => this.handleSuggestor(metaData)}
          onCancel={(metaData) => this.handleSuggestor(metaData)}
          onType={(metaData) => this.handleInput(metaData)}
          endTrigger={(endHandler) => {
            this.endHandler = endHandler;
          }}
        >
          <textarea
            autoFocus
            onBlur={onBlur}
            onFocus={this.onFocus}
            value={textareaValue}
            placeholder={placeholder}
            ref={this.setTextareaRef}
            className="comment__textarea"
            onChange={this.handleTextareaInput}
          />
        </InputTrigger>
        {show && (
          <Mentions
            parentRef={this.containerRef}
            onMemberSelected={this.handleMemberSelected}
            teamMembers={teamMembers}
            text={text}
            style={{ top, left }}
          />
        )}
      </Container>
    );
  }
}

TextareaComponent.defaultProps = {
  onSubmit: () => {},
};

export default TextareaComponent;
