import { createContext, useContext, useState } from 'react';

export const FeaturesContext = createContext({ sitemapId: null });

export function FeaturesProvider({ children, features }) {
  const [state] = useState(features);

  return <FeaturesContext.Provider value={state}>{children}</FeaturesContext.Provider>;
}

export function useFeatures() {
  const ctx = useContext(FeaturesContext);

  if (ctx === undefined) {
    throw new Error('useFeatures must be used within a FeaturesProvider');
  }

  return ctx;
}
