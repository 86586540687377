/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from 'react';
import classNames from 'classnames';

function SitemapComparisonSearchBar({ onSearchValueChange }) {
  const [showClearButton, setShowClearButton] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const onInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    onSearchValueChange(searchValue);
  }, [onSearchValueChange, searchValue]);

  const clearSearchValue = (event) => {
    event.preventDefault();
    setSearchValue('');
  };

  const onReviewFlaggedPages = () => {
    setSearchValue('');
  };

  useEffect(() => {
    document.addEventListener('comparisonReviewFlaggedPages', onReviewFlaggedPages);

    return () => {
      document.removeEventListener('comparisonReviewFlaggedPages', onReviewFlaggedPages);
    };
  });

  return (
    <div className="search-bar">
      <i className="fas fa-search" />
      <input
        value={searchValue}
        placeholder="Search"
        onChange={onInputChange}
        onFocus={() => setShowClearButton(true)}
        onBlur={() => setShowClearButton(false)}
      />
      <i className={classNames('fas fa-times-circle', { 'd-none': !showClearButton })} onMouseDown={clearSearchValue} />
    </div>
  );
}

export default SitemapComparisonSearchBar;
