/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { Box } from '../shared/Box';
import CloseButton from '../shared/CloseButton';

// Tinymce plays with z-index of an element in the body to display modals (.tox-tinymce-aux)
// Using z-index 1299 we ensure those modals aren't behind this modal.
// Also lower numbers could make appear sitemap elements on the top of this modal
const Backdrop = styled.div`
  background: #303030;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1299;
  cursor: auto;

  display: none;
  opacity: 0;

  transition:
    opacity 0.25s,
    display 0.25s allow-discrete;

  &.offcanvas {
    width: 50vw;
    width: min(100%, max(650px, 50vw));
    left: auto;
    filter: drop-shadow(-2px 3px 4px #000000);
    outline: none;
  }

  &.open {
    display: block;
    opacity: 1;
  }

  @starting-style {
    &.open {
      opacity: 0;
    }
  }
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  height: 100%;

  ${(props) =>
    !props.$footerLess &&
    `
      padding-bottom: 1rem;
    `}

  ${(props) =>
    props.$borderLess &&
    `
    padding-top: 0.7rem;
    padding-left: 0;
    padding-right: 0;

    .close-button {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
  `}
`;

const Header = styled.div`
  > * {
    min-width: 0;
  }

  ${(props) =>
    !props.$empty &&
    `
      > .close-button {
        transform: translate(25%, -100%);
      }
    `}
`;

const Body = styled.div.attrs({ className: 'os-scrollbars' })`
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -1.5rem;
  padding-right: 1.5rem;
`;

const NoScrollingBody = styled.div`
  width: 100%;
  height: 100%;
`;

export function Modal({ children, title, onKeyDown, onClose, open, bodyNoScrolling, borderLess, offcanvas, footer }) {
  const ref = useRef();

  useEffect(() => {
    if (open && ref) {
      ref.current.focus();
    }
  }, [open]);

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      onClose();
    }
  };

  return (
    <Backdrop
      ref={ref}
      className={classNames({ open, 'p-5': !offcanvas, 'py-5 px-4': offcanvas, offcanvas })}
      tabIndex="-1"
      onKeyDown={handleKeyDown}
    >
      <Content $borderLess={borderLess} $footerLess={!footer}>
        <Header
          className={classNames('d-flex', { 'align-items-baseline': offcanvas, 'align-items-center': !offcanvas })}
          $empty={!title}
        >
          {title}
          <CloseButton onClick={onClose} className="ml-auto pl-4 close-button" />
        </Header>
        {bodyNoScrolling ? <NoScrollingBody>{children}</NoScrollingBody> : <Body>{children}</Body>}
        {footer && <div className="mt-3 ml-auto">{footer}</div>}
      </Content>
    </Backdrop>
  );
}
