import { useEffect, useState, useCallback, useMemo, useRef, useLayoutEffect } from 'react';
import UploadPreview from '@rpldy/upload-preview';
import { useBatchAddListener, useItemFinishListener } from '@rpldy/uploady';
import { useFormikContext } from 'formik';

import { ActiveStorageUploady, UploadButton } from '../../shared/file-upload';
import QueueItem from '../file-upload/QueueItem';
import config from '../../../config';
import { bytesToMegaBytes } from '../../sitemap-page-content/utils';

const { PAGE_MAX_FILES, PAGE_MAX_FILE_UPLOAD_SIZE, PAGE_MAX_UPLOAD_PREVIEW_FILE_SIZE } = config;

export default function FileInput({ maxConcurrent = 3, disabled, onChange, name, constraintParams }) {
  // Use state to track finished upload due to the async nature of uploads, field is an array, no way
  // to update form state using current field value (we have latest rendered value).
  const [newFiles, setNewFiles] = useState([]);
  const [queueSize, setQueueSize] = useState(0);
  const previewMethodsRef = useRef();
  const { files } = constraintParams;

  useLayoutEffect(() => {
    // we must get rid of the preview manually after saving to avoid displaying duplicated pic thumb
    if (previewMethodsRef.current) {
      const uploaded = files.map((f) => f.signed_id);
      newFiles.forEach(({ id, signed_id }) => {
        if (uploaded.includes(signed_id)) {
          previewMethodsRef.current.removePreview(id);
        }
      });
    }
  }, [files, newFiles]);

  useEffect(() => {
    onChange({ target: { name, value: newFiles.map((f) => f.signed_id) } });
  }, [newFiles, onChange, name]);

  const handleItemFinish = (item) => {
    if (!item.blob) {
      return;
    }

    setNewFiles((current) => [...current, { id: item.id, signed_id: item.blob.signed_id }]);
  };

  const handleRemove = useCallback(
    (targetId) => {
      setNewFiles((current) => current.filter((f) => f.id !== targetId));
    },
    [setNewFiles]
  );

  const getPreviewProps = useCallback(
    (item) => ({
      onRemove() {
        handleRemove(item.id);
      },
    }),
    [handleRemove]
  );

  return (
    <ActiveStorageUploady maxConcurrent={maxConcurrent}>
      <TrackItemFinish onFinish={handleItemFinish} />

      <UploadPreview
        rememberPreviousBatches
        PreviewComponent={QueueItem}
        previewComponentProps={getPreviewProps}
        maxPreviewImageSize={PAGE_MAX_UPLOAD_PREVIEW_FILE_SIZE}
        maxPreviewVideoSize={PAGE_MAX_UPLOAD_PREVIEW_FILE_SIZE}
        onPreviewsChanged={(previews) => setQueueSize(previews.length)}
        previewMethodsRef={previewMethodsRef}
      />
      {!disabled && (
        <div className="align-self-center">
          <UploadButton className="btn btn-outline-primary border-primary">+ Upload</UploadButton>
          <FileUploadConstraints
            maxSize={PAGE_MAX_FILE_UPLOAD_SIZE}
            maxFiles={PAGE_MAX_FILES}
            queueSize={queueSize}
            constraintParams={constraintParams}
          />
        </div>
      )}
    </ActiveStorageUploady>
  );
}

function TrackItemFinish({ onFinish }) {
  useItemFinishListener(onFinish);

  return null;
}

function useExistingSize({ files, removedField }) {
  const form = useFormikContext();
  const removed = form.values[removedField];

  return useMemo(() => {
    return files.reduce((sum, file) => (removed.includes(file.id) ? 0 : 1) + sum, 0);
  }, [files, removed]);
}

/**
 * Prevent enqueue of choosen files if a file is larger than limits or max files per page is reached.
 */
function FileUploadConstraints({ maxSize, maxFiles, constraintParams, queueSize }) {
  const [error, setError] = useState('');
  // number of existing files
  const existingSize = useExistingSize(constraintParams);

  const remainingFiles = maxFiles - existingSize - queueSize;

  const validations = useCallback(
    (batch) => {
      // Ignore retries.
      if (batch.items.some((item) => item.recycled)) {
        return true;
      }

      if (!Number.isInteger(maxFiles) && !Number.isFinite(maxSize)) {
        return true;
      }

      if (batch.items.length > remainingFiles) {
        setError(`Pages can have up to ${maxFiles} files`);
        return false;
      }

      if (batch.items.some(({ file }) => file.size > maxSize)) {
        setError(`File size must be less than ${bytesToMegaBytes(maxSize)}Mb`);
        return false;
      }

      setError('');

      return true;
    },
    [maxSize, maxFiles, remainingFiles]
  );

  useBatchAddListener(validations);

  return error && <div className="text-danger mt-2">{error}</div>;
}
