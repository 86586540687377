import { useIsMutating } from '@tanstack/react-query';

import { pageContentKey, usePageContent } from '../hooks';
import UpdatedIndicator from '../../content-editor/UpdatedIndicator';
import { useSitemap } from '../../../contexts/SitemapContext';

export default function PageUpdateIndicator({ pageId }) {
  const { sitemapId } = useSitemap();
  const { data } = usePageContent(pageId);
  const saving = useIsMutating({ mutationKey: pageContentKey(sitemapId, pageId), exact: true });

  return <UpdatedIndicator saving={Boolean(saving)} updatedAt={data?.updated_at} />;
}
