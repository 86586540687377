/* eslint-disable import/prefer-default-export */
import { create } from 'zustand';

export const useCommentStore = create((set) => ({
  isOpen: false,
  unreadCount: 0,
  totalCount: 0,
  setIsOpen: (isOpen) => set({ isOpen }),
  toggleIsOpen: () => set((state) => ({ isOpen: !state.isOpen })),
  setCounts: (unreadCount, totalCount) => set({ unreadCount, totalCount }),
}));
