import { useState, useEffect, useRef } from 'react';

import AuthenticityToken from '../AuthenticityToken';
import CloseButton from '../CloseButton';

const notEqual = (recordId) => (e) => e.recordId !== recordId;

function BulkActionsModal({ formAction, eventsPrefix, friendlyName }) {
  const [records, setRecords] = useState([]);
  const form = useRef();

  const closeModal = (event) => {
    event.preventDefault();
    setRecords([]);
    document.dispatchEvent(new CustomEvent(`${eventsPrefix}/resetBulkActionCheckboxes`));
  };

  const recordIds = Array.from(records, (e) => e.recordId);
  const canMove = records.every((record) => record.canMove);
  const canDelete = records.every((record) => record.canDelete);

  useEffect(() => {
    const handleSelected = ({ detail }) => {
      // Selection can be called to update permissions. Make sure we don't add duplicates.
      setRecords((current) => current.filter(notEqual(detail.recordId)).concat(detail));
    };

    const handleDeselected = ({ detail }) => {
      setRecords((current) => current.filter(notEqual(detail.recordId)));
    };

    document.addEventListener(`${eventsPrefix}/SelectedForBulkAction`, handleSelected);
    document.addEventListener(`${eventsPrefix}/DeselectedForBulkAction`, handleDeselected);

    return () => {
      document.removeEventListener(`${eventsPrefix}/SelectedForBulkAction`, handleSelected);
      document.removeEventListener(`${eventsPrefix}/DeselectedForBulkAction`, handleDeselected);
    };
  }, [setRecords, eventsPrefix]);

  useEffect(() => {
    const handleSelectionSync = ({ detail }) => {
      let selection = recordIds;
      if (detail?.ids) {
        selection = selection.filter((id) => detail.ids.includes(id));
      }

      document.dispatchEvent(new CustomEvent(`${eventsPrefix}/resetBulkActionCheckboxes`, { detail: selection }));
    };

    document.addEventListener(`${eventsPrefix}/BulkActionSyncRequired`, handleSelectionSync);

    return () => {
      document.removeEventListener(`${eventsPrefix}/BulkActionSyncRequired`, handleSelectionSync);
    };
  }, [recordIds, eventsPrefix]);

  const onDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    form.current && form.current.submit();
  };

  const onMove = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const detail = {
      formAction: `${formAction}?bulk_action=move`,
      projectParamName: 'target_project_id',
      extraHiddens: { record_ids: recordIds.join(',') },
    };

    document.dispatchEvent(new CustomEvent('moveProjectInit', { detail }));
  };

  if (records.length === 0) {
    return null;
  }

  return (
    <div className="bulk-action-modal">
      <CloseButton className="modal-close" onClick={closeModal} />

      <div className="row mt-4 mb-4 mt-md-0">
        <div className="col-sm-12">
          You have selected <span className="selection-count">({records.length})</span> {friendlyName}
          {records.length > 1 ? 's' : ''}
        </div>
      </div>

      <div className="row mt-4 mt-md-0">
        <div className="col-sm-6">
          <button
            className={`btn btn-muted btn-block ${canDelete ? '' : 'disabled'}`}
            type="button"
            onClick={onDelete}
            name="delete"
          >
            Delete
          </button>
        </div>
        <div className="col-sm-6">
          <button
            className={`btn btn-muted btn-block ${canMove ? '' : 'disabled'}`}
            type="button"
            onClick={onMove}
            name="move"
          >
            Move
          </button>
        </div>
      </div>

      <form action={formAction} method="post" ref={form}>
        <AuthenticityToken />
        <input name="bulk_action" type="hidden" value="delete" />
        <input name="record_ids" type="hidden" value={recordIds} />
      </form>
    </div>
  );
}

export default BulkActionsModal;
