/* global toastr */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../../contexts/AuthContext';
import { useSitemap } from '../../contexts/SitemapContext';
import { getPageContent, updatePageContent } from './api';

export const pageContentKey = (sitemapId, pageId) => ['sitemap', sitemapId, 'page', pageId.toString(), 'content'];

export function usePageContent(pageId) {
  const { sitemapId } = useSitemap();

  return useQuery({
    queryKey: pageContentKey(sitemapId, pageId),
    queryFn: () => getPageContent(sitemapId, pageId),
    enabled: Boolean(sitemapId) && Boolean(pageId),
  });
}

export function useUpdatePageContent(pageId) {
  const { sitemapId } = useSitemap();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => updatePageContent(sitemapId, pageId, user.random_uuid, data),
    mutationKey: pageContentKey(sitemapId, pageId),
    onSuccess(pageContent) {
      document.dispatchEvent(new CustomEvent('treeNodeChangePageWithContent', { detail: { target: pageId } }));
      queryClient.setQueryData(pageContentKey(sitemapId, pageId), pageContent);
    },
    onError() {
      toastr.error("The data couldn't be saved...", '', { preventDuplicates: true });
    },
  });
}
