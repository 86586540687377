import { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  margin: auto;
  border: 1px dashed ${({ $isEnabled }) => ($isEnabled ? '#a7a7a7' : '#797c83')};
  border-radius: 4px;
  cursor: ${({ $isEnabled }) => ($isEnabled ? 'pointer' : 'not-allowed')};

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: #20bbff;
    margin: 3px;
    display: ${({ $isChecked }) => ($isChecked ? 'block' : 'none')};
  }
`;

const notifySelection = (checked, detail) => {
  if (checked) {
    document.dispatchEvent(new CustomEvent('sitemapSelectedForComparison', { detail }));
  } else {
    document.dispatchEvent(new CustomEvent('sitemapDeselectedForComparison', { detail }));
  }
};

function SitemapComparisonCheckbox(props) {
  const { sitemapId } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);

  const onClick = (event) => {
    event.stopPropagation();
    if (isEnabled) {
      setIsChecked(!isChecked);
      notifySelection(!isChecked, props);
    }
  };

  useEffect(() => {
    const enable = () => {
      setIsEnabled(true);
    };

    document.addEventListener('enableSitemapComparisonCheckboxes', enable);

    return () => {
      document.removeEventListener('enableSitemapComparisonCheckboxes', enable);
    };
  });

  useEffect(() => {
    const disableUnchecked = ({ detail: { except = [] } }) => {
      if (!except.includes(sitemapId)) {
        setIsEnabled(false);
      }
    };

    const uncheckIfNeeded = ({ detail }) => {
      if (sitemapId === detail.sitemapId) {
        setIsChecked(false);
      }
    };

    document.addEventListener('disableSitemapComparisonCheckboxes', disableUnchecked);
    document.addEventListener('uncheckSitemapComparisonCheckbox', uncheckIfNeeded);

    return () => {
      document.removeEventListener('disableSitemapComparisonCheckboxes', disableUnchecked);
      document.removeEventListener('uncheckSitemapComparisonCheckbox', uncheckIfNeeded);
    };
  }, [sitemapId]);

  // Register listener ASAP. Ajax pagination triggers a state sync. We need to make sure that
  // all listeners are registered before it does it.
  useLayoutEffect(() => {
    const reset = ({ detail }) => {
      const { checked = [], uncheckedEnabled = true } = detail || {};

      const selected = checked.includes(sitemapId);

      setIsEnabled(uncheckedEnabled || selected);
      setIsChecked(selected);
    };

    document.addEventListener('resetSitemapComparisonCheckboxes', reset);

    return () => {
      document.removeEventListener('resetSitemapComparisonCheckboxes', reset);
    };
  }, [sitemapId]);

  return (
    <div className="text-center">
      <StyledCheckbox $isChecked={isChecked} $isEnabled={isEnabled} onClick={onClick} />
    </div>
  );
}

export default SitemapComparisonCheckbox;
