import { useState, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';

import SearchInput from './SearchInput';

export default function Search({ initialQuery = '' }) {
  const [query, setQuery] = useState(initialQuery);
  const ref = useRef();

  const onSearchChange = useMemo(() => {
    const debouncedSetQuery = debounce(setQuery, 1000);

    const onSearchValueUpdated = (value) => {
      debouncedSetQuery(value);
    };

    return onSearchValueUpdated;
  }, []);

  const onClearHandler = () => {
    setQuery('');
  };

  const handleOpen = () => {
    ref.current?.focus();
  };

  useEffect(() => {
    if (query === initialQuery) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    params.delete('page');

    if (query) {
      params.set('q', query);
    } else {
      params.delete('q');
    }

    window.location.search = params.toString();
  }, [query, initialQuery]);

  return (
    <SearchInput
      ref={ref}
      initialSearchValue={initialQuery}
      onOpen={handleOpen}
      onChange={onSearchChange}
      onClear={onClearHandler}
    />
  );
}
