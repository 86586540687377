/* eslint-disable max-classes-per-file */
/* eslint-disable import/prefer-default-export */
import { isEmbedContext } from './utils';

/**
 * Always ready. Do not wait for anything.
 */
class DummyTracker {
  promise = Promise.resolve();

  track() {
    return this.promise;
  }
}

function supportIntersectionObserver() {
  return typeof window.IntersectionObserver !== 'undefined';
}

/**
 * Wait until the target is visible before resolving the promise.
 * Use IntersectionObserver to detect visibility.
 * Resolve inmediately if IntersectionObserver is not supported.
 */
class IntersectionTracker {
  constructor(target) {
    this.target = target;
    this.promise = null;
  }

  track() {
    if (this.promise == null) {
      // Create a promise that will resolve when the target is visible for the first time.
      try {
        this.setupPromise();
      } catch (e) {
        // Problems creating the promise. Fallback to no intersaction tracking.
        this.promise = Promise.resolve();
      }
    }

    return this.promise;
  }

  // Private methods

  setupPromise() {
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });

    this.observer = new IntersectionObserver(this.intersectionCallback.bind(this), { threshold: 0.25 });
    this.observer.observe(this.target);
  }

  intersectionCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Stop observation. Release resources.
        this.observer.disconnect();
        this.observer = null;
        this.resolve();
      }
    });
  }
}

/**
 * Utility to defer response to messages in embed context until the iframe is visible.
 * Return an object with a track() method. Calling that method returns a promise that resolves
 * once after the first time the iframe is visible.
 */
export function getVisibilityTracker({ target, context }) {
  if (isEmbedContext(context) && supportIntersectionObserver()) {
    return new IntersectionTracker(target);
  }

  return new DummyTracker();
}
