/* global Sitemap */
import { useMemo } from 'react';

import { useSitemapTree } from '../../../hooks/useSitemapTree';

export const useTreeDescendants = (nodes) => {
  const tree = useSitemapTree();

  return useMemo(() => {
    const treeNodes = Sitemap.tree.findMany(tree, nodes);
    const descendants = treeNodes.reduce((result, node) => {
      node.descendants().forEach((d) => result.push(d.data.id));
      return result;
    }, []);

    return descendants;
  }, [nodes, tree]);
};

export default useTreeDescendants;
