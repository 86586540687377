import { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext({ user: null, authenticated: null });

export function AuthProvider({ children }) {
  const { AuthUser: user } = window;
  const [auth, setAuth] = useState({ user: null, authenticated: null });

  useEffect(() => {
    if (user) {
      setAuth({ user, authenticated: true });
    } else {
      setAuth({ user: null, authenticated: false });
    }
  }, [setAuth, user]);

  if (auth.authenticated === null) {
    return null;
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
