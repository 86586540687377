import { createContext, useContext, useState } from 'react';

export const SitemapContext = createContext({ sitemapId: null });

export function SitemapProvider({ children, sitemapId }) {
  const [state] = useState({ sitemapId });

  return <SitemapContext.Provider value={state}>{children}</SitemapContext.Provider>;
}

export function useSitemap() {
  const ctx = useContext(SitemapContext);

  if (ctx === undefined) {
    throw new Error('useSitemap must be used within a SitemapProvider');
  }

  return ctx;
}
